import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Col, Row, Space, DatePicker, Select, ConfigProvider } from "antd";
import "moment/locale/uk";
import locale from "antd/es/locale/uk_UA";
import { Map, TileLayer, Marker } from "react-leaflet";

import * as Actions from "../../../../Store/actions";
import { translate } from "../../../../Translation";
import { request } from "../../../../Services";
import styles from "./styles.module.css";
import { api } from "../../../../Services/axios";

const { Option } = Select;

class ChartAddress extends Component {
  state = {
    dateFrom: moment(Date.now()).startOf("month"),
    dateTo: moment(Date.now()),
    questionId: null,
    executorId: null,
    data: [],
    dataExecutor: [],
    dataAddress: [],
    loading: false,
    lat: 47.905546,
    lon: 33.393958,
  };

  componentDidMount() {
    if (this.props.location.state !== undefined) {
      this.onChangeDateFrom(this.props.location.state.dateFrom);
      this.onChangeDateTo(this.props.location.state.dateTo);
      this.onIdQuestionsChange(this.props.location.state);
      this.onIdExecutorChange(this.props.location.state);
    } else {
      this.fetchSelectData();
    }
  }

  intl = (selector) => {
    return translate(selector, this.props.lang);
  };

  fetchSelectData = () => {
    this.setState({ loading: true }, async () => {
      const data = await request("/statistics/problems/address", {
        method: "post",
        body: this.state,
        cancelTokenKey: "address",
      });
      console.log("data: ", data);
      this.setState({ data: data?.content, loading: false });
    });
  };

  fetchExecutor = () => {
    this.setState({ loading: true }, async () => {
      const dataExecutor = await request(
        "/statistics/problems/address-executor",
        { method: "post", body: this.state, cancelTokenKey: "address-executor" }
      );
      console.log("dataExecutor: ", dataExecutor);
      this.setState({ dataExecutor: dataExecutor?.content, loading: false });
    });
  };

  fetchAddress = () => {
    this.setState({ loading: true }, async () => {
      const dataAddress = await request(
        "/statistics/problems/address-problemaddress",
        {
          method: "post",
          body: this.state,
          cancelTokenKey: "address-problemaddress",
        }
      );
      console.log("dataAddress: ", dataAddress);
      this.setState({ dataAddress: dataAddress?.content, loading: false });
    });
  };

  onChangeDateFrom = (date) => {
    this.setState(
      { dateFrom: moment(date, "DD-MM-YYYY").startOf("date") },
      () => {
        this.fetchSelectData();
        if (this.state.executorId !== null) {
          this.fetchAddress();
        }
      }
    );
  };

  onChangeDateTo = (date) => {
    this.setState({ dateTo: moment(date, "DD-MM-YYYY").endOf("date") }, () => {
      this.fetchSelectData();
      if (this.state.executorId !== null) {
        this.fetchAddress();
      }
    });
  };

  onIdQuestionsChange = (data) => {
    this.setState(
      { questionId: data.selectId ? data.selectId : data.key },
      () => {
        this.fetchExecutor();
      }
    );
  };

  onIdExecutorChange = (data) => {
    this.setState({ executorId: data.key }, () => {
      this.fetchAddress();
    });
  };

  addMarker = async (address) => {
    try {
      const response = await api.get(
        `https://nominatim.openstreetmap.org/?addressdetails=1&q=${address}, Кривий Ріг&format=json&limit=1`
      );

      if (response.data) {
        this.setState({ lat: response.data[0].lat, lon: response.data[0].lon });
        return {
          type: "ok",
          content: response.data.content,
          info: response.data.info,
        };
      }
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { dateFrom, dateTo, data, dataExecutor, dataAddress, lat, lon } =
      this.state;
    const { location } = this.props;

    return (
      <Row>
        <Col lg={24} md={24} sm={24}>
          <Space direction="vertical" size={12}>
            <ConfigProvider locale={locale}>
              {dateFrom ? (
                <DatePicker
                  defaultValue={
                    location.state
                      ? moment(location.state.dateFrom, "DD-MM-YYYY")
                      : dateFrom
                  }
                  format={"DD-MM-YYYY"}
                  picker="date"
                  onChange={(_, dates) => this.onChangeDateFrom(dates)}
                  style={{ marginLeft: "1rem" }}
                />
              ) : null}
              {dateTo ? (
                <DatePicker
                  defaultValue={
                    location.state
                      ? moment(location.state.dateTo, "DD-MM-YYYY")
                      : dateTo
                  }
                  format={"DD-MM-YYYY"}
                  picker="date"
                  onChange={(_, dates) => this.onChangeDateTo(dates)}
                  style={{ marginLeft: "1rem" }}
                />
              ) : null}
            </ConfigProvider>
          </Space>
        </Col>
        <Col lg={24} md={24} sm={24}>
          <Select
            defaultValue={
              location.state
                ? location.state.selectValue
                : data?.length > 0
                ? data[0].name_question
                : null
            }
            style={{ width: 520, marginLeft: "1rem", marginTop: "2rem" }}
            onChange={(_, data) => this.onIdQuestionsChange(data)}
          >
            {data?.length > 0
              ? data.map((item) => (
                  <Option
                    key={item.gta_questions_id}
                    value={item.name_question}
                  >
                    {item.name_question}
                  </Option>
                ))
              : null}
          </Select>
        </Col>
        <Col lg={24} md={24} sm={24}>
          <Select
            defaultValue={
              location.state
                ? location.state.value
                : dataExecutor?.length > 0
                ? dataExecutor[0].Executers
                : null
            }
            style={{ width: 520, marginLeft: "1rem", marginTop: "2rem" }}
            onChange={(_, data) => this.onIdExecutorChange(data)}
          >
            {dataExecutor?.length > 0
              ? dataExecutor.map((item) => (
                  <Option key={item.id_executors_id} value={item.Executers}>
                    {item.Executers}
                  </Option>
                ))
              : null}
          </Select>
        </Col>
        <Col lg={10} md={10} sm={24}>
          <Map
            center={[lat, lon]}
            zoom={16}
            style={{
              height: "600px",
              width: "100%",
              marginLeft: "1rem",
              marginTop: "5rem",
            }}
          >
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={[lat, lon]} />
          </Map>
        </Col>
        <Col lg={{ span: 12, offset: 2 }} md={{ span: 12, offset: 2 }} sm={24}>
          <div className={styles.NavbarChart} style={{ marginTop: "5rem" }}>
            <ul>
              <h2>{this.intl("address2")}</h2>
              {dataAddress?.length > 0
                ? dataAddress.map((item) => (
                    <li>
                      <button
                        className={styles.Button}
                        onClick={() =>
                          this.addMarker(item.NAME_STREET + " " + item.House)
                        }
                      >
                        {item.NAME_STREET + ", " + item.House + " / " + item.k}
                      </button>
                    </li>
                  ))
                : null}
            </ul>
          </div>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    lang: state.lang.value,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: (language) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
    raiseMessage: (message) =>
      dispatch({ type: Actions.RAISE_MESSAGE, message }),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChartAddress)
);
