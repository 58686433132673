import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Col, Row, Table, Checkbox, Spin, ConfigProvider } from "antd";
import LoadingOutlined from "@ant-design/icons/LoadingOutlined";
import locale from "antd/es/locale/uk_UA";

import CabinetLayout from "../../HOC/CabinetLayout";
import User from "../../Services/user";
import * as Actions from "../../Store/actions";
import { translate } from "../../Translation";
import { request } from "../../Services";
import styles from "./styles.module.scss";
import { ModalProvider } from "../../Contexts/modal.context";
import ModalAccessSettings from "../../Components/Modals/AccessSettings";

function AccessButtons(props) {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [selectData, setSelectData] = useState([]);
  const roleArr = [...new Set(tableData?.map((i) => i.role))];
  const pagesArr = [...new Set(tableData?.map((i) => i.routeName))];
  const buttonsArr = [...new Set(tableData?.map((i) => i.buttonName))];

  useEffect(() => {
    const currentUser = User.decode();
    if (!currentUser) return props.history.replace("/auth");
    fetchTableData();
  }, []);

  const intl = (selector) => {
    return translate(selector, props.lang);
  };

  const fetchTableData = async () => {
    setLoading(true);
    const { content } = await request("/administration/getaccessbuttons", {
      method: "get",
      cancelTokenKey: "getaccess",
    });
    setTableData(content || []);
    setLoading(false);
  };

  const onClickRow = (record) => {
    setSelectData(record);
    setDisabled(false);
  };

  const setRowClassName = (record) => {
    return record.id === selectData?.id ? styles["table-select-row"] : "";
  };

  const columns = [
    {
      title: intl("role"),
      dataIndex: "role",
      key: "role",
      ellipsis: "true",
      filters:
        tableData?.length > 0
          ? roleArr.map((item) => ({ text: item, value: item }))
          : null,
      onFilter: (value, record) => record.role.indexOf(value) === 0,
    },
    {
      title: intl("pages"),
      dataIndex: "routeName",
      key: "routeName",
      ellipsis: "true",
      filters:
        tableData?.length > 0
          ? pagesArr.map((item) => ({ text: item, value: item }))
          : null,
      onFilter: (value, record) => record.routeName.indexOf(value) === 0,
    },
    {
      title: intl("buttons"),
      dataIndex: "buttonName",
      key: "buttonName",
      ellipsis: "true",
      filters:
        tableData?.length > 0
          ? buttonsArr.map((item) => ({ text: item, value: item }))
          : null,
      onFilter: (value, record) => record.buttonName.indexOf(value) === 0,
    },
    {
      title: intl("access"),
      dataIndex: "isAccess",
      key: "isAccess",
      align: "center",
      render: (_, record) => <Checkbox checked={!!record?.isAccess} />,
    },
  ];

  return (
    <CabinetLayout activeLink={props.match.path} {...props}>
      <Row>
        <Col lg={24} md={24} sm={24}>
          <ModalProvider>
            <ModalAccessSettings
              name={intl("add")}
              fetchTableData={fetchTableData}
              buttons={true}
            />
            <ModalAccessSettings
              name={intl("edit")}
              selectData={selectData}
              fetchTableData={fetchTableData}
              disabled={disabled}
              buttons={true}
            />
          </ModalProvider>
        </Col>
        <Col lg={24} md={24} sm={24}>
          <ConfigProvider locale={locale}>
            <Table
              className={styles.table}
              dataSource={tableData}
              columns={columns}
              onRow={(record) => {
                return {
                  onClick: () => onClickRow(record),
                };
              }}
              rowClassName={setRowClassName}
              pagination={{ position: ["bottomCenter"], showSizeChanger: true }}
              loading={{
                spinning: loading,
                indicator: (
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 24 }} spin />
                    }
                  />
                ),
              }}
              size="small"
              bordered
            />
          </ConfigProvider>
        </Col>
      </Row>
    </CabinetLayout>
  );
}

function mapStateToProps(state) {
  return {
    lang: state.lang.value,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: (language) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
    raiseMessage: (message) =>
      dispatch({ type: Actions.RAISE_MESSAGE, message }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccessButtons);
