import { useState, useRef } from "react";
import { Input, Button, Space } from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";

export function tasksColumns(intl = () => String()) {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  let searchInput = useRef();

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`${intl("search")}...`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {intl("search")}
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            {intl("reset")}
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            {intl("filter")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  return [
    {
      title: intl("project"),
      dataIndex: "isProject",
      key: "isProject",
      align: "center",
      ellipsis: true,
    },
    {
      title: intl("perform"),
      dataIndex: "dateK_plan",
      key: "dateK_plan",
      align: "center",
      ellipsis: true,
    },
    {
      title: intl("date-of-assignment"),
      dataIndex: "dateN_fact",
      key: "dateN_fact",
      align: "center",
      ellipsis: true,
      ...getColumnSearchProps("dateN_fact"),
    },
    {
      title: intl("task-content"),
      dataIndex: "Parent_Resolution",
      key: "Parent_Resolution",
      align: "center",
      ellipsis: true,
    },
    {
      title: intl("task-given"),
      dataIndex: "Parent_ResolutionFio",
      key: "Parent_ResolutionFio",
      align: "center",
      ellipsis: true,
      ...getColumnSearchProps("Parent_ResolutionFio"),
    },
    {
      title: intl("performer"),
      dataIndex: "ExecutorsFio",
      key: "ExecutorsFio",
      align: "center",
      ellipsis: true,
    },
    {
      title: intl("type2"),
      dataIndex: "eta_const_typeDoc_id",
      key: "eta_const_typeDoc_id",
      align: "center",
      ellipsis: true,
    },
    {
      title: intl("place-of-registration"),
      dataIndex: "DeptReestration",
      key: "DeptReestration",
      align: "center",
      ellipsis: true,
      ...getColumnSearchProps("DeptReestration"),
    },
    {
      title: intl("num-document-project"),
      dataIndex: "num_doc",
      key: "num_doc",
      align: "center",
      ellipsis: true,
      ...getColumnSearchProps("num_doc"),
    },
    {
      title: intl("date-document-project"),
      dataIndex: "date_doc",
      key: "date_doc",
      align: "center",
      ellipsis: true,
      ...getColumnSearchProps("date_doc"),
    },
    {
      title: intl("type-document-project"),
      dataIndex: "Name_TypeDoc",
      key: "Name_TypeDoc",
      align: "center",
      ellipsis: true,
      ...getColumnSearchProps("Name_TypeDoc"),
    },
    {
      title: intl("name-document-project"),
      dataIndex: "context",
      key: "context",
      align: "center",
      ellipsis: true,
      ...getColumnSearchProps("context"),
    },
    {
      title: intl("id"),
      dataIndex: "id",
      key: "id",
      align: "center",
      ellipsis: true,
    },
    {
      title: intl("parent-id"),
      dataIndex: "parent_id",
      key: "parent_id",
      align: "center",
      ellipsis: true,
    },
    {
      title: intl("controls"),
      dataIndex: "ControllerFIO",
      key: "ControllerFIO",
      align: "center",
      ellipsis: true,
    },
    {
      title: intl("done"),
      dataIndex: "dateK_fact",
      key: "dateK_fact",
      align: "center",
      ellipsis: true,
    },
    {
      title: intl("result"),
      dataIndex: "Result",
      key: "Result",
      align: "center",
      ellipsis: true,
    },
    {
      title: intl("task-given-remark"),
      dataIndex: "prim_plan",
      key: "prim_plan",
      align: "center",
      ellipsis: true,
      ...getColumnSearchProps("prim_plan"),
    },
    {
      title: intl("task-text"),
      dataIndex: "text_rezolizii_parent",
      key: "text_rezolizii_parent",
      align: "center",
      ellipsis: true,
      ...getColumnSearchProps("text_rezolizii_parent"),
    },
    {
      title: intl("performers-of-the-task"),
      dataIndex: "text_rezolizii_executers_parent",
      key: "text_rezolizii_executers_parent",
      align: "center",
      ellipsis: true,
    },
  ];
}
