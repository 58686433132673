// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_modal-button__oY933,\n.styles_modal-button__oY933:focus {\n  background: #5abab9;\n  font-size: 16px;\n  border: none;\n  color: #ffffff;\n  line-height: 1;\n  margin-left: 1rem;\n}\n\n.styles_modal-button__oY933:hover {\n  background: #5abab9;\n  color: #3f434e;\n}\n", "",{"version":3,"sources":["webpack://src/Components/Modals/AccessSettings/styles.module.css"],"names":[],"mappings":"AAAA;;EAEE,mBAAmB;EACnB,eAAe;EACf,YAAY;EACZ,cAAc;EACd,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,cAAc;AAChB","sourcesContent":[".modal-button,\n.modal-button:focus {\n  background: #5abab9;\n  font-size: 16px;\n  border: none;\n  color: #ffffff;\n  line-height: 1;\n  margin-left: 1rem;\n}\n\n.modal-button:hover {\n  background: #5abab9;\n  color: #3f434e;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal-button": "styles_modal-button__oY933"
};
export default ___CSS_LOADER_EXPORT___;
