import { useState, useContext } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Space,
  Select,
  ConfigProvider,
  DatePicker,
  Typography,
  Checkbox,
  Tabs,
  Upload,
  Table,
  Empty,
  Spin,
  Menu,
} from "antd";
import { UploadOutlined, LoadingOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/uk";
import locale from "antd/lib/locale/uk_UA";

import * as Actions from "../../../Store/actions";
import { translate } from "../../../Translation";
import { environment } from "../../../Services/environment";
import Drawer from "../../../Components/UI/Drawer";
import { request } from "../../../Services";
import UIFile from "../../../Components/UI/File";
import Modal from "../../../Components/UI/Modal";
import {
  registryColumns,
  passingColumns,
  relationColumns,
  checkMailColumns,
  filesColumns,
} from "../utils";
import { ModalContext, ModalProvider } from "../../../Contexts/modal.context";
import User from "../../../Services/user";
import styles from "./styles.module.scss";

const { Option } = Select;
const { Title } = Typography;
const { TabPane } = Tabs;
const { TextArea } = Input;

function FormComponent(props) {
  const [selectId, setSelectId] = useState(undefined);
  const [selectData, setSelectData] = useState([]);
  const [fileInfoVisible, setFileInfoVisible] = useState(true);
  const [signVisible, setSignVisible] = useState(true);
  const [dataMail, setDataMail] = useState([]);
  const [mailFiles, setMailFiles] = useState([]);
  const [visibleModal, setVisibleModal] = useState(0);
  const [original, setOriginal] = useState(false);
  const [sendMail, setSendMail] = useState(false);
  const [fileRowId, setFileRowId] = useState(false);
  const {
    name,
    add,
    passing,
    registry,
    relation,
    includedFiles,
    documentData,
    isEdit,
    fileTabs,
    loading,
    fetchTableData,
    changeFilesData,
    view,
  } = props;
  const modalContext = useContext(ModalContext);
  const formData = new FormData();

  const intl = (selector) => {
    return translate(selector, props.lang);
  };

  const onFormFinish = async (values) => {
    console.log("Success:", values);
    const currentUser = User.decode();
    formData.append("userId", currentUser?.id);
    formData.append("uploadFile", values.uploadFile?.file.originFileObj);
    formData.append("original", values.original);
    formData.append("toSend", values.toSend);
    formData.append("sign", values.sign);
    formData.append("signNotes", values.signNotes);
    // formData.append("password", values.password);
    // formData.append("signKey", values.signKey?.file.originFileObj);
    // formData.append("certificate", values.certificate?.file.originFileObj);
    formData.append("documentId", documentData?.bta_document_id);
    formData.append("registered", values.registered);
    formData.append(
      "fromRegistered",
      values.fromRegistered.format("DD-MM-YYYY")
    );

    if (add) {
      await request("/incoming/insertModalDocuments", {
        method: "post",
        body: { ...values, userId: currentUser?.id },
        cancelTokenKey: "insertModalDocuments",
      });
    } else {
      await request("/incoming/updateModalDocuments", {
        method: "post",
        body: { ...values, id: documentData?.id },
        cancelTokenKey: "updateModalDocuments",
      });
    }
    values.uploadFile &&
      (await request("/incoming/insertModalFiles", {
        method: "post",
        body: formData,
        cancelTokenKey: "insertModalFiles",
        headers: { "Content-Type": "multipart/form-data" },
      }));
    modalContext.close(name);
    window.location.href =
      values.sign && `${environment.url}cabinet/others`;
    fetchTableData();
  };

  const onFormFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  function validator(subject, value) {
    return new Promise((resolve, reject) => {
      if (!value) {
        return reject(new Error(subject.message));
      }

      return resolve();
    });
  }

  const fetchSelect = async () => {
    const { content } = await request(
      `/incoming/tableNomenklatureDocuments?dataId=${selectId}`,
      { method: "get", cancelTokenKey: "nomenclature" }
    );
    if (content) {
      setSelectData(content);
    }
  };

  const getMail = async () => {
    const { content } = await request("/mail/getMail", {
      method: "get",
      cancelTokenKey: "getMail",
    });
    if (content) {
      setDataMail(content);
    }
  };

  const onClickRow = (record) => {
    setMailFiles(record.attachments);
    setVisibleModal((prev) => prev + 1);
  };

  const onSignVisibleChange = () => {
    setSignVisible((current) => !current);
  };

  const onHowerRow = (record) => {
    setFileRowId(record.id);
    setOriginal(!!record.isOriginal);
    setSendMail(!!record.isSendEmail);
  };

  const onOriginalChange = (e) => {
    setOriginal(async () => {
      await request("/incoming/updatefiles", {
        method: "post",
        body: { original: e.target.checked, id: fileRowId },
        cancelTokenKey: "updatefiles",
      });
      const updateFiles = await request(
        `/incoming/includedFiles?btaDocId=${documentData?.bta_document_id}`,
        {
          method: "get",
          cancelTokenKey: "includedFiles",
        }
      );
      changeFilesData("includedFiles", updateFiles.content);
      return e.target.checked;
    });
  };

  const onSendMailChange = (e) => {
    setSendMail(async () => {
      await request("/incoming/updatefiles", {
        method: "post",
        body: { sendMail: e.target.checked, id: fileRowId },
        cancelTokenKey: "updatefiles",
      });
      const updateFiles = await request(
        `/incoming/includedFiles?btaDocId=${documentData?.bta_document_id}`,
        {
          method: "get",
          cancelTokenKey: "includedFiles",
        }
      );
      changeFilesData("includedFiles", updateFiles.content);
      return e.target.checked;
    });
  };

  const onAddBarcode = async () => {
    await request(
      `/incoming/addbarcode?btaDocId=${documentData?.bta_document_id}`,
      {
        method: "post",
        body: { id: fileRowId },
        cancelTokenKey: "addbarcode",
      }
    );
  };

  const uploadProps = {
    name: "file",
    multiple: true,
    action: `${environment.api}incoming/insertModalFiles`,
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
    onChange(info) {
      // if (info.file.status !== "uploading") {
      //     console.log(info.file, info.fileList);
      // }
      if (info.file.status === "done") {
        // setFileList(info.file.originFileObj);
        setFileInfoVisible(false);
        console.log(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        console.log(`${info.file.name} file upload failed.`);
      }
      if (info.file.status === "removed") {
        if (info.fileList.length === 0) {
          setFileInfoVisible(true);
        }
      }
    },
  };

  const uploadProps2 = {
    name: "file",
    action: `${environment.api}incoming/insertModalFiles`,
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
    onChange(info) {
      if (info.file.status === "done") {
        console.log(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        console.log(`${info.file.name} file upload failed.`);
      }
    },
  };

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Checkbox checked={original} onChange={onOriginalChange}>
          {intl("original")}
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="2">
        <Checkbox checked={sendMail} onChange={onSendMailChange}>
          {intl("to-send")}
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="3">
        <Button className={styles["button"]} onClick={onAddBarcode}>
          {intl("add-barcode")}
        </Button>
      </Menu.Item>
      <Menu.Item key="4">
        <Button className={styles["button"]}>{intl("delete-file")}</Button>
      </Menu.Item>
      <Menu.Item key="5">
        <ModalProvider
          style={{ marginLeft: "0", marginTop: "0", width: "100%" }}
          width={500}
        >
          <Modal name={intl("sign-file")}>
            <Row>
              <Col lg={12} md={12} sm={24}>
                <Form.Item label={intl("electronic-signature")} name="signKey">
                  <Upload {...uploadProps2}>
                    <Button icon={<UploadOutlined />}>
                      {intl("select-file")}
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
              <Col lg={12} md={12} sm={24}>
                <Form.Item label={intl("certificate")} name="certificate">
                  <Upload {...uploadProps2}>
                    <Button icon={<UploadOutlined />}>
                      {intl("select-file")}
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col lg={24} md={24} sm={24}>
                <Form.Item label={intl("password")} name="password">
                  <Input.Password />
                </Form.Item>
              </Col>
            </Row>
          </Modal>
        </ModalProvider>
      </Menu.Item>
      <Menu.Item key="6">
        <Button className={styles["button"]}>{intl("check-signature")}</Button>
      </Menu.Item>
      <Menu.Item key="7">
        <Button className={styles["button"]}>{intl("remove-signature")}</Button>
      </Menu.Item>
    </Menu>
  );

  const checkMailCol = checkMailColumns(intl);
  const registryCol = registryColumns(intl);
  const passingCol = passingColumns(intl);
  const relationCol = relationColumns(intl);
  const filesCol = filesColumns(intl, menu);
  const dateFormat = "DD.MM.YYYY";

  return (
    <ConfigProvider locale={locale}>
      <Form
        className={styles["form"]}
        layout="vertical"
        onFinish={onFormFinish}
        onFinishFailed={onFormFailed}
        initialValues={{
          performer: false,
          coPerformer: false,
          nomenclature: documentData?.nomenklatureId,
          registered: documentData?.Reestr_no,
          fromRegistered: moment(
            documentData?.Reestr_date ?? new Date(),
            dateFormat
          ),
          fromOutput:
            documentData?.Enter_date &&
            moment(documentData?.Enter_date, dateFormat),
          controlDate:
            documentData?.DateControl &&
            moment(documentData?.DateControl, dateFormat),
          signed: documentData?.PodpisCorrespondents,
          additionally: documentData?.DopCorrespondents,
          output: documentData?.Enter_no,
          coPerformers: documentData?.OtherCoresp,
          summary: documentData?.Content,
          departure: documentData?.MailingNum,
          numberOfApplications: documentData?.KolvoEx,
          numberOfPages: documentData?.KolvoPages,
          shelfLife: documentData?.StorageLife,
          notes: documentData?.prim_spisan,
          original: false,
          toSend: false,
          sign: false,
        }}
      >
        <Row>
          <Col lg={7} md={7} sm={24}>
            <Title level={4}>{intl("registration-department")}</Title>
            <Form.Item
              label={intl("registration-department")}
              name="registrationDepartment"
              rules={[
                {
                  required: true,
                  message: intl("registration-department-required"),
                  validator: validator,
                },
              ]}
            >
              <Drawer
                url="/incoming/tableOtdelDocuments"
                options={{
                  method: "get",
                  cancelTokenKey: "registrationDepartment",
                }}
                setSelectId={setSelectId}
                documentName={documentData?.NAME_OTDEL}
                documentId={documentData?.otdelId}
                isEdit={isEdit}
              />
            </Form.Item>
            <Form.Item
              label={intl("nomenclature")}
              name="nomenclature"
              rules={[
                {
                  required: true,
                  message: intl("nomenclature-required"),
                  validator: validator,
                },
              ]}
            >
              <Select
                defaultValue={documentData?.nomenklatureName}
                onDropdownVisibleChange={
                  documentData !== undefined ? null : fetchSelect
                }
                disabled={isEdit}
              >
                {selectData.length > 0 ? (
                  selectData.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.Name}
                    </Option>
                  ))
                ) : documentData !== undefined ? (
                  <Option
                    key={documentData?.nomenklatureId}
                    value={documentData?.nomenklatureId}
                  >
                    {documentData?.nomenklatureName}
                  </Option>
                ) : null}
              </Select>
            </Form.Item>
            <Form.Item
              label={intl("type-of-document")}
              name="documentType"
              rules={[
                {
                  required: true,
                  message: intl("type-of-document-required"),
                  validator: validator,
                },
              ]}
            >
              <Drawer
                url="/incoming/tableTypeDocDocuments"
                options={{ method: "get", cancelTokenKey: "documentType" }}
                documentName={documentData?.Name_TypeDoc}
                documentCode={documentData?.Code_TypeDoc}
                documentId={documentData?.typeDocId}
                isEdit={isEdit}
                style={{ marginLeft: "8px" }}
                code
              />
            </Form.Item>
            <Form.Item label={intl("input-index-cor")} name="inputIndex">
              <Drawer
                url="/incoming/tableReferenceDocuments"
                options={{ method: "get", cancelTokenKey: "inputIndex" }}
                documentName={documentData?.referenceName}
                documentCode={documentData?.referenceCode}
                documentId={documentData?.referenceId}
                isEdit={isEdit}
                style={{ marginLeft: "8px" }}
                code
              />
            </Form.Item>
            <Row>
              <Col lg={14} md={14} sm={24}>
                <Form.Item label={intl("registered-no")} name="registered">
                  <Input disabled={isEdit} />
                </Form.Item>
              </Col>
              <Col
                lg={{ span: 8, offset: 2 }}
                md={{ span: 8, offset: 2 }}
                sm={24}
              >
                <Form.Item
                  label={intl("from")}
                  name="fromRegistered"
                  rules={[
                    {
                      required: true,
                      message: intl("from-receipt"),
                    },
                  ]}
                >
                  <DatePicker format={dateFormat} disabled={isEdit} />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col lg={{ span: 7, offset: 1 }} md={{ span: 7, offset: 1 }} sm={24}>
            <Title level={4}>{intl("correspondent")}</Title>
            <Form.Item label={intl("category")} name="category">
              <Drawer
                url="/incoming/tableKindCorespDocuments"
                options={{ method: "get", cancelTokenKey: "category" }}
                documentName={documentData?.kindCorespName}
                documentCode={documentData?.kindCorespCode}
                documentId={documentData?.kindCorespId}
                isEdit={isEdit}
                style={{ marginLeft: "8px" }}
                code
              />
            </Form.Item>
            <Form.Item
              label={intl("correspondent")}
              name="correspondent"
              rules={[
                {
                  required: true,
                  message: intl("correspondent-required"),
                  validator: validator,
                },
              ]}
            >
              <Drawer
                url="/incoming/tableKontragentDocuments"
                options={{ method: "get", cancelTokenKey: "correspondent" }}
                documentName={documentData?.NAME_FIRM_PLAT}
                documentId={documentData?.kontragentId}
                isEdit={isEdit}
              />
            </Form.Item>
            <Form.Item label={intl("signed")} name="signed">
              <Input disabled={isEdit} />
            </Form.Item>
            <Form.Item label={intl("additionally")} name="additionally">
              <Input disabled={isEdit} />
            </Form.Item>
            <Row>
              <Col lg={8} md={8} sm={24}>
                <Form.Item label={intl("output-no")} name="output">
                  <Input disabled={isEdit} />
                </Form.Item>
              </Col>
              <Col
                lg={{ span: 7, offset: 1 }}
                md={{ span: 7, offset: 1 }}
                sm={24}
              >
                <Form.Item label={intl("from")} name="fromOutput">
                  <DatePicker format={dateFormat} disabled={isEdit} />
                </Form.Item>
              </Col>
              <Col
                lg={{ span: 7, offset: 1 }}
                md={{ span: 7, offset: 1 }}
                sm={24}
              >
                <Form.Item label={intl("control-date")} name="controlDate">
                  <DatePicker format={dateFormat} disabled={isEdit} />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col lg={{ span: 8, offset: 1 }} md={{ span: 8, offset: 1 }} sm={24}>
            <Title level={4}>{intl("recipient")}</Title>
            <Row>
              <Col lg={16} md={16} sm={24}>
                <Form.Item label={intl("received")} name="received">
                  <Drawer
                    url="/incoming/tableClerksDocuments"
                    options={{ method: "get", cancelTokenKey: "received" }}
                    documentName={documentData?.FIO}
                    documentCode={documentData?.TAB_NO}
                    documentId={documentData?.clerksId}
                    isEdit={isEdit}
                    style={{ marginLeft: "8px" }}
                    code
                  />
                </Form.Item>
              </Col>
              <Col
                lg={{ span: 6, offset: 2 }}
                md={{ span: 6, offset: 2 }}
                sm={24}
              >
                <Form.Item
                  label={intl("performer")}
                  name="performer"
                  valuePropName="checked"
                >
                  <Checkbox disabled={isEdit} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col lg={16} md={16} sm={24}>
                <Form.Item label={intl("co-performers")} name="coPerformers">
                  <Input disabled={isEdit} />
                </Form.Item>
              </Col>
              <Col
                lg={{ span: 6, offset: 2 }}
                md={{ span: 6, offset: 2 }}
                sm={24}
              >
                <Form.Item
                  label={intl("co-performer")}
                  name="coPerformer"
                  valuePropName="checked"
                >
                  <Checkbox disabled={isEdit} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label={intl("summary")} name="summary">
              <TextArea rows={4} disabled={isEdit} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col lg={24} md={24} sm={24}>
            <Tabs defaultActiveKey="1">
              <TabPane tab={intl("additional-information")} key="1">
                <Row>
                  <Col lg={11} md={11} sm={24}>
                    <Form.Item
                      label={intl("type-of-receipt")}
                      name="typeOfReceipt"
                    >
                      <Drawer
                        url="/incoming/tableTypeSendDocuments"
                        options={{
                          method: "get",
                          cancelTokenKey: "typeOfReceipt",
                        }}
                        documentName={documentData?.Name_TypeSend}
                        documentCode={documentData?.Code_TypeSend}
                        documentId={documentData?.typeSendId}
                        isEdit={isEdit}
                        style={{ marginLeft: "8px" }}
                        code
                      />
                    </Form.Item>
                    <Form.Item label={intl("no-departure")} name="departure">
                      <Input disabled={isEdit} />
                    </Form.Item>
                    <Row>
                      <Col lg={7} md={7} sm={24}>
                        <Form.Item
                          label={intl("number-of-applications")}
                          name="numberOfApplications"
                        >
                          <Input disabled={isEdit} />
                        </Form.Item>
                      </Col>
                      <Col
                        lg={{ span: 7, offset: 1 }}
                        md={{ span: 7, offset: 1 }}
                        sm={24}
                      >
                        <Form.Item
                          label={intl("number-of-pages")}
                          name="numberOfPages"
                        >
                          <Input disabled={isEdit} />
                        </Form.Item>
                      </Col>
                      <Col
                        lg={{ span: 7, offset: 1 }}
                        md={{ span: 7, offset: 1 }}
                        sm={24}
                      >
                        <Form.Item
                          label={intl("shelf-life-years")}
                          name="shelfLife"
                        >
                          <Input disabled={isEdit} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    lg={{ span: 11, offset: 2 }}
                    md={{ span: 11, offset: 2 }}
                    sm={24}
                  >
                    <Form.Item label={intl("case")} name="case">
                      <Drawer
                        url="/incoming/tableOfficialDocYearDocuments"
                        options={{ method: "get", cancelTokenKey: "case" }}
                        documentName={documentData?.Name_OfficialDoc}
                        documentCode={documentData?.Code_OfficialDoc}
                        documentId={documentData?.officialDocId}
                        isEdit={isEdit}
                        style={{ marginLeft: "8px" }}
                        code
                        table
                      />
                    </Form.Item>
                    <Form.Item label={intl("notes")} name="notes">
                      <Input disabled={isEdit} />
                    </Form.Item>
                  </Col>
                </Row>
              </TabPane>
              {!fileTabs ? (
                <TabPane tab={intl("files")} key="2">
                  {/* {includedFiles?.length > 0 || mailFiles?.length > 0 ? (
                                        <div
                                            style={{
                                                padding: "1rem",
                                                display: "flex",
                                                flexDirection: "row",
                                                flexWrap: "wrap",
                                            }}
                                        >
                                            {includedFiles?.map((file) => {
                                                return (
                                                    <UIFile
                                                        key={file.id}
                                                        onClick={() => this.props.fetchIncludedFile(file, this.props.includedFiles.id, file.fileextention)}
                                                        title={file.filename}
                                                        download
                                                    />
                                                );
                                            })}
                                            {mailFiles?.map((file) => {
                                                return (
                                                    <UIFile
                                                        key={file.contentId}
                                                        title={file.filename}
                                                        download
                                                    />
                                                );
                                            })}
                                        </div>
                                    ) : null} */}
                  <Row>
                    <Col lg={6} md={6} sm={24}>
                      <Form.Item label={intl("upload-file")} name="uploadFile">
                        <Upload {...uploadProps}>
                          <Button icon={<UploadOutlined />} disabled={isEdit}>
                            {intl("select-file")}
                          </Button>
                        </Upload>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row hidden={fileInfoVisible}>
                    <Col lg={6} md={6} sm={24}>
                      <Form.Item name="original" valuePropName="checked">
                        <Checkbox disabled={isEdit}>
                          {intl("original")}
                        </Checkbox>
                      </Form.Item>
                      <Form.Item name="toSend" valuePropName="checked">
                        <Checkbox disabled={isEdit}>{intl("to-send")}</Checkbox>
                      </Form.Item>
                      <Form.Item name="sign" valuePropName="checked">
                        <Checkbox disabled={isEdit}>
                          {intl("sign-file")}
                        </Checkbox>
                      </Form.Item>
                      <Form.Item>
                        <Button style={{ width: "50%" }}>
                          {intl("add-barcode")}
                        </Button>
                      </Form.Item>
                      {/* <Form.Item>
                        <Button
                          onClick={onSignVisibleChange}
                          style={{ width: "50%" }}
                        >
                            {intl("sign-file")}
                        </Button>
                      </Form.Item> */}
                      {/* <Row hidden={signVisible}>
                        <Col lg={12} md={12} sm={24}>
                          <Form.Item
                            label={intl("electronic-signature")}
                            name="signKey"
                          >
                            <Upload {...uploadProps2}>
                              <Button icon={<UploadOutlined />}>
                                {intl("select-file")}
                              </Button>
                            </Upload>
                          </Form.Item>
                        </Col>
                        <Col lg={12} md={12} sm={24}>
                          <Form.Item
                            label={intl("certificate")}
                            name="certificate"
                          >
                            <Upload {...uploadProps2}>
                              <Button icon={<UploadOutlined />}>
                                {intl("select-file")}
                              </Button>
                            </Upload>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row hidden={signVisible}>
                        <Col lg={24} md={24} sm={24}>
                          <Form.Item label={intl("password")} name="password">
                            <Input.Password />
                          </Form.Item>
                        </Col>
                      </Row> */}
                      <Form.Item label={intl("notes")} name="signNotes">
                        <Input
                          placeholder={intl("add-text-255")}
                          disabled={isEdit}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* <Row>
                                        <Col lg={4} md={4} sm={24}>
                                            <Form.Item name="uploadFile">
                                                <Modal
                                                    nameBtn={intl("check-mail")}
                                                    style={{ marginLeft: "0", marginTop: "0" }}
                                                    connectMail={getMail}
                                                    visibleModal={visibleModal}
                                                >
                                                    <Table
                                                        dataSource={dataMail}
                                                        columns={checkMailCol}
                                                        onRow={(record) => {
                                                            return {
                                                                onDoubleClick: () => onClickRow(record),
                                                            };
                                                        }}
                                                    />
                                                </Modal>
                                            </Form.Item>
                                        </Col>
                                    </Row> */}
                  <Row>
                    <Col lg={24} md={24} sm={24}>
                      <Table
                        className={styles["table"]}
                        dataSource={includedFiles}
                        columns={filesCol}
                        pagination={{ position: ["bottomCenter"] }}
                        size="small"
                        onRow={(record) => {
                          return {
                            onMouseEnter: () => {
                              onHowerRow(record);
                            },
                          };
                        }}
                      />
                    </Col>
                  </Row>
                </TabPane>
              ) : (
                <>
                  <TabPane
                    tab={intl("included-files")}
                    key="3"
                    disabled={includedFiles?.length === 0}
                  >
                    {includedFiles?.length > 0 ? (
                      <div
                        style={{
                          padding: "1rem",
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        }}
                      >
                        {includedFiles?.map((file) => {
                          return (
                            <UIFile
                              key={file.id}
                              /* onClick={() => fetchIncludedFile(file, includedFiles.id)} */ title={
                                file.filename
                              }
                              download
                              loading={loading}
                            />
                          );
                        })}
                      </div>
                    ) : (
                      <Empty description={intl("no-data")} />
                    )}
                  </TabPane>
                  <TabPane
                    tab={intl("control-terms")}
                    key="4"
                    disabled={registry?.length === 0}
                  >
                    {registry?.length > 0 ? (
                      <Table
                        columns={registryCol}
                        pagination={false}
                        rowKey={(record) => record.id}
                        scroll={{ x: 1024 }}
                        locale={{ emptyText: intl("no-data") }}
                        loading={{
                          spinning: loading,
                          indicator: (
                            <Spin
                              indicator={
                                <LoadingOutlined
                                  style={{ fontSize: 24 }}
                                  spin
                                />
                              }
                            />
                          ),
                        }}
                        dataSource={registry?.map((item) => ({
                          ...item,
                          key: item.id,
                        }))}
                      />
                    ) : (
                      <Empty description={intl("no-data")} />
                    )}
                  </TabPane>
                  <TabPane
                    tab={intl("passing-document")}
                    key="5"
                    disabled={passing?.length === 0}
                  >
                    {passing?.length > 0 ? (
                      <Table
                        columns={passingCol}
                        pagination={false}
                        rowKey={(record) => record.id}
                        scroll={{ x: 1024 }}
                        locale={{ emptyText: intl("no-data") }}
                        loading={{
                          spinning: loading,
                          indicator: (
                            <Spin
                              indicator={
                                <LoadingOutlined
                                  style={{ fontSize: 24 }}
                                  spin
                                />
                              }
                            />
                          ),
                        }}
                        dataSource={passing?.map((item) => ({
                          ...item,
                          key: item.id,
                        }))}
                      />
                    ) : (
                      <Empty description={intl("no-data")} />
                    )}
                  </TabPane>
                  <TabPane
                    tab={intl("linked-documents")}
                    key="6"
                    disabled={relation?.length === 0}
                  >
                    {relation?.length > 0 ? (
                      <Table
                        columns={relationCol}
                        pagination={false}
                        rowKey={(record) => record.id}
                        scroll={{ x: 1024 }}
                        locale={{ emptyText: intl("no-data") }}
                        loading={{
                          spinning: loading,
                          indicator: (
                            <Spin
                              indicator={
                                <LoadingOutlined
                                  style={{ fontSize: 24 }}
                                  spin
                                />
                              }
                            />
                          ),
                        }}
                        dataSource={relation?.map((item) => ({
                          ...item,
                          key: item.id,
                        }))}
                      />
                    ) : (
                      <Empty description={intl("no-data")} />
                    )}
                  </TabPane>
                </>
              )}
            </Tabs>
          </Col>
        </Row>

        <Form.Item>
          <Space style={{ position: "absolute", right: 0 }}>
            <Button
              onClick={() => {
                if (isEdit) {
                  window.history.back();
                  return false;
                } else {
                  modalContext.close(name);
                }
              }}
            >
              {intl("cancel")}
            </Button>
            {isEdit ? null : (
              <Button
                type="primary"
                htmlType="submit"
                className={styles["button"]}
              >
                {intl("ok")}
              </Button>
            )}
          </Space>
        </Form.Item>
      </Form>
    </ConfigProvider>
  );
}

function mapStateToProps(state) {
  return {
    lang: state.lang.value,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: (language) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
    raiseMessage: (message) =>
      dispatch({ type: Actions.RAISE_MESSAGE, message }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormComponent);
