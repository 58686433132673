import { connect } from "react-redux";
import { Col, Row } from "antd";

import CabinetLayout from "../../HOC/CabinetLayout";
import * as Actions from "../../Store/actions";
import Receipts from "../../Containers/Statistics/Receipts";

function ReceiptsPage(props) {
  return (
    <CabinetLayout activeLink={props.match.path} {...props}>
      <Row>
        <Col lg={24} md={24} sm={24}>
          <Receipts />
        </Col>
      </Row>
    </CabinetLayout>
  );
}

function mapStateToProps(state) {
  return {
    lang: state.lang.value,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: (language) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
    raiseMessage: (message) =>
      dispatch({ type: Actions.RAISE_MESSAGE, message }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptsPage);
