import React from "react";
import { Pie } from "@ant-design/charts";

export default function configureDiagram({ data, settingsConfig }) {
  if (!data) return null;

  const config = {
    // title: {
    //   content: "text title",
    //   style: {
    //     fontFamily: '-apple-system, BlinkMacSystemFont,Segoe UI, Roboto',
    //     fontWeight: '500',
    //     fontSize: 22
    //   }
    // },
    data,
    angleField: "CurrentYear",
    colorField: "Name",
    radius: 0.8,
    label: {
      type: "inner",
    },
    style: {
      height: "500px",
      // width: "700px",
    },
    state: {
      active: {
        style: {
          lineWidth: 0,
          fillOpacity: 0.65,
        },
      },
    },
    interactions: [{ type: "element-selected" }, { type: "element-active" }],
  };

  if (settingsConfig !== undefined) {
    Object.keys(settingsConfig).forEach(
      (key) => (config[key] = settingsConfig[key])
    );
  }

  return <Pie {...config} />;
}
