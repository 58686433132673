import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, Row, Col, Modal, Form, Space, Input } from "antd";

import * as Actions from "../../../Store/actions";
import { translate } from "../../../Translation";
import { request } from "../../../Services";
import SelectComponent from "../../UI/Select";

function ModalMailUserSetup(props) {
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectData, setSelectData] = useState([]);
  const [form] = Form.useForm();
  form.setFieldsValue({ serverId: props.serverId });

  const intl = (selector) => {
    return translate(selector, props.lang);
  };

  const showModal = () => {
    setModalVisible(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const onFormFinish = (values) => {
    console.log("values: ", values);
    (async () => {
      await request(`/profile/insertmailusersettings`, {
        method: "post",
        body: values,
        cancelTokenKey: "insertMailUserSettings",
      });
    })();
    handleCancel();
  };

  const onFormFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const fetchSelectData = () => {
    (async () => {
      const data = await request(`/profile/webusers`, {
        method: "get",
        cancelTokenKey: "webusers",
      });
      if (data) {
        setSelectData(data.content);
      }
    })();
  };

  const onChangeSelect = (name, value) => {
    form.setFieldsValue({ userId: value });
  };

  return (
    <>
      <Button
        type="primary"
        onClick={showModal}
        style={{ marginTop: 20, marginLeft: 8 }}
        disabled={props.disabled}
      >
        {intl("user-settings")}
      </Button>
      <Modal
        width={400}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose={true}
      >
        <Row>
          <Col lg={24} md={24} sm={24} style={{ padding: "0 1rem" }}>
            <Form
              form={form}
              layout="vertical"
              onFinish={onFormFinish}
              onFinishFailed={onFormFailed}
            >
              <Row>
                <Col lg={24} md={24} sm={24}>
                  <Form.Item name="serverId" hidden={true}></Form.Item>
                  <Form.Item
                    name="userId"
                    label={intl("user")}
                    rules={[
                      {
                        required: true,
                        message: intl("user-required"),
                      },
                    ]}
                  >
                    <SelectComponent
                      name={"userId"}
                      onChangeSelect={onChangeSelect}
                      rolesData={selectData}
                      fetchData={fetchSelectData}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col lg={24} md={24} sm={24}>
                  <Form.Item
                    name="email"
                    label={intl("email")}
                    rules={[
                      {
                        required: true,
                        message: intl("email-required"),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col lg={24} md={24} sm={24}>
                  <Form.Item
                    name="password"
                    label={intl("password")}
                    rules={[
                      {
                        required: true,
                        message: intl("password-required"),
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <Space style={{ position: "absolute", right: 0 }}>
                  <Button onClick={handleCancel}> {intl("cancel")} </Button>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    {intl("ok")}
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

function mapStateToProps(state) {
  return {
    lang: state.lang.value,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: (language) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalMailUserSetup);
