import { useContext } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Space,
  ConfigProvider,
  Checkbox,
} from "antd";
import { connect } from "react-redux";
import "moment/locale/uk";
import locale from "antd/lib/locale/uk_UA";

import * as Actions from "../../../Store/actions";
import { translate } from "../../../Translation";
import Drawer from "../../../Components/UI/Drawer";
import { request } from "../../../Services";
import { ModalContext } from "../../../Contexts/modal.context";
import User from "../../../Services/user";
import styles from "./styles.module.scss";

function FormComponent(props) {
  const [form] = Form.useForm();
  const { rowData, rowId, fetchTableData, pathname, tableData, name } = props;
  const modalContext = useContext(ModalContext);

  // const layout = {
  //     labelCol: { span: 5 },
  //     wrapperCol: { span: 19 },
  // }

  const layoutBtn = {
    wrapperCol: { span: 24 },
  };

  const intl = (selector) => {
    return translate(selector, props.lang);
  };

  const onFormFinish = async (values) => {
    console.log("Success:", values);
    const currentUser = User.decode();

    rowId === 0
      ? await request(`${pathname}/adddata`, {
          method: "post",
          body: { ...values, userId: currentUser?.id },
          cancelTokenKey: "adddata",
        })
      : await request(`${pathname}/updatedata`, {
          method: "post",
          body: { ...values, id: rowId },
          cancelTokenKey: "updatedata",
        });
    modalContext.close(name);
    fetchTableData();
  };

  const onFormFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  function validator(subject, value) {
    return new Promise((resolve, reject) => {
      if (!value) {
        return reject(new Error(subject.message));
      }

      return resolve();
    });
  }
  return (
    <ConfigProvider locale={locale}>
      <Form
        // {...layout}
        layout="vertical"
        form={form}
        onFinish={onFormFinish}
        onFinishFailed={onFormFailed}
        initialValues={{
          code: rowData?.Code,
          name: rowData?.Name,
          nameFull: rowData?.NameFull,
          nameShort: rowData?.NameShort,
          notes: rowData?.Notes,
          kp: rowData?.KP,
          okp: rowData?.OKP,
          etks: rowData?.ETKS,
          index: rowData?.Code_OfficialDoc,
          heading: rowData?.Name_OfficialDoc,
          shelfLife: rowData?.LimitationText,
          notes2: rowData?.Prim_OfficialDoc,
          refersTo: rowData?.ParentId,
          isManagerHome: rowData?.isManagerHome,
          isProviderHome: rowData?.isProviderHome,
        }}
      >
        <Row>
          <Col lg={24} md={24} sm={24}>
            {tableData.some((i) => i.hasOwnProperty("Code")) ? (
              <Form.Item label={intl("code")} name="code">
                <Input />
              </Form.Item>
            ) : null}
            {tableData.some((i) => i.hasOwnProperty("KP")) ? (
              <Form.Item label={intl("code-kp")} name="kp">
                <Input />
              </Form.Item>
            ) : null}
            {tableData.some((i) => i.hasOwnProperty("OKP")) ? (
              <Form.Item label={intl("code-okp")} name="okp">
                <Input />
              </Form.Item>
            ) : null}
            {tableData.some((i) => i.hasOwnProperty("ETKS")) ? (
              <Form.Item label={intl("code-etks")} name="etks">
                <Input />
              </Form.Item>
            ) : null}
            {tableData.some((i) => i.hasOwnProperty("Name")) ? (
              <Form.Item
                label={intl("name")}
                name="name"
                rules={[
                  {
                    required: true,
                    message: intl("name"),
                    validator: validator,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            ) : null}
            {tableData.some((i) => i.hasOwnProperty("NameFull")) ? (
              <Form.Item label={intl("namefull")} name="nameFull">
                <Input />
              </Form.Item>
            ) : null}
            {tableData.some((i) => i.hasOwnProperty("NameShort")) ? (
              <Form.Item label={intl("nameshort")} name="nameShort">
                {/* nameshort */}
                <Input />
              </Form.Item>
            ) : null}
            {tableData.some((i) => i.hasOwnProperty("NameGroup")) ? (
              <Form.Item label={intl("namegroup")} name="nameGroup">
                <Drawer
                  url="/directories/groups-performers/getdata"
                  options={{ method: "get", cancelTokenKey: "nameGroup" }}
                  documentName={rowData?.NameGroup}
                  documentId={rowData?.id}
                />
              </Form.Item>
            ) : null}
            {tableData.some((i) => i.hasOwnProperty("Department")) ? (
              <Form.Item label={intl("department")} name="department">
                <Drawer
                  url="/directories/name-departments/getdata?id=2"
                  options={{ method: "get", cancelTokenKey: "department" }}
                  documentName={rowData?.Department}
                  documentId={rowData?.id}
                />
              </Form.Item>
            ) : null}
            {tableData.some((i) => i.hasOwnProperty("ParentId")) ? (
              <Form.Item label={intl("refers-to2")} name="refersTo">
                <Drawer
                  url="/directories/groups-performers/getdata"
                  options={{ method: "get", cancelTokenKey: "refersTo" }}
                  documentName={rowData?.Name}
                  documentId={rowData?.id}
                />
              </Form.Item>
            ) : null}
            {tableData.some((i) => i.hasOwnProperty("Notes")) ? (
              <Form.Item label={intl("notes")} name="notes">
                <Input />
              </Form.Item>
            ) : null}
            <Row style={{ justifyContent: "space-around" }}>
              {tableData.some((i) => i.hasOwnProperty("isManagerHome")) ? (
                <Form.Item
                  label={intl("is-manager")}
                  name="isManagerHome"
                  valuePropName="checked"
                >
                  <Checkbox />
                </Form.Item>
              ) : null}
              {tableData.some((i) => i.hasOwnProperty("isProviderHome")) ? (
                <Form.Item
                  label={intl("is-provider")}
                  name="isProviderHome"
                  valuePropName="checked"
                >
                  <Checkbox />
                </Form.Item>
              ) : null}
            </Row>
            {tableData.some((i) => i.hasOwnProperty("Code_OfficialDoc")) ? (
              <Form.Item
                label={intl("index")}
                name="index"
                rules={[
                  {
                    required: true,
                    message: intl("index-required"),
                    validator: validator,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            ) : null}
            {tableData.some((i) => i.hasOwnProperty("Name_OfficialDoc")) ? (
              <Form.Item
                label={intl("heading")}
                name="heading"
                rules={[
                  {
                    required: true,
                    message: intl("heading-required"),
                    validator: validator,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            ) : null}
            {tableData.some((i) => i.hasOwnProperty("LimitationText")) ? (
              <Form.Item label={intl("shelf-life")} name="shelfLife">
                <Input />
              </Form.Item>
            ) : null}
            {tableData.some((i) => i.hasOwnProperty("Prim_OfficialDoc")) ? (
              <Form.Item label={intl("notes")} name="notes2">
                <Input />
              </Form.Item>
            ) : null}
          </Col>
        </Row>

        <Form.Item {...layoutBtn}>
          <Space style={{ display: "flex", justifyContent: "end" }}>
            <Button onClick={() => modalContext.close(name)}>
              {intl("cancel")}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className={styles["button"]}
            >
              {intl("ok")}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </ConfigProvider>
  );
}

function mapStateToProps(state) {
  return {
    lang: state.lang.value,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: (language) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
    raiseMessage: (message) =>
      dispatch({ type: Actions.RAISE_MESSAGE, message }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormComponent);
