import { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Col, Row, Space, DatePicker, Table, ConfigProvider } from "antd";
import "moment/locale/uk";
import locale from "antd/es/locale/uk_UA";

import * as Actions from "../../../Store/actions";
import styles from "./styles.module.css";
import { translate } from "../../../Translation";
import { request } from "../../../Services";
import Pie from "../../../Components/Diagrams/Pie";
import { leftTableColumns, rightTableColumns } from "./utils";

// function ChartReceipts(props) {
//   const [fromDate, setFromDate] = useState(moment(Date.now()).startOf("month"));
//   const [toDate, setToDate] = useState(moment(Date.now()).startOf("month").add(1, "M"));
//   const [data, setData] = useState(null);
//   const [dataDocType, setDataDocType] = useState(null);
//   const [loading, setLoading] = useState(null);

//   useEffect(() => {
//     // onDateChange(Date.now());
//     fetchCharts();
//   }, []);

//   const intl = (selector) => {
//     return translate(selector, props.lang);
//   }

//   const fetchCharts = async () => {
//     setLoading(true);
//     const data = await request('/charts/receipts', { method: 'post', body: fromDate.toISOString(), cancelTokenKey: 'receipts' });
//     console.log('data: ', data);
//     const dataDocType = await request('/charts/receiptsDocType', { method: 'post', body: fromDate.toISOString(), cancelTokenKey: 'receiptsDocType' });
//     console.log('dataDocType: ', dataDocType);
//     if (data) {
//       setLoading(false);
//       setData(data.content);
//       setDataDocType(dataDocType.content);
//     }
//   }

//   const onDateChange = (date) => {
//     setFromDate(moment(date).startOf("month"));
//     setToDate(moment(date).startOf("month").add(1, "M"));
//     fetchCharts();
//   }

//   const configLeftTable = leftTableColumns(intl);
//   const configRightTable = rightTableColumns(intl);

//   return (
//     <Row>
//       <Col lg={24} md={24} sm={24}>
//         <Space direction="vertical" size={12}>
//           <ConfigProvider locale={locale}>
//             {fromDate ? <DatePicker defaultValue={fromDate} format={"MM-YYYY"} picker="month" onChange={onDateChange} style={{ marginLeft: "1rem" }} /> : null}
//           </ConfigProvider>
//         </Space>
//       </Col>

//       <Col lg={11} md={11} sm={24}>
//         {data ? (
//           <Pie
//             data={data}
//           // settingsConfig={{
//           //   title: { visible: true, text: this.intl("number-of-registered-documents") },
//           // }}
//           />
//         ) : null}
//         {data ? (
//           <Table
//             className={styles.Table}
//             pagination={false}
//             columns={configLeftTable}
//             dataSource={data.map((item) => ({
//               ...item,
//             }))}
//           />
//         ) : null}
//       </Col>

//       <Col lg={{ span: 12, offset: 1 }} md={{ span: 12, offset: 1 }} sm={24}>
//         {dataDocType ? (
//           <Pie
//             data={dataDocType}
//           // settingsConfig={{
//           //   title: { visible: true, text: this.intl("citizens-appeals-by-form-of-income") },
//           // }}
//           />
//         ) : null}
//         {dataDocType ? (
//           <Table
//             className={styles.Table}
//             pagination={false}
//             columns={configRightTable}
//             dataSource={dataDocType.map((item) => ({
//               ...item,
//             }))}
//           />
//         ) : null}
//       </Col>
//     </Row>
//   );
// }

class ChartReceipts extends Component {
  state = {
    dateFrom: moment(Date.now()).startOf("month"),
    dateTo: moment(Date.now()).startOf("month").add(1, "M"),
    data: [],
    dataDocType: [],
    loading: false,
  };

  componentDidMount() {
    this.fetchCharts();
  }

  intl = (selector) => {
    return translate(selector, this.props.lang);
  };

  fetchCharts = () => {
    this.setState({ loading: true }, async () => {
      const data = await request("/statistics/receipts", {
        method: "post",
        body: this.state,
        cancelTokenKey: "receipts",
      });
      console.log("data: ", data);
      const dataDocType = await request("/statistics/receiptsDocType", {
        method: "post",
        body: this.state,
        cancelTokenKey: "receiptsDocType",
      });
      console.log("dataDocType: ", dataDocType);
      this.setState({
        data: data?.content,
        dataDocType: dataDocType?.content,
        loading: false,
      });
    });
  };

  onChangeDate = (date) => {
    this.setState(
      {
        dateFrom: moment(date).startOf("month"),
        dateTo: moment(date).startOf("month").add(1, "M"),
      },
      () => this.fetchCharts()
    );
  };

  render() {
    const { dateFrom, data, dataDocType } = this.state;

    const configLeftTable = leftTableColumns(this.intl);
    const configRightTable = rightTableColumns(this.intl);

    return (
      <Row>
        <Col lg={24} md={24} sm={24}>
          <Space direction="vertical" size={12}>
            <ConfigProvider locale={locale}>
              {dateFrom ? (
                <DatePicker
                  defaultValue={dateFrom}
                  format={"MM-YYYY"}
                  picker="month"
                  onChange={this.onChangeDate}
                  style={{ marginLeft: "1rem" }}
                />
              ) : null}
            </ConfigProvider>
          </Space>
        </Col>

        <Col lg={11} md={11} sm={24}>
          {data.length > 0 ? <Pie data={data} /> : null}
          {data.length > 0 ? (
            <Table
              className={styles.Table}
              pagination={false}
              columns={configLeftTable}
              dataSource={data.map((item) => ({
                ...item,
              }))}
            />
          ) : null}
        </Col>

        <Col lg={{ span: 12, offset: 1 }} md={{ span: 12, offset: 1 }} sm={24}>
          {dataDocType.length > 0 ? <Pie data={dataDocType} /> : null}
          {dataDocType.length > 0 ? (
            <Table
              className={styles.Table}
              pagination={false}
              columns={configRightTable}
              dataSource={dataDocType.map((item) => ({
                ...item,
              }))}
            />
          ) : null}
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    lang: state.lang.value,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: (language) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
    raiseMessage: (message) =>
      dispatch({ type: Actions.RAISE_MESSAGE, message }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChartReceipts);
