import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import { Col, Row, Space, DatePicker, ConfigProvider } from "antd";
import "moment/locale/uk";
import locale from "antd/es/locale/uk_UA";
import { Column } from "@ant-design/charts";

import * as Actions from "../../../Store/actions";
import { translate } from "../../../Translation";
import { request } from "../../../Services";
import styles from "./styles.module.css";

class ChartProblems extends Component {
  state = {
    dateFrom: moment(Date.now()).startOf("month"),
    dateTo: moment(Date.now()),
    data: [],
    loading: false,
  };

  componentDidMount() {
    this.fetchCharts();
  }

  intl = (selector) => {
    return translate(selector, this.props.lang);
  };

  fetchCharts = () => {
    this.setState({ loading: true }, async () => {
      const data = await request("/statistics/problems", {
        method: "post",
        body: this.state,
        cancelTokenKey: "problems",
      });
      console.log("data: ", data);
      this.setState({ data: data?.content, loading: false });
    });
  };

  onChangeDateFrom = (date) => {
    this.setState(
      { dateFrom: moment(date, "DD-MM-YYYY").startOf("date") },
      () => {
        if (this.state.dateFrom && this.state.dateTo) {
          this.fetchCharts();
        }
      }
    );
  };

  onChangeDateTo = (date) => {
    this.setState({ dateTo: moment(date, "DD-MM-YYYY").endOf("date") }, () => {
      if (this.state.dateFrom && this.state.dateTo) {
        this.fetchCharts();
      }
    });
  };

  render() {
    const { dateFrom, dateTo, data } = this.state;
    const dataList = [...new Set(data.map((u) => u.name_question))].map(
      (name) => data.find((u) => u.name_question === name)
    );

    const config = {
      data: data,
      xField: "name_question",
      yField: "count_quest_reg",
      seriesField: "name_reg",
      isStack: true,
      xAxis: false,
      legend: false,
    };

    return (
      <Row>
        <Col lg={24} md={24} sm={24}>
          <Space size={12}>
            <ConfigProvider locale={locale}>
              {dateFrom ? (
                <DatePicker
                  defaultValue={dateFrom}
                  format={"DD-MM-YYYY"}
                  picker="date"
                  onChange={(_, dates) => this.onChangeDateFrom(dates)}
                  style={{ marginLeft: "1rem" }}
                />
              ) : null}
              {dateTo ? (
                <DatePicker
                  defaultValue={dateTo}
                  format={"DD-MM-YYYY"}
                  picker="date"
                  onChange={(_, dates) => this.onChangeDateTo(dates)}
                  style={{ marginLeft: "1rem" }}
                />
              ) : null}
            </ConfigProvider>
          </Space>
        </Col>

        <Col lg={6} md={6} sm={24}>
          <div className={styles.NavbarChart} style={{ marginTop: "5rem" }}>
            <ul>
              {dataList.length > 0
                ? dataList.map((item) => (
                    <li>
                      <Link
                        className={styles.Link}
                        to={{
                          pathname: "/statistics/problems/performers",
                          state: {
                            value: item.name_question,
                            key: item.gta_questions_id,
                            dateFrom: moment(dateFrom)._d,
                            dateTo: moment(dateTo)._d,
                          },
                        }}
                      >
                        {item.name_question}
                      </Link>
                    </li>
                  ))
                : null}
            </ul>
          </div>
        </Col>

        <Col lg={{ span: 16, offset: 2 }} md={{ span: 16, offset: 2 }} sm={24}>
          {data.length > 0 ? (
            <Column
              {...config}
              style={{ marginLeft: "2rem", marginTop: "5rem" }}
            />
          ) : null}
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    lang: state.lang.value,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: (language) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
    raiseMessage: (message) =>
      dispatch({ type: Actions.RAISE_MESSAGE, message }),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChartProblems)
);
