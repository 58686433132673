import { useContext, useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Space,
  ConfigProvider,
  Checkbox,
  Typography,
  DatePicker,
} from "antd";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/uk";
import locale from "antd/lib/locale/uk_UA";

import * as Actions from "../../../Store/actions";
import { translate } from "../../../Translation";
import Drawer from "../../../Components/UI/Drawer";
import { request } from "../../../Services";
import { ModalContext } from "../../../Contexts/modal.context";
import User from "../../../Services/user";
import SelectComponent from "../../../Components/UI/Select";
import styles from "./styles.module.scss";

const { Title } = Typography;

function FormComponent(props) {
  const form = useRef();
  const [typeDoc, setTypeDoc] = useState(undefined);
  const { rowData, rowId, fetchTableData, pathname, name } = props;
  const typeDocId = rowData?.ConstTypeDocID?.split(",").map(Number);
  const typeDocDefId = rowData?.ConstTypeDocDefID?.split(",").map(Number);
  const typeDocName = rowData?.ConstTypeDocName?.split(",");
  const typeDocNameDef = rowData?.ConstTypeDocNameDef?.split(",");
  const initialValuesTDN = typeDocId?.map(id => ({ key: id, label: typeDocName[typeDocId.indexOf(id)] }));
  const initialValuesTDND = typeDocDefId?.map(id => ({ key: id, label: typeDocNameDef[typeDocDefId.indexOf(id)] }));
  const modalContext = useContext(ModalContext);

  const intl = (selector) => {
    return translate(selector, props.lang);
  };

  const fetchTypeDoc = async () => {
    const { content } = await request("/directories/type-documents/getdata", {
      method: "get",
      cancelTokenKey: "typeDoc",
    });
    setTypeDoc(content || []);
  };

  const onFormFinish = async (values) => {
    console.log("Success:", values);
    const currentUser = User.decode();

    rowId === 0
      ? await request(`${pathname}/adddata`, {
          method: "post",
          body: { ...values, userId: currentUser?.id },
          cancelTokenKey: "adddata",
        })
      : await request(`${pathname}/updatedata`, {
          method: "post",
          body: { ...values, id: rowId },
          cancelTokenKey: "updatedata",
        });
    modalContext.close(name);
    fetchTableData();
  };

  const onFormFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onChangeSelect = (name, value) => {
    if (name === "typeDocName") {
      form.current.setFieldsValue({
        typeDocName:
          value.map((item) => item.key) == "all"
            ? typeDoc?.map((i) => i.id)
            : value.map((item) => item.key),
      });
    } else {
      form.current.setFieldsValue({
        typeDocNameDef:
          value.map((item) => item.key) == "all"
            ? typeDoc?.map((i) => i.id)
            : value.map((item) => item.key),
      });
    }
  };

  function validator(subject, value) {
    return new Promise((resolve, reject) => {
      if (!value) {
        return reject(new Error(subject.message));
      }

      return resolve();
    });
  }

  return (
    <ConfigProvider locale={locale}>
      <Form
        ref={form}
        layout="vertical"
        onFinish={onFormFinish}
        onFinishFailed={onFormFailed}
        initialValues={{
          code: rowData?.Code,
          isControl: !!rowData?.isKontrol ?? false,
          name: rowData?.Name,
          notes: rowData?.Notes,
          content: rowData?.content,
          isExec: !!rowData?.isExec ?? false,
          doFile: !!rowData?.DoFile ?? false,
          dateExecution: rowData?.KolvoDayExec,
          dateKFactAfterGet: !!rowData?.AddDateKFactAfterGet ?? false,
          typeDocName: typeDocId,
          typeDocNameDef: typeDocDefId,
          controllersFromInsertStep:
            !!rowData?.AddControllersFromInsertStep ?? false,
          toReestrSendAsDoc: !!rowData?.AddToReestrSendAsDoc ?? false,
          isEditFile: !!rowData?.IsEditFile ?? false,
        }}
      >
        <Row>
          <Col lg={24} md={24} sm={24}>
            <Row
              style={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <Col lg={14} md={14} sm={24}>
                <Form.Item label={intl("code")} name="code">
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={8} md={8} sm={24}>
                <Form.Item name="isControl" valuePropName="checked">
                  <Checkbox>{intl("control-action")}</Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label={intl("action-stage")}
              name="name"
              rules={[
                {
                  required: true,
                  message: intl("action-stage"),
                  validator: validator,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label={intl("notes")} name="notes">
              <Input />
            </Form.Item>
            <Form.Item label={intl("content-action-stage")} name="content">
              <Input />
            </Form.Item>
            <Row style={{ justifyContent: "space-between" }}>
              <Form.Item name="isExec" valuePropName="checked">
                <Checkbox>{intl("track-the-deadline")}</Checkbox>
              </Form.Item>
              <Form.Item name="doFile" valuePropName="checked">
                <Checkbox>{intl("mandatory-entry-file")}</Checkbox>
              </Form.Item>
            </Row>
            <Form.Item label={intl("set-date-execution")} name="dateExecution">
              <Input />
            </Form.Item>
            <Form.Item name="dateKFactAfterGet" valuePropName="checked">
              <Checkbox>{intl("fill-execution-date")}</Checkbox>
            </Form.Item>
            <Form.Item
              label={intl("use-for-document-types")}
              name="typeDocName"
            >
              <SelectComponent
                name="typeDocName"
                roleName={initialValuesTDN}
                onChangeSelect={onChangeSelect}
                rolesData={typeDoc}
                fetchData={fetchTypeDoc}
                mode="multiple"
                all={intl("select-all")}
                labelInValue
              />
            </Form.Item>
            <Form.Item
              label={intl("fill-by-default-for-document-types")}
              name="typeDocNameDef"
            >
              <SelectComponent
                name="typeDocNameDef"
                roleName={initialValuesTDND}
                onChangeSelect={onChangeSelect}
                rolesData={typeDoc}
                fetchData={fetchTypeDoc}
                mode="multiple"
                all={intl("select-all")}
                labelInValue
              />
            </Form.Item>
            <Form.Item name="controllersFromInsertStep" valuePropName="checked">
              <Checkbox>{intl("fill-controlling-person")}</Checkbox>
            </Form.Item>
            <Form.Item name="toReestrSendAsDoc" valuePropName="checked">
              <Checkbox>
                {intl("enter-mailing-list-after-document-registration")}
              </Checkbox>
            </Form.Item>
            <Form.Item name="isEditFile" valuePropName="checked">
              <Checkbox>{intl("allowed-edit-the-file")}</Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Space style={{ display: "flex", justifyContent: "end" }}>
            <Button onClick={() => modalContext.close(name)}>
              {intl("cancel")}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className={styles["button"]}
            >
              {intl("ok")}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </ConfigProvider>
  );
}

function mapStateToProps(state) {
  return {
    lang: state.lang.value,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: (language) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
    raiseMessage: (message) =>
      dispatch({ type: Actions.RAISE_MESSAGE, message }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormComponent);
