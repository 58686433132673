import { useState, useRef } from "react";
import { Input, Button, Space } from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";

export function drawerColumns(intl = () => String()) {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  let searchInput = useRef();

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`${intl("search")}...`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {intl("search")}
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            {intl("reset")}
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            {intl("filter")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  return [
    {
      dataIndex: "Code",
      key: "Code",
      width: "20%",
    },
    {
      dataIndex: "Name",
      key: "Name",
      ...getColumnSearchProps("Name"),
    },
  ];
}

export function caseLeftColumns(intl = () => String()) {
  return [
    {
      title: intl("structural-unit"),
      dataIndex: "name_otdel",
      key: "name_otdel",
    },
    {
      title: "№",
      dataIndex: "ReestrNum",
      key: "ReestrNum",
      width: "25%",
    },
    {
      title: intl("year"),
      dataIndex: "ReestrDate",
      key: "ReestrDate",
    },
  ];
}

export function caseRightColumns(intl = () => String()) {
  return [
    {
      title: intl("index"),
      dataIndex: "Code_OfficialDoc",
      key: "Code_OfficialDoc",
      width: "15%",
    },
    {
      title: intl("heading"),
      dataIndex: "Name_OfficialDoc",
      key: "Name_OfficialDoc",
    },
    {
      title: intl("notes"),
      dataIndex: "LimitationText",
      key: "LimitationText",
    },
  ];
}
