import { createContext, useState } from "react";

const ModalContext = createContext();

function ModalProvider(props) {
  const [modalsState, setModals] = useState({});
  const style = props.style;
  const width = props.width;
  const footer = props.footer;

  function open(id) {
    setModals((prev) => ({ ...prev, [id]: true }));
  }

  function close(id) {
    setModals((prev) => ({ ...prev, [id]: false }));
  }

  function isActive(id) {
    return modalsState[id];
  }

  return (
    <ModalContext.Provider
      value={{ style, width, footer, modalsState, open, close, isActive }}
    >
      {props.children}
    </ModalContext.Provider>
  );
}

export { ModalContext, ModalProvider };
