import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Col, Row, Table, Button, Spin, ConfigProvider } from "antd";
import LoadingOutlined from "@ant-design/icons/LoadingOutlined";
import locale from "antd/es/locale/uk_UA";

import CabinetLayout from "../../HOC/CabinetLayout";
import * as Actions from "../../Store/actions";
import { translate } from "../../Translation";
import { request } from "../../Services";
import { ModalProvider } from "../../Contexts/modal.context";
import Modal from "../../Components/UI/Modal";
import Form from "../../Containers/Directories/NomenclatureDocuments";
import Form2 from "../../Containers/Directories/CharacteristicsDocuments";
import PopconfirmComponent from "../../Components/UI/Popconfirm";
import styles from "./styles.module.scss";
import {
  nomenclatureDocumentsColumns,
  nomenclatureDocumentsColumns2,
} from "./utils";

function NomenclatureDocuments(props) {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableData2, setTableData2] = useState([]);
  const [rowId, setRowId] = useState(0);
  const [rowId2, setRowId2] = useState(0);
  const [rowData, setRowData] = useState(undefined);
  const [rowData2, setRowData2] = useState(undefined);
  const [disabled, setDisabled] = useState(true);
  const [disabled2, setDisabled2] = useState(true);

  useEffect(() => {
    fetchTableData();
  }, []);

  const intl = (selector) => {
    return translate(selector, props.lang);
  };

  const fetchTableData = async (id) => {
    setLoading(true);
    const { content } = id
      ? await request(`${props.location.pathname}2/getdata`, {
          method: "post",
          body: { id: id },
          cancelTokenKey: "getdata",
        })
      : await request(`${props.location.pathname}/getdata`, {
          method: "get",
          cancelTokenKey: "getdata",
        });
    id ? setTableData2(content || []) : setTableData(content || []);
    setLoading(false);
  };

  const onClickRow = (record, int) => {
    if (int === 1) {
      setRowId(record.id);
      setRowData(record);
      setDisabled(false);
      fetchTableData(record.id);
    } else {
      setRowId2(record.id);
      setRowData2(record);
      setDisabled2(false);
    }
  };

  const onDeleteRow = async (int) => {
    await request(`${props.location.pathname}${int}/deletedata`, {
      method: "post",
      body: { id: int ? rowId2 : rowId },
      cancelTokenKey: "deletedata",
    });
    fetchTableData(int ? rowId : undefined);
  };

  const setRowClassName = (record, bool) => {
    const id = bool ? rowId : rowId2;
    return record.id === id ? styles["table-select-row"] : "";
  };

  const columns = nomenclatureDocumentsColumns(intl);
  const columns2 = nomenclatureDocumentsColumns2(intl);

  return (
    <CabinetLayout activeLink={props.match.path} {...props}>
      <ConfigProvider locale={locale}>
        <Row>
          <Col lg={24} md={24} sm={24}>
            <ModalProvider width={800}>
              <Modal name={intl("add")}>
                <Form
                  name={intl("add")}
                  tableData={tableData}
                  rowId={0}
                  pathname={props.location.pathname}
                  fetchTableData={fetchTableData}
                />
              </Modal>
              <Modal name={intl("edit")} disabled={disabled}>
                <Form
                  name={intl("edit")}
                  tableData={tableData}
                  rowData={rowData}
                  rowId={rowId}
                  pathname={props.location.pathname}
                  fetchTableData={fetchTableData}
                />
              </Modal>
              <PopconfirmComponent
                intl={intl}
                onDeleteRow={onDeleteRow}
                disabled={disabled}
                directories={true}
              />
            </ModalProvider>
          </Col>
          <Col lg={24} md={24} sm={24}>
            <Table
              className={styles.table}
              dataSource={tableData}
              columns={columns}
              onRow={(record) => {
                return { onClick: () => onClickRow(record, 1) };
              }}
              rowClassName={(record) => setRowClassName(record, true)}
              loading={{
                spinning: loading,
                indicator: (
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 24 }} spin />
                    }
                  />
                ),
              }}
              pagination={{ position: ["bottomCenter"], showSizeChanger: true }}
              size="small"
              bordered
            />
          </Col>
        </Row>
        {tableData2.length > 0 ? (
          <Row>
            <Col lg={24} md={24} sm={24}>
              <ModalProvider width={800}>
                <Modal name={intl("add")}>
                  <Form2
                    name={intl("add")}
                    id={rowId}
                    tableData={tableData2}
                    rowId={0}
                    pathname={`${props.location.pathname}2`}
                    fetchTableData={fetchTableData}
                  />
                </Modal>
                <Modal name={intl("edit")} disabled={disabled2}>
                  <Form2
                    name={intl("edit")}
                    id={rowId}
                    tableData={tableData2}
                    rowData={rowData2}
                    rowId={rowId2}
                    pathname={`${props.location.pathname}2`}
                    fetchTableData={fetchTableData}
                  />
                </Modal>
                <Button
                  className={styles["delete-button"]}
                  type="primary"
                  onClick={() => onDeleteRow("2")}
                  disabled={disabled2}
                >
                  {intl("remove")}
                </Button>
              </ModalProvider>
            </Col>
            <Col lg={24} md={24} sm={24}>
              <Table
                className={styles.table}
                dataSource={tableData2}
                columns={columns2}
                onRow={(record) => {
                  return { onClick: () => onClickRow(record, 2) };
                }}
                rowClassName={(record) => setRowClassName(record, undefined)}
                loading={{
                  spinning: loading,
                  indicator: (
                    <Spin
                      indicator={
                        <LoadingOutlined style={{ fontSize: 24 }} spin />
                      }
                    />
                  ),
                }}
                pagination={{ position: ["bottomCenter"] }}
                size="small"
                bordered
              />
            </Col>
          </Row>
        ) : null}
      </ConfigProvider>
    </CabinetLayout>
  );
}

function mapStateToProps(state) {
  return {
    lang: state.lang.value,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: (language) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
    raiseMessage: (message) =>
      dispatch({ type: Actions.RAISE_MESSAGE, message }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NomenclatureDocuments);
