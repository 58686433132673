import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Col, Row, Table, Button, Spin, ConfigProvider } from "antd";
import LoadingOutlined from "@ant-design/icons/LoadingOutlined";
import locale from "antd/es/locale/uk_UA";

import CabinetLayout from "../../HOC/CabinetLayout";
import * as Actions from "../../Store/actions";
import { translate } from "../../Translation";
import { request } from "../../Services";
import { ModalProvider } from "../../Contexts/modal.context";
import Modal from "../../Components/UI/Modal";
import Form from "../../Containers/Directories/CharacteristicsDocuments";
import PopconfirmComponent from "../../Components/UI/Popconfirm";
import styles from "./styles.module.scss";

function CharacteristicsDocuments(props) {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [rowId, setRowId] = useState(undefined);
  const [rowData, setRowData] = useState(undefined);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    fetchTableData();
  }, []);

  const intl = (selector) => {
    return translate(selector, props.lang);
  };

  const fetchTableData = async () => {
    setLoading(true);
    const { content } = await request(`${props.location.pathname}/getdata`, {
      method: "get",
      cancelTokenKey: "getdata",
    });
    setTableData(content || []);
    setLoading(false);
  };

  const onClickRow = (record) => {
    setRowId(record.id);
    setRowData(record);
    setDisabled(false);
  };

  const onDeleteRow = async () => {
    await request(`${props.location.pathname}/deletedata`, {
      method: "post",
      body: { id: rowId },
      cancelTokenKey: "deletedata",
    });
    fetchTableData();
  };

  const setRowClassName = (record) => {
    return record.id === rowId ? styles["table-select-row"] : "";
  };

  const injectColumns = (keys, columns) => {
    for (const key of keys) {
      // const item = tableData.find((predicate) => predicate[key]);
      const item = tableData.find((predicate) =>
        Object.keys(predicate).includes(key)
      );

      if (item) {
        columns.push({
          title: intl(`${key.toLowerCase()}`),
          dataIndex: key,
          key: key,
          align: key === "Code" && "center",
        });
      }
    }
  };

  let columns = [];
  injectColumns(
    ["Code", "Name", "NameFull", "NameShort", "NameGroup", "Department", "Notes", "KP", "OKP", "ETKS"],
    columns
  );

  // const columns = [
  //     {
  //         title: this.intl("code"),
  //         dataIndex: "Code",
  //         key: "Code",
  //         align: "center"
  //     },
  //     {
  //         title: this.intl("-name"),
  //         dataIndex: "Name",
  //         key: "Name",
  //         align: "center"
  //     },
  //     {
  //         title: this.intl("notes"),
  //         dataIndex: "Prim",
  //         key: "Prim",
  //         align: "center"
  //     },
  // ];

  return (
    <CabinetLayout activeLink={props.match.path} {...props}>
      <Row>
        <Col lg={24} md={24} sm={24}>
          <ModalProvider width={600}>
            <Modal name={intl("add")}>
              <Form
                name={intl("add")}
                tableData={tableData}
                rowId={0}
                pathname={props.location.pathname}
                fetchTableData={fetchTableData}
              />
            </Modal>
            <Modal name={intl("edit")} disabled={disabled}>
              <Form
                name={intl("edit")}
                tableData={tableData}
                rowData={rowData}
                rowId={rowId}
                pathname={props.location.pathname}
                fetchTableData={fetchTableData}
              />
            </Modal>
            <PopconfirmComponent
              intl={intl}
              onDeleteRow={onDeleteRow}
              disabled={disabled}
              directories={true}
            />
          </ModalProvider>
        </Col>
        <Col lg={24} md={24} sm={24}>
          <ConfigProvider locale={locale}>
            <Table
              className={styles.table}
              dataSource={tableData}
              columns={columns}
              onRow={(record) => {
                return { onClick: () => onClickRow(record) };
              }}
              rowClassName={setRowClassName}
              loading={{
                spinning: loading,
                indicator: (
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 24 }} spin />
                    }
                  />
                ),
              }}
              pagination={{ position: ["bottomCenter"], showSizeChanger: true }}
              size="small"
              bordered
            />
          </ConfigProvider>
        </Col>
      </Row>
    </CabinetLayout>
  );
}

function mapStateToProps(state) {
  return {
    lang: state.lang.value,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: (language) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
    raiseMessage: (message) =>
      dispatch({ type: Actions.RAISE_MESSAGE, message }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CharacteristicsDocuments);
