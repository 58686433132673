import { useContext } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Space,
  ConfigProvider,
  Checkbox,
} from "antd";
import { connect } from "react-redux";
import "moment/locale/uk";
import locale from "antd/lib/locale/uk_UA";

import * as Actions from "../../../Store/actions";
import { translate } from "../../../Translation";
import Drawer from "../../../Components/UI/Drawer";
import { request } from "../../../Services";
import { ModalContext } from "../../../Contexts/modal.context";
import User from "../../../Services/user";
import styles from "./styles.module.scss";

function FormComponent(props) {
  const [form] = Form.useForm();
  const { rowData, rowId, fetchTableData, pathname, name } = props;
  const modalContext = useContext(ModalContext);

  const intl = (selector) => {
    return translate(selector, props.lang);
  };

  const onFormFinish = async (values) => {
    console.log("Success:", values);
    const currentUser = User.decode();

    rowId === 0
      ? await request(`${pathname}/adddata`, {
          method: "post",
          body: { ...values, userId: currentUser?.id },
          cancelTokenKey: "adddata",
        })
      : await request(`${pathname}/updatedata`, {
          method: "post",
          body: { ...values, id: rowId },
          cancelTokenKey: "updatedata",
        });
    modalContext.close(name);
    fetchTableData();
  };

  const onFormFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  function validator(subject, value) {
    return new Promise((resolve, reject) => {
      if (!value) {
        return reject(new Error(subject.message));
      }

      return resolve();
    });
  }

  return (
    <ConfigProvider locale={locale}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFormFinish}
        onFinishFailed={onFormFailed}
        initialValues={{
          code: rowData?.Kod,
          department: rowData?.NAME_OTDEL,
          shortName: rowData?.name_otdel_short,
          popupMenu: false,
        }}
      >
        <Row>
          <Col lg={24} md={24} sm={24}>
            <Form.Item label={intl("code")} name="code">
              <Input />
            </Form.Item>
            <Form.Item label={intl("type2")} name="subdivision">
              <Drawer
                url="/directories/structural-unit/getdata"
                options={{ method: "get", cancelTokenKey: "subdivision" }}
                documentName={rowData?.name_subdivision}
                documentId={rowData?.sta_type_subdivision_id}
              />
            </Form.Item>
            <Form.Item
              label={intl("full-name")}
              name="department"
              rules={[
                {
                  required: true,
                  message: intl("full-name-required"),
                  validator: validator,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label={intl("short-name")} name="shortName">
              <Input />
            </Form.Item>
            <Form.Item label={intl("refers-to")} name="refersTo">
              <Drawer
                url="/directories/name-departments/getdata"
                options={{ method: "get", cancelTokenKey: "refersTo" }}
                documentName={rowData?.NameParent}
                documentId={rowData?.parent_id}
              />
            </Form.Item>
            <Form.Item label={intl("reorganized-into")} name="reorganized">
              <Drawer
                url="/directories/name-departments/getdata"
                options={{ method: "get", cancelTokenKey: "reorganized" }}
                documentName={rowData?.NameReorg}
                documentId={rowData?.reorganize_otdel_id}
              />
            </Form.Item>
            <Form.Item name="popupMenu" valuePropName="checked">
              <Checkbox>{intl("liquidated")}</Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Space style={{ display: "flex", justifyContent: "end" }}>
            <Button onClick={() => modalContext.close(name)}>
              {intl("cancel")}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className={styles["button"]}
            >
              {intl("ok")}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </ConfigProvider>
  );
}

function mapStateToProps(state) {
  return {
    lang: state.lang.value,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: (language) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
    raiseMessage: (message) =>
      dispatch({ type: Actions.RAISE_MESSAGE, message }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormComponent);
