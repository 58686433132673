/* eslint-disable */
import React from "react";
import Descriptions from "antd/lib/descriptions";

export const Storage = {
  "terms-link": {
    en: "Information about the rights and name of the System",
    uk: "Інформація про права та назва Системи",
  },
  "language": {
    en: "English",
    uk: "Українська",
  },
  "login": {
    en: "Login",
    uk: "Логін",
  },
  "login-required": {
    en: "Enter login",
    uk: "Введіть логін",
  },
  "email-pattern": {
    en: "The mail is incorrect",
    uk: "Пошта некоректна",
  },
  "password": {
    en: "Password",
    uk: "Пароль",
  },
  "password-required": {
    en: "Fill the password",
    uk: "Введіть пароль",
  },
  "sign-in": {
    en: "Login",
    uk: "Вхід",
  },
  "logout": {
    en: "Logout",
    uk: "Вийти",
  },
  "forgot-password": {
    en: "Forgot your password?",
    uk: "Забули свій пароль?",
  },
  "remember-me": {
    en: "Remember me",
    uk: "Запам'ятати мене",
  },
  "profile": {
    en: "Profile",
    uk: "Профіль",
  },
  "incoming": {
    en: "Incoming",
    uk: "Вхідні",
  },
  "outcoming": {
    en: "Outcoming",
    uk: "Вихідні",
  },
  "administrative": {
    en: "Administrative",
    uk: "Розпорядчі",
  },
  "citizens-appeal": {
    en: "Appeal of citizens",
    uk: "Звернення громадян",
  },
  "resolutions": {
    en: "Resolutions",
    uk: "Резолюції",
  },
  "others": {
    en: "Others",
    uk: "Інші",
  },
  "receipts": {
    en: "Receipts",
    uk: "Надходження",
  },
  "problems": {
    en: "Problematic questions",
    uk: "Проблемні питання",
  },
  "by-performers": {
    en: "By performers",
    uk: "За виконавцями",
  },
  "other-questions": {
    en: "Other questions",
    uk: "Інші питання",
  },
  "by-address": {
    en: "By address",
    uk: "За адресами",
  },
  "by-district": {
    en: "By district",
    uk: "За районами",
  },
  "activity-of-residents": {
    en: "Activity of residents",
    uk: "Активність мешканців",
  },
  "rating-of-managers": {
    en: "Rating of managers",
    uk: "Рейтинг управителів",
  },
  "rating-service-providers": {
    en: "Rating of service providers",
    uk: "Рейтинг підприємств надавачів послуг",
  },
  "structural-units": {
    en: "Top structural divisions",
    uk: "Топ структурних підрозділів",
  },
  "types-of-documents": {
    en: "Types of documents",
    uk: "Види документів",
  },
  "types-of-documents-required": {
    en: "Select types of documents",
    uk: "Виберіть види документів",
  },
  "categories-of-documents": {
    en: "Categories of documents",
    uk: "Категорії документів",
  },
  "types-of-sending-documents": {
    en: "Types of sending documents",
    uk: "Види відправлень документів",
  },
  "relationships-documents": {
    en: "Types of relationships between documents",
    uk: "Типи відносин між документами",
  },
  "category-of-positions": {
    en: "Category of positions",
    uk: "Категорія посад",
  },
  "job-title": {
    en: "Classifier of professions",
    uk: "Класифікатор професій",
  },
  "directory-of-positions": {
    en: "Positions",
    uk: "Посади",
  },
  "structural-unit": {
    en: "Types of structural subdivisions",
    uk: "Типи структурних підрозділів",
  },
  "department": {
    en: "Department",
    uk: "Відділ",
  },
  "departments": {
    en: "Departments",
    uk: "Відділи",
  },
  "employee-directory": {
    en: "Employees",
    uk: "Співробітники",
  },
  "directory-of-correspondents": {
    en: "Correspondents",
    uk: "Кореспонденти",
  },
  "types-toponyms": {
    en: "Types of administrative and territorial units",
    uk: "Типи адміністративно-територіальних одиниць",
  },
  "directory-countries": {
    en: "Countries",
    uk: "Країни",
  },
  "directory-regions": {
    en: "Regions",
    uk: "Області",
  },
  "directory-cities": {
    en: "Cities",
    uk: "Міста",
  },
  "directory-villages": {
    en: "Villages",
    uk: "Селища",
  },
  "directory-districts": {
    en: "Districts",
    uk: "Райони",
  },
  "directory-streets": {
    en: "Streets",
    uk: "Вулиці",
  },
  "directory-of-nomenclature": {
    en: "Nomenclature of cases",
    uk: "Номенклатура справ",
  },
  "users": {
    en: "Users",
    uk: "Користувачі",
  },
  "configuring-page-access": {
    en: "Configuring page access",
    uk: "Налаштування доступу сторінок",
  },
  "servers-settings": {
    en: "Servers settings",
    uk: "Налаштування серверів",
  },
  "tasks": {
    en: "Tasks",
    uk: "Завдання",
  },
  "buttons-access-settings": {
    en: "Buttons access settings",
    uk: "Налаштування доступу кнопок",
  },
  "documents": {
    en: "Documents",
    uk: "Документи",
  },
  "statistics": {
    en: "Statistics",
    uk: "Статистика",
  },
  "directories": {
    en: "Directories",
    uk: "Довідники",
  },
  "administration": {
    en: "Administration",
    uk: "Адміністрування",
  },
  "characteristics-of-documents": {
    en: "Characteristics of documents",
    uk: "Характеристика документів",
  },
  "hr-department": {
    en: "HR Department",
    uk: "Відділ кадрів",
  },
  "general": {
    en: "General",
    uk: "Загальні",
  },
  "koatuu": {
    en: "KOATUU",
    uk: "КОАТУУ",
  },
  "privacy-policy": {
    en: "Privacy policy",
    uk: "Політика конфіденційності",
  },
  "terms-of-use": {
    en: "Terms of use",
    uk: "Правила користування",
  },
  "support": {
    en: "Support",
    uk: "Технічна підтримка",
  },
  "cabinet": {
    en: "Cabinet",
    uk: "Кабінет",
  },
  "information": {
    en: "Information",
    uk: "Довідка",
  },
  "password-recovery": {
    en: "Password recovery",
    uk: "Відновлення пароля",
  },
  "sent-new-password": {
    en: "An email with a new password will be sent to your email address",
    uk: "На вашу електронну адресу буде відправлено лист з новим паролем",
  },
  "confirm-password-change": {
    en: "Confirm password change",
    uk: "Підтвердити зміну пароля",
  },
  "change-password": {
    en: "Change password",
    uk: "Змінити пароль",
  },
  "return": {
    en: "Return",
    uk: "Повернутися",
  },
  "personal-info": {
    en: "Personal information",
    uk: "Персональна інформація",
  },
  "last-name": {
    en: "Last name",
    uk: "Прізвище",
  },
  "last-name-required": {
    en: "Enter Your last name",
    uk: "Введіть Ваше прізвище",
  },
  "first-name": {
    en: "First name",
    uk: "Ім'я",
  },
  "first-name-required": {
    en: "Enter Your first name",
    uk: "Введіть Ваше ім'я",
  },
  "middle-name": {
    en: "Middle name",
    uk: "По батькові",
  },
  "middle-name-required": {
    en: "Enter Your middle name",
    uk: "Введіть Ваше по батькові",
  },
  "update-info": {
    en: "Update info",
    uk: "Оновити інформацію",
  },
  "role": {
    en: "Role",
    uk: "Роль",
  },
  "role-required": {
    en: "Select role",
    uk: "Виберіть роль",
  },
  "sed-user": {
    en: "SED user",
    uk: "Користувач СЕД",
  },
  "update-password": {
    en: "Update password",
    uk: "Оновити пароль",
  },
  "current-password": {
    en: "Current password",
    uk: "Поточний пароль",
  },
  "current-password-required": {
    en: "Rnter your current password",
    uk: "Введіть поточний пароль",
  },
  "confirm-password": {
    en: "Confirm password",
    uk: "Підтвердити пароль",
  },
  "confirm-password-required": {
    en: "Enter the confirm password",
    uk: "Введіть підтвердження пароля",
  },
  "confirm-password-reference": {
    en: "Password mismatch",
    uk: "Паролі не співпадають",
  },
  "update-data": {
    en: "Update data",
    uk: "Оновити дані",
  },
  "login-email": {
    en: "Login (email)",
    uk: "Логін (email)",
  },
  "access": {
    en: "Access",
    uk: "Доступ",
  },
  "add": {
    en: "Provide",
    uk: "Додати",
  },
  "edit": {
    en: "Edit",
    uk: "Редагувати",
  },
  "view": {
    en: "View",
    uk: "Переглянути",
  },
  "pages": {
    en: "Pages",
    uk: "Сторінки",
  },
  "pages-required": {
    en: "Select pages",
    uk: "Виберіть сторінки",
  },
  "select-all": {
    en: "Select all",
    uk: "Вибрати все",
  },
  "buttons": {
    en: "Buttons",
    uk: "Кнопки",
  },
  "buttons-required": {
    en: "Select buttons",
    uk: "Виберіть кнопки",
  },
  "allow": {
    en: "Allow",
    uk: "Дозволяти",
  },
  "cancel": {
    en: "Cancel",
    uk: "Скасувати",
  },
  "ok": {
    en: "Ok",
    uk: "Підтвердити",
  },
  "server-name": {
    en: "Server name",
    uk: "Ім'я сервера",
  },
  "server-code": {
    en: "Server code",
    uk: "Код сервера",
  },
  "imap-host": {
    en: "Imap host",
    uk: "Imap host",
  },
  "imap-port": {
    en: "Imap port",
    uk: "Imap port",
  },
  "remove-impossible": {
    en: "It is impossible to delete, there are links to this document",
    uk: "Видалити неможливо, існують посилання на даний документ",
  },
  "remove": {
    en: "Remove",
    uk: "Видалити",
  },
  "type-of-document": {
    en: "Type of document",
    uk: "Вид документа",
  },
  "type-of-document-required": {
    en: "Select document type!",
    uk: "Виберіть вид документа!",
  },
  "by-order": {
    en: "№ b/o",
    uk: "№ з/п",
  },
  "code": {
    en: "Code",
    uk: "Код",
  },
  "type": {
    en: "Type",
    uk: "Вид",
  },
  "type2": {
    en: "Type",
    uk: "Тип",
  },
  "belongs-to-category": {
    en: "It belongs to the category",
    uk: "Відноситься до категорії",
  },
  "display-toolbar-menu": {
    en: "Display for the main toolbar menu",
    uk: "Відображати для меню головної панелі інструментів",
  },
  "parameters-default": {
    en: "Parameters that will be set by default",
    uk: "Параметри що будуть встановлені за замовченням",
  },
  "type-of-connection": {
    en: "Type of connection",
    uk: "Тип зв'язку",
  },
  "correspondent": {
    en: "Correspondent",
    uk: "Кореспондент",
  },
  "create-draft-document": {
    en: "Create a draft document",
    uk: "Створювати проект документу",
  },
  "valid": {
    en: "Valid",
    uk: "Чинна",
  },
  "year": {
    en: "Year",
    uk: "Рік",
  },
  "year-required": {
    en: "Enter year",
    uk: "Введіть рік",
  },
  "date-of-approval": {
    en: "Date of approval",
    uk: "Дата затвердження",
  },
  "document": {
    en: "Document",
    uk: "Документ",
  },
  "add-num-nomenclature": {
    en: "Add to the case № nomenclature",
    uk: "Додавати до справи № номенклатури",
  },
  "add-letter-c": {
    en: "Add the letter 'C' to the case",
    uk: "Додавати до справи літеру 'С'",
  },
  "general-notes": {
    en: "General notes",
    uk: "Загальні примітки",
  },
  "index": {
    en: "Index",
    uk: "Індекс",
  },
  "index-required": {
    en: "Enter index",
    uk: "Введіть індекс",
  },
  "heading": {
    en: "Heading",
    uk: "Заголовок",
  },
  "heading-required": {
    en: "Enter heading",
    uk: "Введіть заголовок",
  },
  "shelf-life-years": {
    en: "Shelf life, years",
    uk: "Термін зберігання, роки",
  },
  "shelf-life": {
    en: "Shelf life",
    uk: "Термін зберігання",
  },
  "fio": {
    en: "Name",
    uk: "П.І.Б.",
  },
  "for-envelopes": {
    en: "For envelopes",
    uk: "Для конвертів",
  },
  "position": {
    en: "Position",
    uk: "Посада",
  },
  "other": {
    en: "Other",
    uk: "Інше",
  },
  "description": {
    en: "Description",
    uk: "Опис",
  },
  "full-name": {
    en: "Full name",
    uk: "Повна назва",
  },
  "full-name-required": {
    en: "Enter department name",
    uk: "Введіть назву відділу",
  },
  "short-name": {
    en: "Short name",
    uk: "Коротка назва",
  },
  "short-name-country": {
    en: "Short name of the country in English",
    uk: "Коротка назва країни англійською мовою",
  },
  "reorganization": {
    en: "Reorganization",
    uk: "Реорганізація",
  },
  "reorganized-into": {
    en: "Reorganized into ...",
    uk: "Реорганізовано у...",
  },
  "liquidated": {
    en: "Liquidated",
    uk: "Ліквідовано",
  },
  "basic-information": {
    en: "Basic information",
    uk: "Основна інформація",
  },
  "official-name": {
    en: "Official name",
    uk: "Офіційна назва",
  },
  "digital": {
    en: "digital",
    uk: "цифровий",
  },
  "alpha": {
    en: "alpha",
    uk: "альфа",
  },
  "code-okpo": {
    en: "Code EDRPOU",
    uk: "Код ЄДРПОУ",
  },
  "abbreviated": {
    en: "Abbreviated",
    uk: "Скорочено",
  },
  "address": {
    en: "Address",
    uk: "Адреса",
  },
  "email": {
    en: "E-mail",
    uk: "E-mail",
  },
  "email-required": {
    en: "Enter E-mail!",
    uk: "Введіть E-mail!",
  },
  "mail": {
    en: "Mail",
    uk: "Пошту",
  },
  "mail-required": {
    en: "Enter mail",
    uk: "Введіть пошту",
  },
  "performer": {
    en: "Performer",
    uk: "Виконавець",
  },
  "top-level-administrative-unit": {
    en: "Top level administrative unit",
    uk: "Адміністративна одиниця верхнього рівня",
  },
  "country": {
    en: "Сountry",
    uk: "Країна",
  },
  "country-required": {
    en: "Enter country",
    uk: "Введіть країну",
  },
  "region-special-status": {
    en: "Region, city with special status",
    uk: "Область, місто зі спеціальнім статусом",
  },
  "region": {
    en: "Region",
    uk: "Регіон",
  },
  "city-of-regional-subordination": {
    en: "City of regional subordination, district of the region",
    uk: "Місто обласного підпорядкування, район області",
  },
  "village": {
    en: "Village",
    uk: "Село",
  },
  "village-council": {
    en: "Village council",
    uk: "Селищна рада",
  },
  "administrative-unit": {
    en: "Administrative unit",
    uk: "Адміністративна одиниця",
  },
  "name": {
    en: "Name",
    uk: "Назва",
  },
  "name-required": {
    en: "Enter name",
    uk: "Введіть назву",
  },
  "name-species-required": {
    en: "Enter тame of the species",
    uk: "Введіть назву виду",
  },
  "previous-street-name": {
    en: "Previous street name",
    uk: "Попередня назва вулиці",
  },
  "district-center": {
    en: "District center",
    uk: "Районний центр",
  },
  "city-with-special-status": {
    en: "City with special status",
    uk: "Місто зі спеціальним статусом",
  },
  "service-information": {
    en: "Service information",
    uk: "Службова інформація",
  },
  "registration-department": {
    en: "Registration department",
    uk: "Відділ реєстрації",
  },
  "registration-department-required": {
    en: "Select registration department!",
    uk: "Виберіть відділ реєстрації!",
  },
  "registration-index": {
    en: "Registration index",
    uk: "Індекс реєстрації",
  },
  "registration-index-required": {
    en: "Select registration index!",
    uk: "Виберіть індекс реєстрації!",
  },
  "registration-date": {
    en: "Registration date",
    uk: "Дата реєстрації",
  },
  "control": {
    en: "Control",
    uk: "Контроль",
  },
  "for-execution": {
    en: "For execution",
    uk: "На виконання",
  },
  "place-of-registration": {
    en: "Place of registration",
    uk: "Місце реєстрації",
  },
  "place-of-registration-required": {
    en: "Please select place of registration!",
    uk: "Будь ласка, виберіть місце реєстрації!",
  },
  "author-developer": {
    en: "Author (developer)",
    uk: "Автор (розробник)",
  },
  "signed": {
    en: "Signed",
    uk: "За підписом",
  },
  "organization": {
    en: "Organization",
    uk: "Організація",
  },
  "classifier": {
    en: "Classifier",
    uk: "Класифікатор",
  },
  "co-authors": {
    en: "Co-authors",
    uk: "Співавтори",
  },
  "name-topic-document": {
    en: "Name (topic) of the document",
    uk: "Назва (тема) документу",
  },
  "text-document": {
    en: "The text of the document",
    uk: "Текст документа",
  },
  "case": {
    en: "Case",
    uk: "Справа",
  },
  "date-of-write-off": {
    en: "Date of write-off to the case",
    uk: "Дата списання у справу",
  },
  "number-of-applications": {
    en: "Number of applications",
    uk: "Кількість додатків",
  },
  "number-of-pages": {
    en: "Number of pages",
    uk: "Кількість сторінок",
  },
  "period-action-with": {
    en: "Period of action with",
    uk: "Період дії з",
  },
  "on": {
    en: "on",
    uk: "по",
  },
  "passing-document": {
    en: "Passing the document",
    uk: "Проходження документу",
  },
  "task": {
    en: "Task",
    uk: "Завдання",
  },
  "files": {
    en: "Files",
    uk: "Файли",
  },
  "select-file": {
    en: "Select File",
    uk: "Виберіть файл",
  },
  "included-files": {
    en: "Included files",
    uk: "Прикріплені файли",
  },
  "no-data": {
    en: "No data",
    uk: "Немає даних",
  },
  "control-terms": {
    en: "Control terms",
    uk: "Контрольні терміни",
  },
  "date-receipt": {
    en: "Date of receipt",
    uk: "Дата надходження",
  },
  "date-signing": {
    en: "Date of signing (sending) the appeal, date of the call",
    uk: "Дата підписання (надсилання) звернення, дата дзвінка",
  },
  "registry-index": {
    en: "Registry index",
    uk: "Реєстр. індекс",
  },
  "applicant": {
    en: "The applicant",
    uk: "Заявник",
  },
  "applicant-required": {
    en: "Please select applicant!",
    uk: "Будь ласка, виберіть заявника!",
  },
  "misuse": {
    en: "Misuse (not applicable)",
    uk: "Звернення не за призначенням (не належить)",
  },
  "appeal": {
    en: "Appeal",
    uk: "Звернення",
  },
  "form-receipt": {
    en: "Form of receipt",
    uk: "Форма надходження",
  },
  "kind-appeal": {
    en: "Kind of appeal",
    uk: "Вид звернення",
  },
  "type-appeal": {
    en: "Type of appeal",
    uk: "Тип звернення",
  },
  "sign-receipt": {
    en: "Sign of receipt",
    uk: "Ознака надходження",
  },
  "subject": {
    en: "By subject",
    uk: "За суб'єктом",
  },
  "responsible-institution": {
    en: "Responsible institution",
    uk: "Відповідальна установа",
  },
  "category": {
    en: "Category",
    uk: "Категорія",
  },
  "data-applicant": {
    en: "Data on the applicant",
    uk: "Дані про заявника",
  },
  "social-status": {
    en: "Social status",
    uk: "Соціальний стан",
  },
  "work-place": {
    en: "Work place",
    uk: "Місце роботи",
  },
  "telephone": {
    en: "Telephone",
    uk: "Телефон",
  },
  "male": {
    en: "Male",
    uk: "Чол",
  },
  "female": {
    en: "Female",
    uk: "Жін",
  },
  "settlement": {
    en: "settlement",
    uk: "нас. пункт",
  },
  "area": {
    en: "area",
    uk: "район",
  },
  "street": {
    en: "street",
    uk: "вулиця",
  },
  "house": {
    en: "house",
    uk: "будинок",
  },
  "apartment": {
    en: "apartment",
    uk: "квартира",
  },
  "additionally2": {
    en: "additionally",
    uk: "додатково",
  },
  "summary": {
    en: "Summary",
    uk: "Короткий зміст",
  },
  "summary-receipt": {
    en: "Select summary!",
    uk: "Виберіть зміст!",
  },
  "come-from": {
    en: "Where did it come from",
    uk: "Звідки одержано",
  },
  "date": {
    en: "Date",
    uk: "Дата",
  },
  "date-control": {
    en: "Date of control / implementation",
    uk: "Дата контролю / виконання",
  },
  "course-execution": {
    en: "The course of execution",
    uk: "Хід виконання",
  },
  "result-review": {
    en: "The result of the review",
    uk: "Результат розгляду",
  },
  "done": {
    en: "Done",
    uk: "Виконано",
  },
  "explained": {
    en: "Explained",
    uk: "Роз'яснено",
  },
  "removal-from-control": {
    en: "Removal from control",
    uk: "Зняття з контролю",
  },
  "took-off": {
    en: "Who took off",
    uk: "Хто зняв",
  },
  "date-withdrawal": {
    en: "Date of withdrawal",
    uk: "Дата зняття",
  },
  "response-document": {
    en: "Response document",
    uk: "Документ-відповідь",
  },
  "register": {
    en: "Register №t",
    uk: "Реєстр №",
  },
  "from": {
    en: "from",
    uk: "від",
  },
  "from-receipt": {
    en: "Select date!",
    uk: "Виберіть дату!",
  },
  "preliminary-appeals": {
    en: "Preliminary appeals",
    uk: "Попередні звернення",
  },
  "passing-document": {
    en: "Document passing",
    uk: "Проходження документа",
  },
  "linked-documents": {
    en: "Related documents",
    uk: "Зв'язані документи",
  },
  "electronic-signature": {
    en: "Electronic signature",
    uk: "Електронний підпис",
  },
  "certificate": {
    en: "Certificate",
    uk: "Сертифікат",
  },
  "add-text-255": {
    en: "Add text up to 255 characters",
    uk: "Додати текст до 255 символів",
  },
  "check-mail": {
    en: "Check mail",
    uk: "Перевірити пошту",
  },
  "correspondence-index": {
    en: "Correspondence index",
    uk: "Індекс кореспонденції",
  },
  "type-of-receipt": {
    en: "Type of receipt",
    uk: "Вид отримання",
  },
  "recipients": {
    en: "Recipients",
    uk: "Одержувачі",
  },
  "additionally": {
    en: "Additionally",
    uk: "Додатково",
  },
  "main-executor": {
    en: "Main executor",
    uk: "Головний виконавець",
  },
  "state": {
    en: "State",
    uk: "Стан",
  },
  "sure-delete": {
    en: "Sure to delete?",
    uk: "Дійсно видалити?",
  },
  "author": {
    en: "Author",
    uk: "Автор",
  },
  "author-required": {
    en: "Select author!",
    uk: "Виберіть автора!",
  },
  "action": {
    en: "Action",
    uk: "Дія",
  },
  "date-resolution": {
    en: "Date of resolution",
    uk: "Дата надання резолюції",
  },
  "date-resolution-required": {
    en: "Select date of resolutionmary!",
    uk: "Виберіть дату надання резолюції!",
  },
  "text-resolution": {
    en: "Full text of the resolution",
    uk: "Повний текст резолюції",
  },
  "search": {
    en: "Search",
    uk: "Пошук",
  },
  "reset": {
    en: "Reset",
    uk: "Скинути",
  },
  "filter": {
    en: "Filter",
    uk: "Фільтрувати",
  },
  "id": {
    en: "ID",
    uk: "ID",
  },
  "document-number": {
    en: "Document number",
    uk: "Номер документа",
  },
  "document-date": {
    en: "Document date",
    uk: "Дата документа",
  },
  "document-type": {
    en: "Document type",
    uk: "Тип документа",
  },
  "content": {
    en: "Content",
    uk: "Зміст",
  },
  "co-executors": {
    en: "Сo-executors",
    uk: "Співвиконавці",
  },
  "date-of-control": {
    en: "Date of control",
    uk: "Дата контролю",
  },
  "recipient": {
    en: "Recipient",
    uk: "Одержувач",
  },
  "resolution": {
    en: "Resolution",
    uk: "Резолюція",
  },
  "current-stage": {
    en: "Current stage",
    uk: "Поточний етап",
  },
  "project": {
    en: "Project",
    uk: "Проект",
  },
  "perform": {
    en: "Perform",
    uk: "Виконати",
  },
  "date-of-assignment": {
    en: "The date of assignment",
    uk: "Дата надання завдання",
  },
  "task-content": {
    en: "Task content",
    uk: "Зміст завдання",
  },
  "task-given": {
    en: "Task given",
    uk: "Завдання надав",
  },
  "type2": {
    en: "Type",
    uk: "Тип",
  },
  "num-document-project": {
    en: "№ document / project",
    uk: "№ документа / проекта",
  },
  "date-document-project": {
    en: "Date document / project",
    uk: "Дата документа / проекта",
  },
  "type-document-project": {
    en: "Type document / project",
    uk: "Вид документа / проекта",
  },
  "name-document-project": {
    en: "Name of the document / Contents",
    uk: "Назва документу / Зміст",
  },
  "parent-id": {
    en: "parent_id",
    uk: "parent_id",
  },
  "controls": {
    en: "Controls",
    uk: "Контролює",
  },
  "result": {
    en: "Result",
    uk: "Результат",
  },
  "task-given-remark": {
    en: "Task given. Remark",
    uk: "Надане завдання. Зауваження",
  },
  "task-text": {
    en: "Task text",
    uk: "Текст завдання",
  },
  "performers-of-the-task": {
    en: "Performers of the task",
    uk: "Виконавці завдання",
  },
  "num-pp": {
    en: "Num PP",
    uk: "Num PP",
  },
  "bta-document-id": {
    en: "bta_document_id",
    uk: "bta_document_id",
  },
  "date-of-receiving": {
    en: "Date of receiving",
    uk: "Дата отримання",
  },
  "execution-date": {
    en: "Execution date",
    uk: "Дата виконання",
  },
  "executer": {
    en: "Executer",
    uk: "Виконавець",
  },
  "responsible-person": {
    en: "Responsible person",
    uk: "Відповідальна особа",
  },
  "controller": {
    en: "Controller",
    uk: "Контролює",
  },
  "main-executor": {
    en: "The main executor",
    uk: "Головний виконавець",
  },
  "task-resolution": {
    en: "Task resolution",
    uk: "Резолюція завдання",
  },
  "paragraph-no": {
    en: "Paragraph №",
    uk: "Пункт №",
  },
  "additions": {
    en: "Additions",
    uk: "Додатки",
  },
  "type-of-connection": {
    en: "Type of connection",
    uk: "Тип зв'язку",
  },
  "notes": {
    en: "Notes",
    uk: "Примітки",
  },
  "incoming-no-correspondent": {
    en: "Incoming № correspondent",
    uk: "Вхідний № кореспонденту",
  },
  "date-to-correspondent": {
    en: "Date to correspondent",
    uk: "Дата кореспонденту",
  },
  "type-of-shipment": {
    en: "Type of shipment",
    uk: "Тип відправлення",
  },
  "name-recipient": {
    en: "Name recipient",
    uk: "П.І.Б. одержувача",
  },
  "no-letterhead": {
    en: "№ letterhead",
    uk: "№ бланку",
  },
  "theme": {
    en: "Theme",
    uk: "Тема",
  },
  "date": {
    en: "Date",
    uk: "Дата",
  },
  "text": {
    en: "Text",
    uk: "Текст",
  },
  "attachments": {
    en: "Attachments",
    uk: "Вкладення",
  },
  "folder-already-created": {
    en: "Folder already created",
    uk: "Папка вже створена",
  },
  "alert-changes": {
    en: "Changes will take effect after re-authorization",
    uk: "Зміни набудуть чинності після повторної авторизації",
  },
  "create-folder": {
    en: "Create folder",
    uk: "Створити папку",
  },
  "name-folder": {
    en: "Name folder",
    uk: "Назва папки",
  },
  "name-folder-required": {
    en: "Rnter name folder",
    uk: "Введіть назва папки",
  },
  "input-correct-password": {
    en: "Fill the correct password",
    uk: "Введіть коректний пароль",
  },
  "user-settings": {
    en: "User settings",
    uk: "Налаштування користувача",
  },
  "user": {
    en: "User",
    uk: "Користувач",
  },
  "user-required": {
    en: "Select user",
    uk: "Виберіть користувача",
  },
  "move": {
    en: "Move",
    uk: "Перемістити",
  },
  "where": {
    en: "Where",
    uk: "Куди",
  },
  "folder-required": {
    en: "Select folder",
    uk: "Виберіть папку",
  },
  "send-mail": {
    en: "Send mail",
    uk: "Відправити пошту",
  },
  "to": {
    en: "To",
    uk: "Кому",
  },
  "to-required": {
    en: "Enter recipient of a letter",
    uk: "Введіть отримувача листа",
  },
  "subject": {
    en: "Subject",
    uk: "Тема",
  },
  "subject-required": {
    en: "Enter mail subject",
    uk: "Введіть тему листа",
  },
  "server-name": {
    en: "Server name",
    uk: "Ім'я сервера",
  },
  "server-name-required": {
    en: "Enter server name",
    uk: "Введіть ім'я сервера",
  },
  "server-code": {
    en: "Server code",
    uk: "Код сервера",
  },
  "server-code-required": {
    en: "Enter server code",
    uk: "Введіть код сервера",
  },
  "imap-host": {
    en: "Imap host",
    uk: "Imap host",
  },
  "imap-host-required": {
    en: "Enter imap host",
    uk: "Введіть imap host",
  },
  "imap-port": {
    en: "Imap port",
    uk: "Imap port",
  },
  "imap-port-required": {
    en: "Enter imap port",
    uk: "Введіть imap port",
  },
  "nomenclature": {
    en: "Nomenclature (№ journal)",
    uk: "Номенклатура (№ журнала)",
  },
  "nomenclature-required": {
    en: "Select nomenclature!",
    uk: "Виберіть номенклатуру!",
  },
  "input-index-cor": {
    en: "Input index cor.",
    uk: "Індекс вх. кор.",
  },
  "registered-no": {
    en: "Registered №",
    uk: "Зареєстровано №",
  },
  "correspondent-required": {
    en: "Select correspondent!",
    uk: "Виберіть кореспондента!",
  },
  "output-no": {
    en: "Output №",
    uk: "Вихідний №",
  },
  "control-date": {
    en: "Control date",
    uk: "Контрольна дата",
  },
  "received": {
    en: "Received",
    uk: "Отримав",
  },
  "co-performers": {
    en: "Co-performers",
    uk: "Співвиконавці",
  },
  "co-performer": {
    en: "Co-performer",
    uk: "Співвиконавець",
  },
  "additional-information": {
    en: "Additional Information",
    uk: "Додаткова інформація",
  },
  "no-departure": {
    en: "№ departure",
    uk: "№ відправлення",
  },
  "upload-file": {
    en: "Upload the file",
    uk: "Завантажте файл",
  },
  "code-kp": {
    en: "Code KP",
    uk: "Код КП",
  },
  "code-okp": {
    en: "Code OKP",
    uk: "Код ЗКППТР",
  },
  "code-etks": {
    en: "Code ETKS",
    uk: "Код ЄТКД",
  },
  "kp": {
    en: "Code KP",
    uk: "Код КП",
  },
  "okp": {
    en: "Code OKP",
    uk: "Код ЗКППТР",
  },
  "etks": {
    en: "Code ETKS",
    uk: "Код ЄТКД",
  },
  "for-envelope": {
    en: "For the envelope",
    uk: "Для конверту",
  },
  "has-right-sign": {
    en: "Has the right to sign source documents",
    uk: "Має право підписувати вихідні документи",
  },
  "definition-full-name": {
    en: "Definition of the full name",
    uk: "Визначення повної назви",
  },
  "not-include-address": {
    en: "do not include in the address",
    uk: "не включати в адресу",
  },
  "refers-to": {
    en: "Refers to...",
    uk: "Відноситься до...",
  },
  "refers-to2": {
    en: "Refers to",
    uk: "Відноситься до",
  },
  "position-required": {
    en: "Enter position",
    uk: "Введіть посаду",
  },
  "personal-data": {
    en: "Personal data",
    uk: "Особові данні",
  },
  "date-birth": {
    en: "Date of birth",
    uk: "Дата народження",
  },
  "contact-information": {
    en: "Contact information",
    uk: "Контактна інформація",
  },
  "fax": {
    en: "Fax",
    uk: "Факс",
  },
  "mobile-phone": {
    en: "Mobile phone",
    uk: "Мобільний телефон",
  },
  "no-cabinet": {
    en: "№ cabinet",
    uk: "№ кабінета",
  },
  "signs": {
    en: "Signs",
    uk: "Ознаки",
  },
  "head": {
    en: "Head",
    uk: "Керівник",
  },
  "right-control": {
    en: "Has the right to control",
    uk: "Має право контролю",
  },
  "released": {
    en: "Released",
    uk: "Звільнено",
  },
  "administrative-unit": {
    en: "Administrative unit",
    uk: "Адміністративна одиниця",
  },
  "administrative-unit-required": {
    en: "Please, enter administrative unit",
    uk: "Буль ласка, введіть адміністративну одиницю",
  },
  "administrative-unit-2": {
    en: "Administrative unit (region, republic)",
    uk: "Адміністративна одиниця (область, республіка)",
  },
  "administrative-unit-3": {
    en: "Administrative unit (city, district)",
    uk: "Адміністративна одиниця (місто, район)",
  },
  "administrative-unit-4": {
    en: "Administrative unit (settlement, city district)",
    uk: "Адміністративна одиниця (селище, район міста)",
  },
  "administrative-unit-5": {
    en: "Administrative unit (settlement, village)",
    uk: "Адміністративна одиниця (селище, село)",
  },
  "nomenclature-no": {
    en: "Nomenclature №",
    uk: "Номенклатура №",
  },
  "nomenclature-no-required": {
    en: "Enter nomenclature №",
    uk: "Буль ласка, введіть № номенклатури",
  },
  "approved": {
    en: "Approved",
    uk: "Затверджено",
  },
  "street-name": {
    en: "Street name",
    uk: "Назва вулиці",
  },
  "street-name-required": {
    en: "Please, enter street name",
    uk: "Буль ласка, введіть назву вулиці",
  },
  "display-toolbar-menu": {
    en: "Display for the main toolbar menu",
    uk: "Відображати для меню головної панелі інструментів",
  },
  "default-parameters": {
    en: "Parameters to be set by default",
    uk: "Параметри, що будуть встановлені за замовченням",
  },
  "other-parameters": {
    en: "Other parameters",
    uk: "Інші параметри",
  },
  "templates": {
    en: "Templates",
    uk: "Шаблони",
  },
  "details": {
    en: "Details",
    uk: "Реквізити",
  },
  "address2": {
    en: "Address:",
    uk: "Адреси:",
  },
  "index-of-treatment": {
    en: "Index of treatment",
    uk: "Індекс звернення",
  },
  "data-of-treatment": {
    en: "Data of treatment",
    uk: "Дата звернення",
  },
  "detail": {
    en: "Detail",
    uk: "Детально",
  },
  "save": {
    en: "Save",
    uk: "Зберегти",
  },
  "current-year": {
    en: "Current year",
    uk: "Поточний рік",
  },
  "previous-year": {
    en: "Previous year",
    uk: "Попередній рік",
  },
  "difference": {
    en: "Difference",
    uk: "Різниця",
  },
  "number-of-registered-documents": {
    en: "Number of registered documents",
    uk: "Кількість зареєстрованних документів",
  },
  "citizens-appeals-by-form-of-income": {
    en: "Citizens appeals by form of income",
    uk: "Звернення громадян за формою надходження",
  },
  "completed-ahead-of-schedule": {
    en: "Completed ahead of schedule",
    uk: "Виконано достроково",
  },
  "completed-in-violation": {
    en: "Completed in violation",
    uk: "Виконано з порушенням",
  },
  "completed": {
    en: "Completed",
    uk: "Виконано",
  },
  "work-phone": {
    en: "Work phone",
    uk: "Робочий телефон",
  },
  "mobile-phone": {
    en: "Mobile phone",
    uk: "Мобільний телефон",
  },
  "species-name": {
    en: "Species name",
    uk: "Назва виду",
  },
  "species-name-required": {
    en: "Enter species name",
    uk: "Введіть назву виду",
  },
  "representative": {
    en: "Representative",
    uk: "Представник",
  },
  "representative-required": {
    en: "Enter representative",
    uk: "Введіть представник",
  },
  "species-name": {
    en: "User",
    uk: "Користувач",
  },
  "species-name-required": {
    en: "Enter species name",
    uk: "Введіть назву виду",
  },
  "namefull": {
    en: "Full name",
    uk: "Повне найменування",
  },
  "nameshort": {
    en: "Short name",
    uk: "Коротке найменування",
  },
  "namegroup": {
    en: "Group",
    uk: "Група",
  },
  "message-invalid-email-or-password": {
    en: "Invalid email or password",
    uk: "Неправильна пошта або пароль",
  },
  "message-invalid-del-access": {
    en: "Access to the resource is blocked",
    uk: "Доступ до ресурсу заблокований",
  },
  "message-server-is-not-responding": {
    en: "Server is not responding, contact support or try again later",
    uk: "Сервер не відповідає, зверніться в підтримку або спробуйте ще раз",
  },
  "message-account-already-exist": {
    en: "This account already exists",
    uk: "Реєстраційний запис вже існує",
  },
  "message-refer-not-found": {
    en: "This invitation does not exist or the invitation has expired",
    uk: "Такого запрошення не існує або термін запрошення закінчився",
  },
  "question-delete-entry": {
    en: "You really want to delete selected entry?",
    uk: "Ви дійсно бажаєте видалити обраний запис?",
  },
  "original": {
    en: "Original",
    uk: "Оригінал",
  },
  "to-send": {
    en: "To send",
    uk: "Для відправлення (розсилання)",
  },
  "add-barcode": {
    en: "Add barcode",
    uk: "Додати штрих код",
  },
  "delete-file": {
    en: "Delete the file",
    uk: "Видалити файл",
  },
  "sign-file": {
    en: "Sign file",
    uk: "Підписати файл",
  },
  "check-signature": {
    en: "Check the signature",
    uk: "Перевірити підпис",
  },
  "remove-signature": {
    en: "Remove signature",
    uk: "Видалити підпис",
  },
  "file": {
    en: "File",
    uk: "Файл",
  },
  "version-number": {
    en: "Version number",
    uk: "Номер версії",
  },
  "date-of-change": {
    en: "Date of change",
    uk: "Дата зміни",
  },
  "barcode-added": {
    en: "Barcode added",
    uk: "Додано штрихкод",
  },
  "signature": {
    en: "Signature",
    uk: "Підпис",
  },
  "profession-classifier": {
    en: "Profession classifier",
    uk: "Класифікатор професій",
  },
  "groups-of-correspondents": {
    en: "Groups of correspondents",
    uk: "Групи кореспондентів",
  },
  "indexes-of-correspondence": {
    en: "Indexes of correspondence",
    uk: "Індекси кореспонденції",
  },
  "types-of-receipts": {
    en: "Types of receipts",
    uk: "Види отримань",
  },
  "forms-of-receipts": {
    en: "Forms of receipt",
    uk: "Форми надходження",
  },
  "types-of-appeals": {
    en: "Types of appeals",
    uk: "Види звернень",
  },
  "characteristics-of-appeals": {
    en: "Characteristics of appeals",
    uk: "Характеристики звернень",
  },
  "types-of-appeals2": {
    en: "Types of appeals",
    uk: "Типи звернень",
  },
  "signs-of-receipt": {
    en: "Signs of receipt",
    uk: "Ознаки надходження",
  },
  "subjects-of-appeals": {
    en: "Subjects of appeals",
    uk: "Суб'єкти звернень",
  },
  "categories-of-authors-of-appeals": {
    en: "Categories of authors of appeals",
    uk: "Категорії авторів звернень",
  },
  "social-status-of-the-authors-of-appeals": {
    en: "Social status of the authors of appeals",
    uk: "Соціальний стан авторів звернень",
  },
  "groups-of-performers": {
    en: "Groups of performers",
    uk: "Групи виконавців",
  },
  "groups-of-performers": {
    en: "Groups of performers",
    uk: "Групи виконавців",
  },
  "performers": {
    en: "Performers",
    uk: "Виконавці",
  },
  "departments-organizations": {
    en: "Departments, organizations",
    uk: "Відділи, організації",
  },
  "is-manager": {
    en: "Manager",
    uk: "Управитель",
  },
  "is-provider": {
    en: "Utility service provider",
    uk: "Надавач комунальних послуг",
  },
  "document-movement-characteristics": {
    en: "Document movement characteristics",
    uk: "Характеристики руху документів",
  },
  "action-stage": {
    en: "Action (stage)",
    uk: "Дія (етап)",
  },
  "content-action-stage": {
    en: "Content of action/stage",
    uk: "Зміст дії/етапу",
  },
  "actions-with-documents": {
    en: "Actions with documents",
    uk: "Дії з документами",
  },
  "control-action": {
    en: "Control action",
    uk: "Контрольна дія",
  },
  "track-the-deadline": {
    en: "Track the deadline",
    uk: "Відслідковувати термін виконання",
  },
  "set-date-execution": {
    en: "Set the execution/control date",
    uk: "Встановлювати дату виконання/контроля",
  },
  "mandatory-entry-file": {
    en: "Mandatory entry of the file",
    uk: "Обов'язкове внесення файлу",
  },
  "fill-execution-date": {
    en: "Fill in the execution date when receiving information",
    uk: "Заповнювати дату виконання при отриманні інформації",
  },
  "use-for-document-types": {
    en: "Use for document types",
    uk: "Використовувати для типів документів",
  },
  "fill-by-default-for-document-types": {
    en: "Fill in by default for document types",
    uk: "Заповнювати за замовчуванням для типів документів",
  },
  "fill-controlling-person": {
    en: "Fill in the controlling person",
    uk: "Заповнювати контролюючу особу",
  },
  "enter-mailing-list-after-document-registration": {
    en: "Enter in the mailing list after document registration (for internal documents)",
    uk: "Вносити в реєстр розсилань після реєстрації документу (для внутрішніх документів)",
  },
  "allowed-edit-the-file": {
    en: "Allowed to edit the file",
    uk: "Дозволено редагувати файл",
  },
};
