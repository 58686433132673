import { useState, useContext } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Space,
  ConfigProvider,
  DatePicker,
  Typography,
  Tabs,
  Upload,
  Checkbox,
  Empty,
  Spin,
} from "antd";
import { UploadOutlined, LoadingOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/uk";
import locale from "antd/lib/locale/uk_UA";

import * as Actions from "../../../Store/actions";
import { translate } from "../../../Translation";
import { environment } from "../../../Services/environment";
import Drawer from "../../../Components/UI/Drawer";
import { request } from "../../../Services";
import UIFile from "../../../Components/UI/File";
import { registryColumns, passingColumns, relationColumns } from "../utils";
import { ModalContext } from "../../../Contexts/modal.context";
import User from "../../../Services/user";
import styles from "./styles.module.scss";

const { TabPane } = Tabs;
const { TextArea } = Input;
const { Title } = Typography;

function FormComponent(props) {
  const [fileList, setFileList] = useState([]);
  const {
    name,
    add,
    passing,
    registry,
    relation,
    includedFiles,
    documentData,
    isEdit,
    fileTabs,
    loading,
    fetchTableData,
    id,
  } = props;
  const modalContext = useContext(ModalContext);

  const intl = (selector) => {
    return translate(selector, props.lang);
  };

  const onFormFinish = async (values) => {
    console.log("Success:", values);
    const fileData = new FormData();
    fileData.append("file", fileList);
    const currentUser = User.decode();

    if (add) {
      await request("/citizens-appeal/insertModalDocuments", {
        method: "post",
        body: { ...values, userId: currentUser?.id, selectId: id },
        cancelTokenKey: "insertModalDocuments",
      });
      fileList.length > 0 &&
        (await request("/citizens-appeal/insertModalFiles", {
          method: "post",
          body: fileData,
          cancelTokenKey: "insertModalFiles",
          headers: { "Content-Type": "multipart/form-data" },
        }));
    } else {
      await request("/citizens-appeal/updateModalDocuments", {
        method: "post",
        body: { ...values, id: documentData?.id },
        cancelTokenKey: "updateModalDocuments",
      });
    }
    modalContext.close(name);
    fetchTableData();
  };

  const onFormFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  function validator(subject, value) {
    return new Promise((resolve, reject) => {
      if (!value) {
        return reject(new Error(subject.message));
      }

      return resolve();
    });
  }

  const uploadProps = {
    name: "file",
    action: `${environment.api}citizens-appeal/insertModalFiles`,
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
    onChange(info) {
      if (info.file.status === "done") {
        setFileList(info.file.originFileObj);
        console.log(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        console.log(`${info.file.name} file upload failed.`);
      }
      if (info.file.status === "removed") {
        if (info.fileList.length === 0) {
          setFileList([]);
        }
      }
    },
  };

  const registryCol = registryColumns(intl);
  const passingCol = passingColumns(intl);
  const relationCol = relationColumns(intl);
  const dateFormat = "DD.MM.YYYY";

  return (
    <ConfigProvider locale={locale}>
      <Form
        className={styles["form"]}
        layout="vertical"
        onFinish={onFormFinish}
        onFinishFailed={onFormFailed}
        initialValues={{
          misuse: false,
          male: false,
          female: false,
          done: false,
          explained: false,
          dateReceipt:
            documentData?.Date_z && moment(documentData?.Date_z, dateFormat),
          dateSigning:
            documentData?.date_send_Z &&
            moment(documentData?.date_send_Z, dateFormat),
          registryIndex: documentData?.Index_z,
          applicant: documentData?.Zajvnik,
          subjectPrim: documentData?.primSubject,
          categoryPrim: documentData?.kategZayavnik_prim,
          socialStatusPrim: documentData?.Soc_state_prim,
          workPlace: documentData?.WorkPlace,
          telephone: documentData?.telefon,
          mail: documentData?.email,
          area: documentData?.name_reg,
          index: documentData?.zip,
          house: documentData?.House,
          apartment: documentData?.flat,
          addressAdditionally: documentData?.adres_text,
          summary: documentData?.Content,
          resolutionDate:
            documentData?.Date_Rezolicia &&
            moment(documentData?.Date_Rezolicia, dateFormat),
          resolutionDateСontrol:
            documentData?.date_kontrol &&
            moment(documentData?.date_kontrol, dateFormat),
          resolutionSummary: documentData?.Resolution,
          notes: documentData?.PrimZver,
          textExecution: documentData?.text_exec,
          description: documentData?.opis,
          case: documentData?.delo,
          dateWithdrawal:
            documentData?.date_Snyato &&
            moment(documentData?.date_Snyato, dateFormat),
          removalNotes: documentData?.prim_Snyato,
          register: documentData?.TurnDocZver_No,
          from:
            documentData?.TurnDocZver_Date &&
            moment(documentData?.TurnDocZver_Date, dateFormat),
          responseNotes: documentData?.TurnDocZver_Prim,
        }}
      >
        <Row>
          <Col lg={8} md={8} sm={24}>
            <Form.Item label={intl("receipt")} name="dateReceipt">
              <DatePicker format={dateFormat} disabled={isEdit} />
            </Form.Item>
            <Form.Item label={intl("date-signing")} name="dateSigning">
              <DatePicker format={dateFormat} disabled={isEdit} />
            </Form.Item>
          </Col>
          <Col lg={16} md={16} sm={24}>
            <Form.Item label={intl("registry-index")} name="registryIndex">
              <Input disabled={isEdit} />
            </Form.Item>
            <Form.Item
              label={intl("applicant")}
              name="applicant"
              rules={[
                {
                  required: true,
                  message: intl("applicant-required"),
                  validator: validator,
                },
              ]}
            >
              <Input disabled={isEdit} />
            </Form.Item>
            <Form.Item
              label={intl("misuse")}
              name="misuse"
              valuePropName="checked"
            >
              <Checkbox disabled={isEdit} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col lg={24} md={24} sm={24}>
            <Tabs defaultActiveKey="1">
              <TabPane tab={intl("appeal")} key="1">
                <Row>
                  <Col lg={12} md={12} sm={24}>
                    <Form.Item label={intl("form-receipt")} name="formReceipt">
                      <Drawer
                        url="/citizens-appeal/modalFormReceiptDocuments"
                        options={{
                          method: "get",
                          cancelTokenKey: "formReceipt",
                        }}
                        documentName={documentData?.name_forma_get}
                        documentCode={documentData?.code_forma_get}
                        documentId={documentData?.formaGetId}
                        isEdit={isEdit}
                        style={{ marginLeft: "8px" }}
                        code
                      />
                    </Form.Item>
                    <Form.Item label={intl("kind-appeal")} name="kindAppeal">
                      <Drawer
                        url="/citizens-appeal/modalKindAppealDocuments"
                        options={{
                          method: "get",
                          cancelTokenKey: "kindAppeal",
                        }}
                        documentName={documentData?.name_vid}
                        documentCode={documentData?.code_vid}
                        documentId={documentData?.vidId}
                        isEdit={isEdit}
                        style={{ marginLeft: "8px" }}
                        code
                      />
                    </Form.Item>
                    <Form.Item label={intl("type-appeal")} name="typeAppeal">
                      <Drawer
                        url="/citizens-appeal/modalTypeAppealDocuments"
                        options={{
                          method: "get",
                          cancelTokenKey: "typeAppeal",
                        }}
                        documentName={documentData?.name_type}
                        documentCode={documentData?.code_type}
                        documentId={documentData?.typeId}
                        isEdit={isEdit}
                        style={{ marginLeft: "8px" }}
                        code
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    lg={{ span: 11, offset: 1 }}
                    md={{ span: 11, offset: 1 }}
                    sm={24}
                  >
                    <Form.Item label={intl("sign-receipt")} name="signReceipt">
                      <Drawer
                        url="/citizens-appeal/modalSignReceiptDocuments"
                        options={{
                          method: "get",
                          cancelTokenKey: "signReceipt",
                        }}
                        documentName={documentData?.name_priznak_get}
                        documentCode={documentData?.code_priznak_get}
                        documentId={documentData?.priznakGetId}
                        isEdit={isEdit}
                        style={{ marginLeft: "8px" }}
                        code
                      />
                    </Form.Item>
                    <Row>
                      <Col lg={20} md={20} sm={24}>
                        <Form.Item label={intl("subject")} name="subject">
                          <Drawer
                            url="/citizens-appeal/modalSubjectDocuments"
                            options={{
                              method: "get",
                              cancelTokenKey: "subject",
                            }}
                            documentName={documentData?.name_Subject}
                            documentCode={documentData?.code_Subject}
                            documentId={documentData?.subjectId}
                            isEdit={isEdit}
                            style={{ marginLeft: "8px" }}
                            code
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        lg={{ span: 3, offset: 1 }}
                        md={{ span: 3, offset: 1 }}
                        sm={24}
                      >
                        <Form.Item label={" "} name="subjectPrim">
                          <Input type="number" disabled={isEdit} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item
                      label={intl("responsible-institution")}
                      name="responsibleInstitution"
                    >
                      <Drawer
                        url="/citizens-appeal/modalResponsibleInstitutionDocuments"
                        options={{
                          method: "get",
                          cancelTokenKey: "responsibleInstitution",
                        }}
                        documentName={documentData?.name_executer}
                        documentCode={documentData?.code_executer}
                        documentId={documentData?.executersId}
                        isEdit={isEdit}
                        style={{ marginLeft: "8px" }}
                        code
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12} sm={24}>
                    <Title level={5}>{intl("data-applicant")}</Title>
                    <Row>
                      <Col lg={20} md={20} sm={24}>
                        <Form.Item label={intl("category")} name="category">
                          <Drawer
                            url="/citizens-appeal/modalApplicantCategoryDocuments"
                            options={{
                              method: "get",
                              cancelTokenKey: "category",
                            }}
                            isEdit={isEdit}
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        lg={{ span: 3, offset: 1 }}
                        md={{ span: 3, offset: 1 }}
                        sm={24}
                      >
                        <Form.Item label={" "} name="categoryPrim">
                          <Input disabled={isEdit} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={20} md={20} sm={24}>
                        <Form.Item
                          label={intl("social-status")}
                          name="socialStatus"
                        >
                          <Drawer
                            url="/citizens-appeal/modalApplicantSocStateDocuments"
                            options={{
                              method: "get",
                              cancelTokenKey: "socialStatus",
                            }}
                            documentName={documentData?.name_SocState}
                            documentCode={documentData?.code_SocState}
                            documentId={documentData?.socStateId}
                            isEdit={isEdit}
                            style={{ marginLeft: "8px" }}
                            code
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        lg={{ span: 3, offset: 1 }}
                        md={{ span: 3, offset: 1 }}
                        sm={24}
                      >
                        <Form.Item label={" "} name="socialStatusPrim">
                          <Input disabled={isEdit} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item label={intl("work-place")} name="workPlace">
                      <Input disabled={isEdit} />
                    </Form.Item>
                    <Row>
                      <Col lg={10} md={10} sm={24}>
                        <Form.Item label={intl("telephone")} name="telephone">
                          <Input disabled={isEdit} />
                        </Form.Item>
                      </Col>
                      <Col
                        lg={{ span: 9, offset: 1 }}
                        md={{ span: 9, offset: 1 }}
                        sm={24}
                      >
                        <Form.Item label={intl("email")} name="mail">
                          <Input disabled={isEdit} />
                        </Form.Item>
                      </Col>
                      <Col
                        lg={{ span: 1, offset: 1 }}
                        md={{ span: 1, offset: 1 }}
                        sm={24}
                      >
                        <Form.Item
                          label={intl("male")}
                          name="male"
                          valuePropName="checked"
                        >
                          <Checkbox disabled={isEdit} />
                        </Form.Item>
                      </Col>
                      <Col
                        lg={{ span: 1, offset: 1 }}
                        md={{ span: 1, offset: 1 }}
                        sm={24}
                      >
                        <Form.Item
                          label={intl("female")}
                          name="female"
                          valuePropName="checked"
                        >
                          <Checkbox disabled={isEdit} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    lg={{ span: 11, offset: 1 }}
                    md={{ span: 11, offset: 1 }}
                    sm={24}
                  >
                    <Title level={5}>{intl("address")}</Title>
                    <Form.Item label={intl("settlement")} name="settlement">
                      <Drawer
                        url="/citizens-appeal/modalAddressSettlementDocuments"
                        options={{
                          method: "get",
                          cancelTokenKey: "settlement",
                        }}
                        isEdit={isEdit}
                      />
                    </Form.Item>
                    <Row>
                      <Col lg={14} md={14} sm={24}>
                        <Form.Item label={intl("area")} name="area">
                          <Input disabled={isEdit} />
                        </Form.Item>
                      </Col>
                      <Col
                        lg={{ span: 9, offset: 1 }}
                        md={{ span: 9, offset: 1 }}
                        sm={24}
                      >
                        <Form.Item label={intl("index")} name="index">
                          <Input disabled={isEdit} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item label={intl("street")} name="street">
                      <Drawer
                        url="/citizens-appeal/modalAddressStreetDocuments"
                        options={{ method: "get", cancelTokenKey: "street" }}
                        documentName={documentData?.NAME_STREET}
                        documentId={documentData?.streetId}
                        isEdit={isEdit}
                      />
                    </Form.Item>
                    <Row>
                      <Col lg={14} md={14} sm={24}>
                        <Form.Item label={intl("house")} name="house">
                          <Input disabled={isEdit} />
                        </Form.Item>
                      </Col>
                      <Col
                        lg={{ span: 9, offset: 1 }}
                        md={{ span: 9, offset: 1 }}
                        sm={24}
                      >
                        <Form.Item label={intl("apartment")} name="apartment">
                          <Input disabled={isEdit} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item
                      label={intl("additionally2")}
                      name="addressAdditionally"
                    >
                      <Input disabled={isEdit} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col lg={24} md={24} sm={24}>
                    <Form.Item label={intl("classifier")} name="classifier">
                      <Drawer isEdit={isEdit} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12} sm={24}>
                    <Title level={5}>{intl("summary")}</Title>
                    <Form.Item label={" "} name="summary">
                      <TextArea rows={4} disabled={isEdit} />
                    </Form.Item>
                    <Title level={5}>{intl("come-from")}</Title>
                    <Row>
                      <Col lg={16} md={16} sm={24}>
                        <Form.Item label={" "} name="comeFrom">
                          <Drawer isEdit={isEdit} />
                        </Form.Item>
                      </Col>
                      <Col
                        lg={{ span: 7, offset: 1 }}
                        md={{ span: 7, offset: 1 }}
                        sm={24}
                      >
                        <Form.Item label={intl("date")} name="date">
                          <DatePicker format={dateFormat} disabled={isEdit} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={16} md={16} sm={24}>
                        <Form.Item label={intl("index")} name="indexCome">
                          <Input disabled={isEdit} />
                        </Form.Item>
                      </Col>
                      <Col
                        lg={{ span: 7, offset: 1 }}
                        md={{ span: 7, offset: 1 }}
                        sm={24}
                      >
                        <Form.Item
                          label={intl("date-control")}
                          name="dateСontrol"
                        >
                          <DatePicker format={dateFormat} disabled={isEdit} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    lg={{ span: 11, offset: 1 }}
                    md={{ span: 11, offset: 1 }}
                    sm={24}
                  >
                    <Title level={5}>{intl("resolution")}</Title>
                    <Form.Item label={intl("author")} name="resolutionAuthor">
                      <Drawer
                        url="/citizens-appeal/modalExecutersDocuments"
                        options={{
                          method: "get",
                          cancelTokenKey: "streresolutionAuthoret",
                        }}
                        documentName={documentData?.Recipient}
                        documentId={documentData?.avtorRezId}
                        isEdit={isEdit}
                      />
                    </Form.Item>
                    <Row>
                      <Col lg={12} md={12} sm={24}>
                        <Form.Item label={intl("date")} name="resolutionDate">
                          <DatePicker format={dateFormat} disabled={isEdit} />
                        </Form.Item>
                      </Col>
                      <Col
                        lg={{ span: 11, offset: 1 }}
                        md={{ span: 11, offset: 1 }}
                        sm={24}
                      >
                        <Form.Item
                          label={intl("date-control")}
                          name="resolutionDateСontrol"
                        >
                          <DatePicker format={dateFormat} disabled={isEdit} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item name="resolutionSummary">
                      <TextArea rows={3} disabled={isEdit} />
                    </Form.Item>
                    <Title level={5}>{intl("notes")}</Title>
                    <Form.Item name="notes">
                      <TextArea rows={3} disabled={isEdit} />
                    </Form.Item>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab={intl("course-execution")} key="2">
                <Row>
                  <Col lg={24} md={24} sm={24}>
                    <Form.Item name="textExecution">
                      <TextArea rows={3} disabled={isEdit} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12} sm={24}>
                    <Form.Item
                      label={intl("result-review")}
                      name="resultReview"
                    >
                      <Drawer
                        url="/citizens-appeal/modalResultExecDocuments"
                        options={{
                          method: "get",
                          cancelTokenKey: "resultReview",
                        }}
                        documentName={documentData?.name_result_exec}
                        documentId={documentData?.resultExecId}
                        isEdit={isEdit}
                      />
                    </Form.Item>
                    <Row>
                      <Col lg={4} md={4} sm={24}>
                        <Form.Item
                          label={intl("done")}
                          name="done"
                          valuePropName="checked"
                        >
                          <Checkbox disabled={isEdit} />
                        </Form.Item>
                      </Col>
                      <Col
                        lg={{ span: 4, offset: 1 }}
                        md={{ span: 4, offset: 1 }}
                        sm={24}
                      >
                        <Form.Item
                          label={intl("explained")}
                          name="explained"
                          valuePropName="checked"
                        >
                          <Checkbox disabled={isEdit} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    lg={{ span: 11, offset: 1 }}
                    md={{ span: 11, offset: 1 }}
                    sm={24}
                  >
                    <Form.Item label={intl("description")} name="description">
                      <Input disabled={isEdit} />
                    </Form.Item>
                    <Form.Item label={intl("case")} name="case">
                      <Input disabled={isEdit} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12} sm={24}>
                    <Title level={5}>{intl("removal-from-control")}</Title>
                    <Form.Item label={intl("took-off")} name="tookOff">
                      <Drawer
                        url="/citizens-appeal/modalExecutersDocuments"
                        options={{ method: "get", cancelTokenKey: "tookOff" }}
                        documentName={documentData?.snyatoFIO}
                        documentId={documentData?.avtorSnyatoId}
                        isEdit={isEdit}
                      />
                    </Form.Item>
                    <Row>
                      <Col lg={6} md={6} sm={24}>
                        <Form.Item
                          label={intl("date-withdrawal")}
                          name="dateWithdrawal"
                        >
                          <DatePicker format={dateFormat} disabled={isEdit} />
                        </Form.Item>
                      </Col>
                      <Col
                        lg={{ span: 17, offset: 1 }}
                        md={{ span: 17, offset: 1 }}
                        sm={24}
                      >
                        <Form.Item label={intl("notes")} name="removalNotes">
                          <Input disabled={isEdit} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    lg={{ span: 11, offset: 1 }}
                    md={{ span: 11, offset: 1 }}
                    sm={24}
                  >
                    <Title level={5}>{intl("response-document")}</Title>
                    <Form.Item label={intl("register")} name="register">
                      <Input disabled={isEdit} />
                    </Form.Item>
                    <Row>
                      <Col lg={6} md={6} sm={24}>
                        <Form.Item label={intl("from")} name="from">
                          <DatePicker format={dateFormat} disabled={isEdit} />
                        </Form.Item>
                      </Col>
                      <Col
                        lg={{ span: 17, offset: 1 }}
                        md={{ span: 17, offset: 1 }}
                        sm={24}
                      >
                        <Form.Item label={intl("notes")} name="responseNotes">
                          <Input disabled={isEdit} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab={intl("preliminary-appeals")} key="3" />
              {!fileTabs ? (
                <TabPane tab={intl("files")} key="4">
                  {includedFiles?.length > 0 ? (
                    <div
                      style={{
                        padding: "1rem",
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      {includedFiles?.map((file) => {
                        return (
                          <UIFile
                            key={file.id}
                            /* onClick={() => this.props.fetchIncludedFile(file, this.props.includedFiles.id, file.fileextention)} */ title={
                              file.filename
                            }
                            download
                          />
                        );
                      })}
                    </div>
                  ) : null}
                  <Upload {...uploadProps}>
                    <Button icon={<UploadOutlined />} disabled={isEdit}>
                      {intl("select-file")}
                    </Button>
                  </Upload>
                </TabPane>
              ) : (
                <>
                  <TabPane
                    tab={intl("included-files")}
                    key="4"
                    disabled={includedFiles?.length === 0}
                  >
                    {includedFiles?.length > 0 ? (
                      <div
                        style={{
                          padding: "1rem",
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        }}
                      >
                        {includedFiles?.map((file) => {
                          return (
                            <UIFile
                              key={file.id}
                              /* onClick={() => fetchIncludedFile(file, includedFiles.id)} */ title={
                                file.filename
                              }
                              download
                              loading={loading}
                            />
                          );
                        })}
                      </div>
                    ) : (
                      <Empty description={intl("no-data")} />
                    )}
                  </TabPane>
                  <TabPane
                    tab={intl("control-terms")}
                    key="5"
                    disabled={registry?.length === 0}
                  >
                    {registry?.length > 0 ? (
                      <Table
                        columns={registryCol}
                        pagination={false}
                        rowKey={(record) => record.id}
                        scroll={{ x: 1024 }}
                        locale={{ emptyText: intl("no-data") }}
                        loading={{
                          spinning: loading,
                          indicator: (
                            <Spin
                              indicator={
                                <LoadingOutlined
                                  style={{ fontSize: 24 }}
                                  spin
                                />
                              }
                            />
                          ),
                        }}
                        dataSource={registry?.map((item) => ({
                          ...item,
                          key: item.id,
                        }))}
                      />
                    ) : (
                      <Empty description={intl("no-data")} />
                    )}
                  </TabPane>
                  <TabPane
                    tab={intl("passing-document")}
                    key="6"
                    disabled={passing?.length === 0}
                  >
                    {passing?.length > 0 ? (
                      <Table
                        columns={passingCol}
                        pagination={false}
                        rowKey={(record) => record.id}
                        scroll={{ x: 1024 }}
                        locale={{ emptyText: intl("no-data") }}
                        loading={{
                          spinning: loading,
                          indicator: (
                            <Spin
                              indicator={
                                <LoadingOutlined
                                  style={{ fontSize: 24 }}
                                  spin
                                />
                              }
                            />
                          ),
                        }}
                        dataSource={passing?.map((item) => ({
                          ...item,
                          key: item.id,
                        }))}
                      />
                    ) : (
                      <Empty description={intl("no-data")} />
                    )}
                  </TabPane>
                  <TabPane
                    tab={intl("linked-documents")}
                    key="7"
                    disabled={relation?.length === 0}
                  >
                    {relation?.length > 0 ? (
                      <Table
                        columns={relationCol}
                        pagination={false}
                        rowKey={(record) => record.id}
                        scroll={{ x: 1024 }}
                        locale={{ emptyText: intl("no-data") }}
                        loading={{
                          spinning: loading,
                          indicator: (
                            <Spin
                              indicator={
                                <LoadingOutlined
                                  style={{ fontSize: 24 }}
                                  spin
                                />
                              }
                            />
                          ),
                        }}
                        dataSource={relation?.map((item) => ({
                          ...item,
                          key: item.id,
                        }))}
                      />
                    ) : (
                      <Empty description={intl("no-data")} />
                    )}
                  </TabPane>
                </>
              )}
            </Tabs>
          </Col>
        </Row>

        <Form.Item>
          <Space style={{ position: "absolute", right: 0 }}>
            <Button
              onClick={() => {
                if (isEdit) {
                  window.history.back();
                  return false;
                } else {
                  modalContext.close(name);
                }
              }}
            >
              {intl("cancel")}
            </Button>
            {isEdit ? null : (
              <Button
                type="primary"
                htmlType="submit"
                className={styles["button"]}
              >
                {intl("ok")}
              </Button>
            )}
          </Space>
        </Form.Item>
      </Form>
    </ConfigProvider>
  );
}

function mapStateToProps(state) {
  return {
    lang: state.lang.value,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: (language) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
    raiseMessage: (message) =>
      dispatch({ type: Actions.RAISE_MESSAGE, message }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormComponent);
