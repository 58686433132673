// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./fonts/Montserrat-Bold.ttf";
import ___CSS_LOADER_URL_IMPORT_1___ from "./fonts/Montserrat-Regular.ttf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"Montserrat Bold\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"Montserrat Regular\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\");\n}", "",{"version":3,"sources":["webpack://src/Assets/fonts.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,+DAA0D;AAC5D;;AAEA;EACE,iCAAiC;EACjC,+DAA6D;AAC/D","sourcesContent":["@font-face {\n  font-family: \"Montserrat Bold\";\n  src: url(\"./fonts/Montserrat-Bold.ttf\") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"Montserrat Regular\";\n  src: url(\"./fonts/Montserrat-Regular.ttf\") format(\"truetype\");\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
