import jwtDecode from "jwt-decode";

/**
 * @typedef {{
 *  id: number,
 *  email: string,
 *  language: string,
 *  firstName: string,
 *  lastName: string,
 *  middleName: string,
 *  iat: number,
 *  accessLevel: 'Support' | 'CompanyAdministrator' | 'CompanyUser' | 'User' | null,
 *  companyId: number,
 *  company?: { id: number, name: string }
 * }} User */

export default class User {
  /**
   * @returns {string}
   */
  static get token() {
    return localStorage.access_token;
  }

  /**
   * @param {string} token
   */

  static store(token) {
    localStorage.access_token = token;
  }

  static unset() {
    if (this.token) {
      localStorage.removeItem("access_token");
    }
  }

  /**
   * @returns {null|User}
   */

  // static decode() {
  //   if (this.token) {
  //     try {
  //       /**
  //        * @type {User}
  //        */
  //       const decoded = jwtDecode(this.token);
  //       if (!decoded) return null;

  //       if (decoded && Date.now() - decoded.exp > 0) {
  //         return decoded;
  //       }

  //       return null;
  //     } catch {
  //       return null;
  //     }
  //   }

  //   return null;
  // }
  static decode() {
    const token = localStorage.getItem("access_token");

    if (token) {
      const decoded = jwtDecode(token);

      if (decoded) {
        const length = decoded.exp.toString().length;
        const time = parseInt(Date.now().toString().slice(0, length), 10);

        if (time - decoded.exp < 0) {
          return decoded;
        }
      }

      localStorage.removeItem("access_token");
    }

    return null;
  }
}
