import { connect } from "react-redux";
import { Col, Row } from "antd";
import "moment/locale/uk";

import CabinetLayout from "../../HOC/CabinetLayout";
import * as Actions from "../../Store/actions";
import IncomingForm from "../../Containers/Cabinet/Incoming";
import OutcomingForm from "../../Containers/Cabinet/Outcoming";
import AdministrativeForm from "../../Containers/Cabinet/Administrative";
import CitizensAppealForm from "../../Containers/Cabinet/CitizensAppeal";
import ResolutionsForm from "../../Containers/Cabinet/Resolutions";
import styles from "./styles.module.scss";

function ViewPage(props) {
  const { type, passing, registry, relation, files, data, loading } =
    props.location.state;
  const obj = {
    loading,
    passing,
    registry,
    relation,
    includedFiles: files,
    documentData: data,
    isEdit: true,
    fileTabs: true,
  };

  return (
    <CabinetLayout activeLink={props.match.path} {...props}>
      <Row className={styles["view-page-row"]}>
        <Col lg={24} md={24} sm={24}>
          {type === "incoming" ? (
            <IncomingForm {...obj} />
          ) : type === "outcoming" ? (
            <OutcomingForm {...obj} />
          ) : type === "administrative" ? (
            <AdministrativeForm {...obj} />
          ) : type === "citizensappeal" ? (
            <CitizensAppealForm {...obj} />
          ) : type === "resolutions" ? (
            <ResolutionsForm {...obj} />
          ) : null}
        </Col>
      </Row>
    </CabinetLayout>
  );
}

function mapStateToProps(state) {
  return {
    lang: state.lang.value,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: (language) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
    raiseMessage: (message) =>
      dispatch({ type: Actions.RAISE_MESSAGE, message }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewPage);
