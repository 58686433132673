import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Col, Row, Table, Checkbox, Button, Spin, ConfigProvider } from "antd";
import LoadingOutlined from "@ant-design/icons/LoadingOutlined";
import locale from "antd/es/locale/uk_UA";

import CabinetLayout from "../../HOC/CabinetLayout";
import * as Actions from "../../Store/actions";
import { translate } from "../../Translation";
import { request } from "../../Services";
import { ModalProvider } from "../../Contexts/modal.context";
import Modal from "../../Components/UI/Modal";
import Form from "../../Containers/Directories/KoatuuDocuments";
import FormStreet from "../../Containers/Directories/StreetsDirectory";
import PopconfirmComponent from "../../Components/UI/Popconfirm";
import styles from "./styles.module.scss";

const { Column, ColumnGroup } = Table;

function KoatuuDirectory(props) {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [rowId, setRowId] = useState(undefined);
  const [rowData, setRowData] = useState(undefined);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    fetchTableData();
  }, []);

  const intl = (selector) => {
    return translate(selector, props.lang);
  };

  const fetchTableData = async () => {
    setLoading(true);
    const { content } = await request(`${props.location.pathname}/getdata`, {
      method: "get",
      cancelTokenKey: "getdata",
    });
    setTableData(content || []);
    setLoading(false);
  };

  const onClickRow = (record) => {
    setRowId(record.id);
    setRowData(record);
    setDisabled(false);
  };

  const onDeleteRow = async () => {
    await request(`${props.location.pathname}/deletedata`, {
      method: "post",
      body: { id: rowId },
      cancelTokenKey: "deletedata",
    });
    fetchTableData();
  };

  const setRowClassName = (record) => {
    return record.id === rowId ? styles["table-select-row"] : "";
  };

  const item = tableData.find((predicate) => predicate["name_obl"]);
  const itemCity = tableData.find((predicate) => predicate["TOWN"]);
  const itemRegion = tableData.find((predicate) => predicate["name_region"]);
  const itemVillage = tableData.find((predicate) => predicate["village"]);

  return (
    <CabinetLayout activeLink={props.match.path} {...props}>
      <Row>
        <Col lg={24} md={24} sm={24}>
          <ModalProvider width={800}>
            <Modal name={intl("add")}>
              {!itemVillage ? (
                <Form
                  name={intl("add")}
                  item={item}
                  itemCity={itemCity}
                  itemRegion={itemRegion}
                  rowId={0}
                  fetchTableData={fetchTableData}
                  pathname={props.location.pathname}
                />
              ) : (
                <FormStreet
                  name={intl("add")}
                  rowId={0}
                  fetchTableData={fetchTableData}
                  pathname={props.location.pathname}
                />
              )}
            </Modal>
            <Modal name={intl("edit")} disabled={disabled}>
              {!itemVillage ? (
                <Form
                  name={intl("edit")}
                  item={item}
                  itemCity={itemCity}
                  itemRegion={itemRegion}
                  rowData={rowData}
                  rowId={rowId}
                  fetchTableData={fetchTableData}
                  pathname={props.location.pathname}
                />
              ) : (
                <FormStreet
                  name={intl("edit")}
                  rowData={rowData}
                  rowId={rowId}
                  fetchTableData={fetchTableData}
                  pathname={props.location.pathname}
                />
              )}
            </Modal>
            <PopconfirmComponent
              intl={intl}
              onDeleteRow={onDeleteRow}
              disabled={disabled}
              directories={true}
            />
          </ModalProvider>
        </Col>
        <Col lg={24} md={24} sm={24}>
          <ConfigProvider locale={locale}>
            <Table
              className={styles.table}
              dataSource={tableData}
              onRow={(record) => {
                return { onClick: () => onClickRow(record) };
              }}
              rowClassName={setRowClassName}
              loading={{
                spinning: loading,
                indicator: (
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 24 }} spin />
                    }
                  />
                ),
              }}
              pagination={{ position: ["bottomCenter"], showSizeChanger: true }}
              size="small"
              bordered
            >
              <ColumnGroup
                title={intl("top-level-administrative-unit")}
                align="center"
                ellipsis="true"
              >
                <Column
                  title={intl("country")}
                  dataIndex="nazva_country"
                  key="nazva_country"
                  ellipsis="true"
                />
                {item ? (
                  <Column
                    title={intl("region-special-status")}
                    dataIndex="name_obl"
                    key="name_obl"
                    ellipsis="true"
                  />
                ) : null}
                {itemCity ? (
                  <Column
                    title={intl("city-of-regional-subordination")}
                    dataIndex="TOWN"
                    key="TOWN"
                    ellipsis="true"
                  />
                ) : null}
                {itemRegion ? (
                  <Column
                    title={intl("region")}
                    dataIndex="name_region"
                    key="name_region"
                    ellipsis="true"
                  />
                ) : null}
                {itemVillage ? (
                  <Column
                    title={intl("village")}
                    dataIndex="village"
                    key="village"
                    ellipsis="true"
                  />
                ) : null}
              </ColumnGroup>
              <ColumnGroup title={intl("administrative-unit")} align="center">
                {!itemVillage ? (
                  <Column
                    title={intl("code")}
                    dataIndex="Code"
                    key="Code"
                    align="center"
                  />
                ) : null}
                <Column
                  title={intl("type2")}
                  dataIndex="name_typeregion"
                  key="name_typeregion"
                  ellipsis="true"
                />
                <Column
                  title={intl("name")}
                  dataIndex="Name"
                  key="Name"
                  ellipsis="true"
                />
                {!itemVillage ? (
                  <Column
                    title={intl("abbreviated")}
                    dataIndex="shortName"
                    key="shortName"
                    ellipsis="true"
                  />
                ) : null}
                {itemVillage ? (
                  <Column
                    title={intl("previous-street-name")}
                    dataIndex="OldName"
                    key="OldName"
                    ellipsis="true"
                  />
                ) : null}
                {/* <Column title={intl("full-name")} dataIndex="country_eng" key="country_eng" /> */}
              </ColumnGroup>
              {!itemVillage ? (
                <ColumnGroup title={intl("other")} align="center">
                  <Column
                    title={
                      itemRegion
                        ? intl("district-center")
                        : itemCity
                        ? intl("village-council")
                        : intl("city-with-special-status")
                    }
                    dataIndex="is_city"
                    key="is_city"
                    align="center"
                    render={(_, record) => (
                      <Checkbox defaultChecked={!!record.is_city} />
                    )}
                  />
                  <Column
                    title={intl("service-information")}
                    dataIndex="is_service"
                    key="is_service"
                    align="center"
                    render={(_, record) => (
                      <Checkbox defaultChecked={!!record.is_service} />
                    )}
                  />
                  <Column
                    title={intl("notes")}
                    dataIndex="Notes"
                    key="Notes"
                    ellipsis="true"
                  />
                </ColumnGroup>
              ) : null}
              <ColumnGroup title={intl("user")} align="center">
                <Column
                  title={intl("login")}
                  dataIndex="user_name"
                  key="user_name"
                  ellipsis="true"
                />
              </ColumnGroup>
            </Table>
          </ConfigProvider>
        </Col>
      </Row>
    </CabinetLayout>
  );
}

function mapStateToProps(state) {
  return {
    lang: state.lang.value,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: (language) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
    raiseMessage: (message) =>
      dispatch({ type: Actions.RAISE_MESSAGE, message }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(KoatuuDirectory);
