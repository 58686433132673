import { useContext } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Space,
  ConfigProvider,
  Checkbox,
} from "antd";
import { connect } from "react-redux";
import "moment/locale/uk";
import locale from "antd/lib/locale/uk_UA";

import * as Actions from "../../../Store/actions";
import { translate } from "../../../Translation";
import Drawer from "../../../Components/UI/Drawer";
import { request } from "../../../Services";
import { ModalContext } from "../../../Contexts/modal.context";
import User from "../../../Services/user";
import styles from "./styles.module.scss";

function FormComponent(props) {
  const [form] = Form.useForm();
  const {
    rowData,
    rowId,
    fetchTableData,
    pathname,
    item,
    itemCity,
    itemRegion,
    name,
  } = props;
  const modalContext = useContext(ModalContext);

  const intl = (selector) => {
    return translate(selector, props.lang);
  };

  const onFormFinish = async (values) => {
    console.log("Success:", values);
    const currentUser = User.decode();

    rowId === 0
      ? await request(`${pathname}/adddata`, {
          method: "post",
          body: { ...values, userId: currentUser?.id },
          cancelTokenKey: "adddata",
        })
      : await request(`${pathname}/updatedata`, {
          method: "post",
          body: { ...values, id: rowId },
          cancelTokenKey: "updatedata",
        });
    modalContext.close(name);
    fetchTableData();
  };

  const onFormFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  function validator(subject, value) {
    return new Promise((resolve, reject) => {
      if (!value) {
        return reject(new Error(subject.message));
      }

      return resolve();
    });
  }

  return (
    <ConfigProvider locale={locale}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFormFinish}
        onFinishFailed={onFormFailed}
        initialValues={{
          code: rowData?.Code,
          name: rowData?.Name,
          shortName: rowData?.shortName,
          notes: rowData?.Notes,
          isCity: !!rowData?.is_city ?? false,
          isInfo: !!rowData?.is_service ?? false,
          isAddress: !!rowData?.isAddress ?? false,
        }}
      >
        <Row>
          <Col lg={24} md={24} sm={24}>
            <Row>
              <Col lg={8} md={8} sm={24}>
                {item ? (
                  <Form.Item
                    label={intl("administrative-unit")}
                    name="adminUnit"
                    rules={[
                      {
                        required: true,
                        message: intl("administrative-unit-required"),
                        validator: validator,
                      },
                    ]}
                  >
                    <Drawer
                      url={
                        itemRegion
                          ? "/directories/village-directory/getdata"
                          : itemCity
                          ? "/directories/town-directory/getdata"
                          : "/directories/region-directory/getdata"
                      }
                      options={{ method: "get", cancelTokenKey: "country" }}
                      documentName={
                        itemRegion
                          ? rowData?.name_region
                          : itemCity
                          ? rowData?.TOWN
                          : rowData?.name_obl
                      }
                      documentId={
                        itemRegion
                          ? rowData?.STA_REGION_ID
                          : itemCity
                          ? rowData?.STA_TOWN_ID
                          : rowData?.KOD_OBLASTI
                      }
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    label={intl("country")}
                    name="adminUnit"
                    rules={[
                      {
                        required: true,
                        message: intl("country-required"),
                        validator: validator,
                      },
                    ]}
                  >
                    <Drawer
                      url="/directories/countries-directory/getdata"
                      options={{ method: "get", cancelTokenKey: "country" }}
                      documentName={rowData?.nazva_country}
                      documentId={rowData?.KOD_COUNTRTY}
                    />
                  </Form.Item>
                )}
              </Col>
              <Col
                lg={{ span: 7, offset: 1 }}
                md={{ span: 7, offset: 1 }}
                sm={24}
              >
                <Form.Item label={intl("type2")} name="type">
                  <Drawer
                    url="/directories/types-toponyms-directory/getdata"
                    options={{ method: "get", cancelTokenKey: "type" }}
                    documentName={rowData?.name_typeregion}
                    documentId={rowData?.STA_TypeRegion_ID}
                  />
                </Form.Item>
              </Col>
              <Col
                lg={{ span: 7, offset: 1 }}
                md={{ span: 7, offset: 1 }}
                sm={24}
              >
                <Form.Item
                  label={intl("definition-full-name")}
                  name="definitionFullName"
                >
                  <Drawer
                    url="/directories/relation-typename/getdata"
                    options={{
                      method: "get",
                      cancelTokenKey: "definitionFullName",
                    }}
                    documentName={rowData?.relation_typename}
                    documentId={rowData?.sta_Relation_TypeNAme_id}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col lg={5} md={5} sm={24}>
                <Form.Item label={intl("code")} name="code">
                  <Input />
                </Form.Item>
              </Col>
              <Col
                lg={{ span: 12, offset: 1 }}
                md={{ span: 12, offset: 1 }}
                sm={24}
              >
                <Form.Item
                  label={intl("name")}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: intl("name-required"),
                      validator: validator,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col
                lg={{ span: 5, offset: 1 }}
                md={{ span: 5, offset: 1 }}
                sm={24}
              >
                <Form.Item label={intl("abbreviated")} name="shortName">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label={intl("notes")} name="notes">
              <Input />
            </Form.Item>
            <Row>
              <Col lg={8} md={8} sm={24}>
                <Form.Item name="isCity" valuePropName="checked">
                  <Checkbox>
                    {itemRegion
                      ? intl("district-center")
                      : itemCity
                      ? intl("village-council")
                      : intl("city-with-special-status")}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col
                lg={{ span: 7, offset: 1 }}
                md={{ span: 7, offset: 1 }}
                sm={24}
              >
                <Form.Item name="isInfo" valuePropName="checked">
                  <Checkbox>{intl("service-information")}</Checkbox>
                </Form.Item>
              </Col>
              <Col
                lg={{ span: 7, offset: 1 }}
                md={{ span: 7, offset: 1 }}
                sm={24}
              >
                <Form.Item name="isAddress" valuePropName="checked">
                  <Checkbox>{intl("not-include-address")}</Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Form.Item>
          <Space style={{ display: "flex", justifyContent: "end" }}>
            <Button onClick={() => modalContext.close(name)}>
              {intl("cancel")}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className={styles["button"]}
            >
              {intl("ok")}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </ConfigProvider>
  );
}

function mapStateToProps(state) {
  return {
    lang: state.lang.value,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: (language) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
    raiseMessage: (message) =>
      dispatch({ type: Actions.RAISE_MESSAGE, message }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormComponent);
