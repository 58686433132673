// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_list__1ybS6 {\n  /* margin-top: 2rem; */\n  padding: 0 1rem;\n}\n\n.styles_button__2HrSH {\n  width: 100%;\n  height: 100%;\n  background: #5abab9;\n  font-size: 16px;\n  border: none;\n  color: #ffffff;\n  line-height: 1;\n  padding: 7px 15px;\n}\n\n.styles_button__2HrSH:hover,\n.styles_button__2HrSH:focus {\n  background: #5abab9;\n  color: #3f434e;\n}\n", "",{"version":3,"sources":["webpack://src/Containers/Cabinet/Tasks/List/styles.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,YAAY;EACZ,cAAc;EACd,cAAc;EACd,iBAAiB;AACnB;;AAEA;;EAEE,mBAAmB;EACnB,cAAc;AAChB","sourcesContent":[".list {\n  /* margin-top: 2rem; */\n  padding: 0 1rem;\n}\n\n.button {\n  width: 100%;\n  height: 100%;\n  background: #5abab9;\n  font-size: 16px;\n  border: none;\n  color: #ffffff;\n  line-height: 1;\n  padding: 7px 15px;\n}\n\n.button:hover,\n.button:focus {\n  background: #5abab9;\n  color: #3f434e;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "styles_list__1ybS6",
	"button": "styles_button__2HrSH"
};
export default ___CSS_LOADER_EXPORT___;
