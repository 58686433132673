import { useContext } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Space,
  ConfigProvider,
  Checkbox,
} from "antd";
import { connect } from "react-redux";
import "moment/locale/uk";
import locale from "antd/lib/locale/uk_UA";

import * as Actions from "../../../Store/actions";
import { translate } from "../../../Translation";
import Drawer from "../../../Components/UI/Drawer";
import { request } from "../../../Services";
import { ModalContext } from "../../../Contexts/modal.context";
import User from "../../../Services/user";
import styles from "./styles.module.scss";

function FormComponent(props) {
  const [form] = Form.useForm();
  const { rowData, rowId, fetchTableData, pathname, name } = props;
  const modalContext = useContext(ModalContext);

  const intl = (selector) => {
    return translate(selector, props.lang);
  };

  const onFormFinish = async (values) => {
    console.log("Success:", values);
    const currentUser = User.decode();

    rowId === 0
      ? await request(`${pathname}/adddata`, {
          method: "post",
          body: { ...values, userId: currentUser?.id },
          cancelTokenKey: "adddata",
        })
      : await request(`${pathname}/updatedata`, {
          method: "post",
          body: { ...values, id: rowId },
          cancelTokenKey: "updatedata",
        });
    modalContext.close(name);
    fetchTableData();
  };

  const onFormFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  function validator(subject, value) {
    return new Promise((resolve, reject) => {
      if (!value) {
        return reject(new Error(subject.message));
      }

      return resolve();
    });
  }

  return (
    <ConfigProvider locale={locale}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFormFinish}
        onFinishFailed={onFormFailed}
        initialValues={{
          isAddress: !!rowData?.is_not_country_in_adres ?? false,
          code: rowData?.KOD_COUNTRY,
          name: rowData?.NAZVA_COUNTRY,
          codeNum: rowData?.code_num,
          codeAlpha2: rowData?.code_alfa_2,
          codeAlpha3: rowData?.code_alfa_3,
        }}
      >
        <Row>
          <Col lg={24} md={24} sm={24}>
            <Form.Item label={intl("type2")} name="type">
              <Drawer
                url="/directories/types-toponyms-directory/getdata"
                options={{ method: "get", cancelTokenKey: "type" }}
                documentName={rowData?.Name_TypeRegion}
                documentId={rowData?.STA_TypeRegion_ID}
              />
            </Form.Item>
            <Row style={{ display: "flex", alignItems: "center" }}>
              <Col lg={12} md={12} sm={24}>
                <Form.Item
                  label={intl("definition-full-name")}
                  name="definitionFullName"
                >
                  <Drawer
                    url="/directories/relation-typename/getdata"
                    options={{
                      method: "get",
                      cancelTokenKey: "definitionFullName",
                    }}
                    documentName={rowData?.RELATION_TypeName}
                    documentId={rowData?.sta_Relation_TypeNAme_id}
                  />
                </Form.Item>
              </Col>
              <Col
                lg={{ span: 11, offset: 1 }}
                md={{ span: 11, offset: 1 }}
                sm={24}
              >
                <Form.Item name="isAddress" valuePropName="checked">
                  <Checkbox>{intl("not-include-address")}</Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={6} sm={24}>
                <Form.Item label={intl("code")} name="code">
                  <Input />
                </Form.Item>
              </Col>
              <Col
                lg={{ span: 17, offset: 1 }}
                md={{ span: 17, offset: 1 }}
                sm={24}
              >
                <Form.Item
                  label={intl("country")}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: intl("country-required"),
                      validator: validator,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col lg={8} md={8} sm={24}>
                <Form.Item label={intl("digital")} name="codeNum">
                  <Input />
                </Form.Item>
              </Col>
              <Col
                lg={{ span: 7, offset: 1 }}
                md={{ span: 7, offset: 1 }}
                sm={24}
              >
                <Form.Item label={`${intl("alpha")}-2`} name="codeAlpha2">
                  <Input />
                </Form.Item>
              </Col>
              <Col
                lg={{ span: 7, offset: 1 }}
                md={{ span: 7, offset: 1 }}
                sm={24}
              >
                <Form.Item label={`${intl("alpha")}-3`} name="codeAlpha3">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Form.Item>
          <Space style={{ display: "flex", justifyContent: "end" }}>
            <Button onClick={() => modalContext.close(name)}>
              {intl("cancel")}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className={styles["button"]}
            >
              {intl("ok")}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </ConfigProvider>
  );
}

function mapStateToProps(state) {
  return {
    lang: state.lang.value,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: (language) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
    raiseMessage: (message) =>
      dispatch({ type: Actions.RAISE_MESSAGE, message }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormComponent);
