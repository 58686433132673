// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_Row__2jtVf {\n    background: linear-gradient(#b0e977, #f1eaea);\n}\n\n.styles_Table__1tw0B table {\n    font-size: 16px;\n}\n\n.styles_Table__1tw0B div {\n    background: transparent;\n}\n\n.styles_Table__1tw0B div table thead tr th {\n    background: transparent;\n}\n\n.styles_Table__1tw0B table thead tr th { \n    text-align: center;\n}\n\n.styles_Columns__1AM_l {\n    text-align: right;\n}\n\n.styles_Green__dNhQ0 {\n    color: green;\n    font-weight: 600;\n}\n\n.styles_Red__1vDqn {\n    color: red;\n    font-weight: 600;\n}\n\n@media (max-width: 1064px) {\n    .styles_Table__1tw0B table {\n        font-size: 12px;\n    }\n}\n\n@media (max-width: 910px) {\n    .styles_Table__1tw0B table {\n        font-size: 9px;\n    }\n}", "",{"version":3,"sources":["webpack://src/Containers/Statistics/Receipts/styles.module.css"],"names":[],"mappings":"AAAA;IACI,6CAA6C;AACjD;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI;QACI,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,cAAc;IAClB;AACJ","sourcesContent":[".Row {\n    background: linear-gradient(#b0e977, #f1eaea);\n}\n\n.Table table {\n    font-size: 16px;\n}\n\n.Table div {\n    background: transparent;\n}\n\n.Table div table thead tr th {\n    background: transparent;\n}\n\n.Table table thead tr th { \n    text-align: center;\n}\n\n.Columns {\n    text-align: right;\n}\n\n.Green {\n    color: green;\n    font-weight: 600;\n}\n\n.Red {\n    color: red;\n    font-weight: 600;\n}\n\n@media (max-width: 1064px) {\n    .Table table {\n        font-size: 12px;\n    }\n}\n\n@media (max-width: 910px) {\n    .Table table {\n        font-size: 9px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Row": "styles_Row__2jtVf",
	"Table": "styles_Table__1tw0B",
	"Columns": "styles_Columns__1AM_l",
	"Green": "styles_Green__dNhQ0",
	"Red": "styles_Red__1vDqn"
};
export default ___CSS_LOADER_EXPORT___;
