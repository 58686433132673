import { useRef, useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Typography,
  Modal,
  Space,
  Checkbox,
  Tooltip,
} from "antd";
import { KeyOutlined } from "@ant-design/icons";

import * as Actions from "../../../Store/actions";
import { translate } from "../../../Translation";
import { request } from "../../../Services";
import SelectComponent from "../../UI/Select";
import { generatePassword } from "./utils/generatePassword";
import styles from "./styles.module.css";

const { Title } = Typography;

function ModalCreateUsers(props) {
  const form = useRef();
  const [visible, setVisible] = useState(false);
  const [flagUser, setFlagUser] = useState(false);
  const [flagRepres, setFlagRepres] = useState(false);
  const [rolesData, setRolesData] = useState(undefined);
  const [usersData, setUsersData] = useState(undefined);
  const [represData, setRepresData] = useState(undefined);
  const { name, selectData, disabled, flag, fetchTableData } = props;

  const intl = (selector) => {
    return translate(selector, props.lang);
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const fetchRolesData = async () => {
    const { content } = await request("/administration/getroles", {
      method: "get",
      cancelTokenKey: "roles",
    });
    setRolesData(content || []);
  };

  const fetchUsersData = async () => {
    const { content } = await request("/profile/btausers", {
      method: "get",
      cancelTokenKey: "btausers",
    });
    setUsersData(content || []);
  };

  const fetchRepresData = async () => {
    const { content } = await request("/profile/repres", {
      method: "get",
      cancelTokenKey: "repres",
    });
    setRepresData(content || []);
  };

  const onFormFinish = async (values) => {
    console.log("values: ", values);
    flag
      ? await request("/administration/insertusers", {
          method: "post",
          body: values,
          cancelTokenKey: "insertusers",
        })
      : await request("/administration/updateusers", {
          method: "post",
          body: { ...values, id: selectData?.id },
          cancelTokenKey: "updateusers",
        });
    setVisible(false);
    fetchTableData();
  };

  const onFormFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onChangeSelect = (name, value) => {
    if (name === "role") {
      form.current.setFieldsValue({ role: value });
      setFlagUser(value !== 8 ? true : false);
      setFlagRepres(value === 8 ? true : false);
    } else if (name === "usersed") {
      form.current.setFieldsValue({ user: value });
    } else {
      form.current.setFieldsValue({ representative: value });
    }
  };

  const onGeneratePassword = () => {
    const newPassword = generatePassword();
    form.current.setFieldsValue({ password: newPassword });
  };

  return (
    <>
      <Button
        className={styles["modal-button"]}
        onClick={showModal}
        disabled={disabled}
      >
        {name}
      </Button>
      <Modal
        width={800}
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose={true}
      >
        <Row>
          <Col lg={24} md={24} sm={24} style={{ padding: "0 1rem" }}>
            <Title level={3}>{intl("personal-info")}</Title>
            <Form
              ref={form}
              layout="vertical"
              onFinish={onFormFinish}
              onFinishFailed={onFormFailed}
              initialValues={{
                email: selectData?.email,
                role: selectData?.roleId,
                lastName: selectData?.LastName,
                firstName: selectData?.FirstName,
                middleName: selectData?.MiddleName,
                user: selectData?.userId,
                del: selectData?.del || false,
              }}
            >
              <Row>
                <Col lg={10} md={10} sm={24}>
                  {/* <Form.Item
                                            name="login"
                                            label={intl("login")}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: intl("login-required"),
                                                },
                                            ]} >
                                            <Input type="text" name="login" />
                                        </Form.Item> */}
                  <Form.Item
                    name="email"
                    label={intl("mail")}
                    rules={[
                      {
                        required: disabled ?? true,
                        message: intl("mail-required"),
                      },
                      {
                        pattern:
                          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: intl("email-pattern"),
                      },
                    ]}
                  >
                    <Input type="text" name="email" disabled={!flag} />
                  </Form.Item>
                  <div style={{ display: "flex" }}>
                    <Form.Item
                      name="password"
                      label={intl("password")}
                      rules={[
                        {
                          required: disabled ?? true,
                          message: intl("password-required"),
                        },
                        {
                          pattern:
                            /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{8,}/g,
                          message: intl("input-correct-password"),
                        },
                      ]}
                      style={{ width: "100%" }}
                    >
                      <Input type="text" name="password" />
                    </Form.Item>
                    <Form.Item label=" ">
                      <Tooltip title={intl("generate-password")}>
                        <Button onClick={onGeneratePassword}>
                          <KeyOutlined />
                        </Button>
                      </Tooltip>
                    </Form.Item>
                  </div>
                  <Form.Item
                    name="role"
                    label={intl("role")}
                    rules={[
                      {
                        required: disabled ?? true,
                        message: intl("role-required"),
                      },
                    ]}
                  >
                    <SelectComponent
                      name="role"
                      roleName={selectData?.role}
                      onChangeSelect={onChangeSelect}
                      rolesData={rolesData}
                      fetchData={fetchRolesData}
                    />
                  </Form.Item>
                  <Form.Item
                    name="representative"
                    label={intl("representative")}
                    rules={[
                      {
                        required: flag ? flagRepres : false,
                        message: intl("representative-required"),
                      },
                    ]}
                  >
                    <SelectComponent
                      name="representative"
                      rolesData={represData}
                      onChangeSelect={onChangeSelect}
                      fetchData={fetchRepresData}
                      disabled={!selectData ? !flagRepres : false}
                    />
                  </Form.Item>
                </Col>
                <Col
                  lg={{ span: 10, offset: 2 }}
                  md={{ span: 10, offset: 2 }}
                  sm={24}
                >
                  <Form.Item
                    name="lastName"
                    label={intl("last-name")}
                    rules={[
                      {
                        required: disabled ?? true,
                        message: intl("last-name-required"),
                      },
                    ]}
                  >
                    <Input type="text" name="LastName" />
                  </Form.Item>
                  <Form.Item
                    name="firstName"
                    label={intl("first-name")}
                    rules={[
                      {
                        required: disabled ?? true,
                        message: intl("first-name-required"),
                      },
                    ]}
                  >
                    <Input type="text" name="FirstName" />
                  </Form.Item>
                  <Form.Item
                    name="middleName"
                    label={intl("middle-name")}
                    rules={[
                      {
                        required: disabled ?? true,
                        message: intl("middle-name-required"),
                      },
                    ]}
                  >
                    <Input type="text" name="MiddleName" />
                  </Form.Item>
                  <Form.Item
                    name="user"
                    label={intl("user")}
                    rules={[
                      {
                        required: flag ? flagUser : false,
                        message: intl("user-required"),
                      },
                    ]}
                  >
                    <SelectComponent
                      name="usersed"
                      roleName={selectData?.UserName}
                      rolesData={usersData}
                      onChangeSelect={onChangeSelect}
                      fetchData={fetchUsersData}
                      disabled={!selectData ? !flagUser : false}
                    />
                  </Form.Item>
                  <Form.Item
                    name="del"
                    label={intl("access")}
                    valuePropName="checked"
                  >
                    <Checkbox>{intl("allow")}</Checkbox>
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item>
                <Space style={{ position: "absolute", right: 0 }}>
                  <Button onClick={handleCancel}>{intl("cancel")}</Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className={styles["modal-button"]}
                  >
                    {intl("ok")}
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

function mapStateToProps(state) {
  return {
    lang: state.lang.value,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: (language) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalCreateUsers);
