import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Row, DatePicker, ConfigProvider, Space, Button } from "antd";
import moment from "moment";
import "moment/locale/uk";
import locale from "antd/es/locale/uk_UA";

import CabinetLayout from "../../HOC/CabinetLayout";
import User from "../../Services/user";
import * as Actions from "../../Store/actions";
import { translate } from "../../Translation";
import { request } from "../../Services";
import styles from "./styles.module.scss";
import { ModalProvider } from "../../Contexts/modal.context";
import SendMail from "../../Components/Modals/SendMail";
import MoveData from "../../Components/Modals/MoveData";
import Modal from "../../Components/UI/Modal";
import Table from "../../Containers/Cabinet/Tasks/Table";
import Form from "../../Containers/Cabinet/Incoming";
import List from "../../Containers/Cabinet/Tasks/List";

class CabinetTasks extends Component {
  state = {
    from: null,
    to: null,
    data: [],
    listId: undefined,
    includedFiles: { entities: [] },
    documentData: undefined,
    loading: false,
    disabled: true,
  };

  componentDidMount() {
    const currentUser = User.decode();
    if (!currentUser) return this.props.history.replace("/auth");

    this.onDataChangeFrom(moment(Date.now()).startOf("week"));
    this.onDataChangeTo(moment(Date.now()));
  }

  intl = (selector) => {
    return translate(selector, this.props.lang);
  };

  fetchTableData = () => {
    const { listId, from, to } = this.state;

    this.fetchData("/tasks/gettabledata", {
      method: "post",
      body: { listId: listId, from: from, to: to },
      cancelTokenKey: "table",
    });
  };

  fetchData = (url, options) => {
    this.setState({ loading: true }, async () => {
      const data = await request(url, options);
      this.setState({ loading: false }, () => {
        if (data) {
          this.setState({
            data: data.content,
            // pagination: {
            //   ...options.body.pagination,
            //   total: data.info.total,
            // },
          });
        } else {
          this.setState({
            data: [],
            // pagination: {
            //   ...options.body.pagination,
            //   total: 0,
            // },
          });
        }
      });
    });
  };

  handleChangeListId = (id) => {
    this.setState({ listId: id });
  };

  onDataChangeFrom = (data, listId) => {
    const { from, to } = this.state;

    this.setState({ from: moment(data, "DD-MM-YYYY").startOf("date") }, () => {
      if (from && to) {
        this.fetchData("/tasks/gettabledata", {
          method: "post",
          body: {
            listId: listId,
            from: moment(data, "DD-MM-YYYY").startOf("date"),
            to: to,
          },
          cancelTokenKey: "table",
        });
      }
    });
  };

  onDataChangeTo = (data, listId) => {
    const { from, to } = this.state;

    this.setState({ to: moment(data, "DD-MM-YYYY").endOf("date") }, () => {
      if (from && to) {
        this.fetchData("/tasks/gettabledata", {
          method: "post",
          body: {
            listId: listId,
            from: from,
            to: moment(data, "DD-MM-YYYY").endOf("date"),
          },
          cancelTokenKey: "table",
        });
      }
    });
  };

  changeDocumentData = (record) => {
    this.setState({ documentData: record, disabled: false });
  };

  changeFilesData = (name, data) => {
    this.setState((prevState) => ({ ...prevState, [name]: data }));
  };

  render() {
    const {
      loading,
      documentData,
      passingDocuments,
      registryDocuments,
      relationDocuments,
      includedFiles,
      disabled,
      from,
      to,
      listId,
    } = this.state;

    return (
      <CabinetLayout activeLink={this.props.match.path} {...this.props}>
        <Row>
          <Col lg={24} md={24} sm={24}>
            <List
              from={from}
              to={to}
              fetchTableData={this.fetchTableData}
              fetchData={this.fetchData}
              handleChangeListId={this.handleChangeListId}
            />
          </Col>

          <Col lg={24} md={24} sm={24}>
            <Space direction="vertical" size={12}>
              <ConfigProvider locale={locale}>
                <DatePicker
                  defaultValue={moment(Date.now()).startOf("week")}
                  format={["DD.MM.YYYY", "DD-MM-YYYY"]}
                  picker="date"
                  onChange={(_, dates) => this.onDataChangeFrom(dates, listId)}
                  style={{ marginLeft: "1rem" }}
                />
                <DatePicker
                  defaultValue={moment(Date.now())}
                  format={["DD.MM.YYYY", "DD-MM-YYYY"]}
                  picker="date"
                  onChange={(_, dates) => this.onDataChangeTo(dates, listId)}
                  style={{ marginLeft: "1rem" }}
                />
              </ConfigProvider>
            </Space>
          </Col>

          {/* <Col lg={24} md={24} sm={24}>
            <ModalProvider>
              <Modal name={this.intl("add")} >
                <Form name={this.intl("add")} fetchTableData={this.fetchTableData} add={true} />
              </Modal>
              <Modal name={this.intl("edit")} disabled={disabled}>
                <Form name={this.intl("edit")} includedFiles={includedFiles} documentData={documentData} fetchTableData={this.fetchTableData} />
              </Modal>
              <Button className={styles["view-button"]} disabled={disabled}>
                <Link
                  to={{
                    pathname: "/cabinet/incomingview",
                    state: {
                      type: "incoming",
                      loading: loading,
                      data: documentData,
                      passing: passingDocuments,
                      registry: registryDocuments,
                      relation: relationDocuments,
                      files: includedFiles
                    }
                  }}>
                  {this.intl("view")}
                </Link>
              </Button>
              <MoveData id={documentData?.bta_document_id} disabled={disabled} />
              <SendMail />
            </ModalProvider>
          </Col> */}

          <Col lg={24} md={24} sm={24}>
            <Table
              // files={{ url: "/incoming/includedFiles?btaDocId=", options: { method: "get", cancelTokenKey: "includedFiles" } }}
              state={this.state}
              // path="/incoming/table"
              fetchData={this.fetchData}
              changeDocumentData={this.changeDocumentData}
              changeFilesData={this.changeFilesData}
            />
          </Col>
        </Row>
      </CabinetLayout>
    );
  }
}

function mapStateToProps(state) {
  return {
    lang: state.lang.value,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: (language) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
    raiseMessage: (message) =>
      dispatch({ type: Actions.RAISE_MESSAGE, message }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CabinetTasks);
