import { Component } from "react";
import { connect } from "react-redux";
import { Col, Row, Button } from "antd";
import moment from "moment";
import "moment/locale/uk";
import locale from "antd/es/locale/uk_UA";

import CabinetLayout from "../../HOC/CabinetLayout";
import User from "../../Services/user";
import * as Actions from "../../Store/actions";
import { translate } from "../../Translation";
import { request } from "../../Services";
import styles from "./styles.module.scss";
import SignWidget from "../../Components/UI/SignWidget";

class CabinetOthers extends Component {
  intl = (selector) => {
    return translate(selector, this.props.lang);
  };

  render() {
    return (
      <CabinetLayout activeLink={this.props.match.path} {...this.props}>
        <Row>
          <Col lg={24} md={24} sm={24}>
            <SignWidget />
          </Col>
        </Row>
        <Row>
          <Col lg={24} md={24} sm={24}>
            <Button
              onClick={() => {
                window.history.back();
                return false;
              }}
            >
              {this.intl("return")}
            </Button>
          </Col>
        </Row>
      </CabinetLayout>
    );
  }
}

function mapStateToProps(state) {
  return {
    lang: state.lang.value,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: (language) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
    raiseMessage: (message) =>
      dispatch({ type: Actions.RAISE_MESSAGE, message }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CabinetOthers);
