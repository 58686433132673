import { useState, useContext, useEffect } from "react";
import { Modal, Button } from "antd";
import { ModalContext } from "../../../Contexts/modal.context";
import styles from "./styles.module.css";

export default function ModalComponent(props) {
  const [isModalVisible, setModalVisible] = useState(false);
  const modalContext = useContext(ModalContext);
  const style = props?.style ||
    modalContext.style || { marginLeft: "1rem", marginTop: "2rem" };
  const width = props?.width || modalContext.width || 1500;
  const footer = props?.footer || modalContext.footer || null;
  const isModalActive = modalContext.isActive(props.name);

  useEffect(() => {
    // handleCancel();
    setModalVisible(isModalActive);
  }, [/* props.visibleModal */ isModalActive]);

  const showModal = () => {
    setModalVisible(true);
    props.connectMail?.();
  };

  const handleOk = () => {
    setModalVisible(false);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  return (
    <>
      <Button
        className={styles["modal-button"]}
        type="primary"
        onClick={/* showModal */ () => modalContext.open(props.name)}
        style={style}
        disabled={props.disabled}
      >
        {props.name}
      </Button>
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={/* handleCancel */ () => modalContext.close(props.name)}
        width={width}
        footer={footer}
        destroyOnClose={true}
      >
        {props.children}
      </Modal>
    </>
  );
}
