import { Storage } from "./storage";

export const availableLanguages = [
  { key: 0, title: "Українська", code: "uk" },
  { key: 1, title: "English", code: "en" },
];

/**
 * @param {string} selector
 * @param {string} language
 * @param {...string} replacements
 * @returns {string | null}
 */
export function translate(selector, language, ...replacements) {
  const targetLanguage = language || getLanguage();
  const object = Storage[selector];
  if (!object) return null;

  let text = object[targetLanguage];
  if (!text) return null;

  if (replacements) {
    for (let index = 0; index < replacements.length; index++) {
      text = text.replace(`\$_PARAM_${index + 1}`, replacements[index]);
    }
  }

  return text;
}

/**
 * @param {string} date
 * @param {string} language
 * @returns {string}
 */
export function translateDate(date, language) {
  const dateInstance = new Date(date);

  switch (language) {
    case "en":
      return dateInstance.toLocaleDateString("en-US");
    default:
      return dateInstance.toLocaleDateString("uk");
  }
}

export function getLanguage() {
  let language = localStorage.getItem("language");
  if (!language) {
    language = navigator.language || navigator.userLanguage;
  }
  if (language.includes("en")) return "en";
  return "uk";
}

export function translateDictionary(str, eng) {
  const uk = {
    а: "a",
    б: "b",
    в: "v",
    г: "g",
    д: "d",
    е: "e",
    ё: "e",
    ж: "j",
    з: "z",
    и: "i",
    і: "i",
    к: "k",
    л: "l",
    м: "m",
    н: "n",
    о: "o",
    п: "p",
    р: "r",
    с: "s",
    т: "t",
    у: "u",
    ф: "f",
    х: "h",
    ц: "c",
    ч: "ch",
    ш: "sh",
    щ: "shch",
    ы: "y",
    э: "e",
    ю: "iu",
    я: "ya",
    А: "A",
    Б: "B",
    В: "V",
    Г: "G",
    Д: "D",
    Е: "E",
    Ё: "E",
    Ж: "J",
    З: "Z",
    И: "I",
    І: "I",
    К: "K",
    Л: "L",
    М: "M",
    Н: "N",
    О: "O",
    П: "P",
    Р: "R",
    С: "S",
    Т: "T",
    У: "U",
    Ф: "F",
    Х: "H",
    Ц: "C",
    Ч: "Ch",
    Ш: "Sh",
    Щ: "Shch",
    Ы: "Y",
    Э: "E",
    Ю: "Iu",
    Я: "Ya",
    " ": "-",
  };
  const en = {
    a: "а",
    b: "б",
    v: "в",
    g: "г",
    d: "д",
    e: "е",
    e: "ё",
    j: "ж",
    z: "з",
    i: "и",
    i: "і",
    k: "к",
    l: "л",
    m: "м",
    n: "н",
    o: "о",
    p: "п",
    r: "р",
    s: "с",
    t: "т",
    u: "у",
    f: "ф",
    h: "х",
    c: "ц",
    ch: "ч",
    sh: "ш",
    shch: "щ",
    y: "ы",
    e: "э",
    iu: "ю",
    ya: "я",
    A: "А",
    B: "Б",
    V: "В",
    G: "Г",
    D: "Д",
    E: "Е",
    E: "Ё",
    J: "Ж",
    Z: "З",
    I: "И",
    I: "І",
    K: "К",
    L: "Л",
    M: "М",
    N: "Н",
    O: "О",
    P: "П",
    R: "Р",
    S: "С",
    T: "Т",
    U: "У",
    F: "Ф",
    H: "Х",
    C: "Ц",
    Ch: "Ч",
    Sh: "Ш",
    Shch: "Щ",
    Y: "Ы",
    E: "Э",
    Iu: "Ю",
    Ya: "Я",
    "-": " ",
  };
  let n_str = [];
  // str = str.replace(/[ъь]+/g, '').replace(/й/g, 'i');
  str = str
    .replace(/shch+/g, "щ")
    .replace(/ch+/g, "ч")
    .replace(/sh+/g, "ш")
    .replace(/iu+/g, "я")
    .replace(/ya+/g, "я");

  for (var i = 0; i < str.length; ++i) {
    eng
      ? n_str.push(
          en[str[i]] ||
            (en[str[i].toLowerCase()] == undefined && str[i]) ||
            en[str[i].toLowerCase()].toUpperCase()
        )
      : n_str.push(
          uk[str[i]] ||
            (uk[str[i].toLowerCase()] == undefined && str[i]) ||
            uk[str[i].toLowerCase()].toUpperCase()
        );
  }

  return n_str.join("");
}
