import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Col,
  Row,
  DatePicker,
  ConfigProvider,
  Space,
  Button,
  Select,
  Dropdown,
  Menu,
  message,
} from "antd";
import { MoreOutlined } from "@ant-design/icons";
import moment from "moment";
import "moment/locale/uk";
import locale from "antd/es/locale/uk_UA";

import CabinetLayout from "../../HOC/CabinetLayout";
import User from "../../Services/user";
import * as Actions from "../../Store/actions";
import { translate } from "../../Translation";
import { request } from "../../Services";
import styles from "./styles.module.scss";
import { ModalProvider } from "../../Contexts/modal.context";
import Modal from "../../Components/UI/Modal";
import Form from "../../Containers/Cabinet/CitizensAppeal";
import Table from "../../Components/UI/Table";
import PopconfirmComponent from "../../Components/UI/Popconfirm";

const { Option } = Select;

class CabinetCitizensAppeal extends Component {
  state = {
    from: null,
    to: null,
    data: [],
    dataList: [],
    id: null,
    passingDocuments: [],
    registryDocuments: [],
    relationDocuments: [],
    includedFiles: { entities: [] },
    pagination: {
      current: 1,
      pageSize: 10,
      position: ["bottomCenter"],
      showSizeChanger: true,
    },
    documentData: [],
    loading: false,
    disabled: true,
    disabledAdd: true,
  };

  componentDidMount() {
    const currentUser = User.decode();
    if (!currentUser) return this.props.history.replace("/auth");

    this.fetchlistCivil();
    this.onDataChangeFrom(moment(Date.now()).startOf("week"));
    this.onDataChangeTo(moment(Date.now()));
  }

  intl = (selector) => {
    return translate(selector, this.props.lang);
  };

  fetchTableData = () => {
    this.fetchData("/citizens-appeal/cards", {
      method: "post",
      body: this.state,
      cancelTokenKey: "table",
    });
  };

  fetchlistCivil = () => {
    const currentUser = User.decode();
    if (!currentUser) return null;

    this.setState({ loading: true }, async () => {
      const data = await request("/citizens-appeal/list", {
        method: "post",
        cancelTokenKey: "list",
      });
      this.setState({ loading: false }, () => {
        if (data) {
          this.setState({ dataList: data.content });
        } else {
          this.setState({ dataList: [] });
        }
      });
    });
  };

  fetchData = (url, options) => {
    this.setState({ loading: true }, async () => {
      const data = await request(url, options);
      this.setState({ loading: false }, () => {
        if (data) {
          this.setState({
            data: data.content,
            pagination: {
              ...options.body.pagination,
              total: data.info.total,
            },
          });
        } else {
          this.setState({
            data: [],
            pagination: {
              ...options.body.pagination,
              total: 0,
            },
          });
        }
      });
    });
  };

  onDataChangeFrom = (data) => {
    this.setState({ from: moment(data, "DD-MM-YYYY").startOf("date") }, () => {
      if (this.state.from && this.state.to) {
        this.fetchData("/citizens-appeal/cards", {
          method: "post",
          body: this.state,
          cancelTokenKey: "cards",
        });
      }
    });
  };

  onDataChangeTo = (data) => {
    this.setState({ to: moment(data, "DD-MM-YYYY").endOf("date") }, () => {
      if (this.state.from && this.state.to) {
        this.fetchData("/citizens-appeal/cards", {
          method: "post",
          body: this.state,
          cancelTokenKey: "cards",
        });
      }
    });
  };

  onListIdChange = (data) => {
    this.setState({ id: data.key, disabledAdd: false }, () => {
      if (this.state.id) {
        this.fetchData("/citizens-appeal/cards", {
          method: "post",
          body: this.state,
          cancelTokenKey: "cards",
        });
      }
    });
  };

  onDeleteRow = async () => {
    const { documentData } = this.state;
    const result = await request("/incoming/deletedocuments", {
      method: "post",
      body: { id: documentData?.id },
      cancelTokenKey: "deleteDocument",
    });
    result === null && this.messageInfo(this.intl("remove-impossible"));
    this.fetchTableData();
  };

  messageInfo = (text) => {
    message.info(text);
  };

  changeDocumentData = (record) => {
    this.setState({ documentData: record, disabled: false });
  };

  changeFilesData = (name, data) => {
    this.setState((prevState) => ({ ...prevState, [name]: data }));
  };

  render() {
    const {
      dataList,
      loading,
      documentData,
      passingDocuments,
      registryDocuments,
      relationDocuments,
      includedFiles,
      disabled,
      disabledAdd,
      id,
    } = this.state;

    const menu = (
      <Menu>
        <Menu.Item>
          <Button className={styles["view-button"]} disabled={disabled}>
            <Link
              to={{
                pathname: "/cabinet/citizensappealview",
                state: {
                  type: "citizensappeal",
                  loading: loading,
                  data: documentData,
                  passing: passingDocuments,
                  registry: registryDocuments,
                  relation: relationDocuments,
                  files: includedFiles,
                },
              }}
            >
              {this.intl("view")}
            </Link>
          </Button>
        </Menu.Item>
        <Menu.Item>
          <PopconfirmComponent
            intl={this.intl}
            onDeleteRow={this.onDeleteRow}
            disabled={disabled}
          />
        </Menu.Item>
      </Menu>
    );

    return (
      <CabinetLayout activeLink={this.props.match.path} {...this.props}>
        <Row>
          <Col lg={24} md={24} sm={24}>
            <Space direction="vertical" size={12}>
              <ConfigProvider locale={locale}>
                <DatePicker
                  defaultValue={moment(Date.now()).startOf("week")}
                  format={["DD.MM.YYYY", "DD-MM-YYYY"]}
                  picker="date"
                  onChange={(_, dates) => this.onDataChangeFrom(dates)}
                  style={{ marginLeft: "1rem" }}
                />
                <DatePicker
                  defaultValue={moment(Date.now())}
                  format={["DD.MM.YYYY", "DD-MM-YYYY"]}
                  picker="date"
                  onChange={(_, dates) => this.onDataChangeTo(dates)}
                  style={{ marginLeft: "1rem" }}
                />
              </ConfigProvider>
            </Space>
            <Select
              /*defaultValue={data[0].name_question}*/ style={{
                width: 520,
                marginLeft: "1rem",
              }}
              onChange={(_, data) => this.onListIdChange(data)}
            >
              {dataList
                ? dataList.map((item) => (
                    <Option key={item.id} value={item.name_form}>
                      {item.name_form}
                    </Option>
                  ))
                : null}
            </Select>
          </Col>

          <Col lg={24} md={24} sm={24}>
            <ModalProvider>
              <Modal name={this.intl("add")} disabled={disabledAdd}>
                <Form
                  name={this.intl("add")}
                  id={id}
                  fetchTableData={this.fetchTableData}
                  add={true}
                />
              </Modal>
              <Modal name={this.intl("edit")} disabled={disabled}>
                <Form
                  name={this.intl("edit")}
                  id={id}
                  includedFiles={includedFiles}
                  documentData={documentData}
                  fetchTableData={this.fetchTableData}
                />
              </Modal>
              {/* <Modal name={this.intl("view")}>
                                <Form includedFiles={includedFiles} documentData={documentData} isEdit={true} />
                            </Modal> */}
              <Dropdown
                overlay={menu}
                trigger={["click"]}
                placement="bottomRight"
                className={styles["dropdown-buttons"]}
              >
                <Button>
                  <MoreOutlined style={{ color: "#000" }} />
                </Button>
              </Dropdown>
            </ModalProvider>
          </Col>

          <Col lg={24} md={24} sm={24}>
            <Table
              registry={{
                url: "/incoming/documentRegistry?btaDocId=",
                options: { method: "get", cancelTokenKey: "documentRegistry" },
              }}
              files={{
                url: "/incoming/includedFiles?btaDocId=",
                options: { method: "get", cancelTokenKey: "includedFiles" },
              }}
              passing={{
                url: "/incoming/documentPassing?btaDocId=",
                options: { method: "get", cancelTokenKey: "documentPassing" },
              }}
              relation={{
                url: "/incoming/relationDocuments?btaDocId=",
                options: { method: "get", cancelTokenKey: "relationDocuments" },
              }}
              state={this.state}
              path="/citizens-appeal/cards"
              fetchData={this.fetchData}
              changeDocumentData={this.changeDocumentData}
              changeFilesData={this.changeFilesData}
            />
          </Col>
        </Row>
      </CabinetLayout>
    );
  }
}

function mapStateToProps(state) {
  return {
    lang: state.lang.value,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: (language) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
    raiseMessage: (message) =>
      dispatch({ type: Actions.RAISE_MESSAGE, message }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CabinetCitizensAppeal);
