import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, Row, Col, Modal, Form, Space, Input } from "antd";

import * as Actions from "../../../Store/actions";
import { translate } from "../../../Translation";
import { request } from "../../../Services";
import styles from "./styles.module.css";

function ModalServersSettings(props) {
  const [visible, setVisible] = useState(false);
  const { name, selectData, disabled, flag, fetchTableData } = props;

  const intl = (selector) => {
    return translate(selector, props.lang);
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFormFinish = async (values) => {
    console.log("values: ", values);
    flag
      ? await request("/administration/insertservers", {
          method: "post",
          body: values,
          cancelTokenKey: "insertservers",
        })
      : await request("/administration/updateservers", {
          method: "post",
          body: { ...values, id: selectData?.id },
          cancelTokenKey: "updateservers",
        });
    setVisible(false);
    fetchTableData();
  };

  const onFormFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Button
        className={styles["modal-button"]}
        onClick={showModal}
        disabled={disabled}
      >
        {name}
      </Button>
      <Modal
        width={400}
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose={true}
      >
        <Row>
          <Col lg={24} md={24} sm={24} style={{ padding: "0 1rem" }}>
            <Form
              layout="vertical"
              onFinish={onFormFinish}
              onFinishFailed={onFormFailed}
              initialValues={{
                serverName: selectData?.ServerName,
                serverCode: selectData?.ServerCode,
                imapHost: selectData?.Imap_host,
                imapPort: selectData?.Imap_port,
              }}
            >
              <Row>
                <Col lg={24} md={24} sm={24}>
                  <Form.Item
                    name="serverName"
                    label={intl("server-name")}
                    rules={[
                      {
                        required: true,
                        message: intl("server-name-required"),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col lg={24} md={24} sm={24}>
                  <Form.Item
                    name="serverCode"
                    label={intl("server-code")}
                    rules={[
                      {
                        required: true,
                        message: intl("server-code-required"),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col lg={24} md={24} sm={24}>
                  <Form.Item
                    name="imapHost"
                    label={intl("imap-host")}
                    rules={[
                      {
                        required: true,
                        message: intl("imap-host-required"),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col lg={24} md={24} sm={24}>
                  <Form.Item
                    name="imapPort"
                    label={intl("imap-port")}
                    rules={[
                      {
                        required: true,
                        message: intl("imap-port-required"),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item>
                <Space style={{ position: "absolute", right: 0 }}>
                  <Button onClick={handleCancel}>{intl("cancel")}</Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className={styles["modal-button"]}
                  >
                    {intl("ok")}
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

function mapStateToProps(state) {
  return {
    lang: state.lang.value,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: (language) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalServersSettings);
