import { Checkbox } from "antd";

export function typesDocumentsColumns(intl = () => String()) {
  return [
    {
      title: intl("type-of-document"),
      children: [
        {
          title: intl("by-order"),
          dataIndex: "firstName",
          key: "firstName",
          align: "center",
          width: 60,
        },
        {
          title: intl("code"),
          dataIndex: "Code_TypeDoc",
          key: "Code_TypeDoc",
          align: "center",
          ellipsis: true,
          width: 60,
        },
        {
          title: intl("type"),
          dataIndex: "Name_TypeDoc",
          key: "Name_TypeDoc",
          ellipsis: true,
        },
        {
          title: intl("belongs-to-category"),
          dataIndex: "ConstTypeDocName",
          key: "ConstTypeDocName",
          ellipsis: true,
        },
        {
          title: intl("category"),
          dataIndex: "name_KategTypeDoc",
          key: "name_KategTypeDoc",
          ellipsis: true,
        },
        {
          title: intl("display-toolbar-menu"),
          dataIndex: "IsAddPopupMenu",
          key: "IsAddPopupMenu",
          align: "center",
          render: (_, record) => (
            <Checkbox defaultChecked={!!record.IsAddPopupMenu} />
          ),
        },
      ],
    },
    {
      title: intl("parameters-default"),
      children: [
        {
          title: intl("type-of-connection"),
          dataIndex: "name_TypeDoc_relation",
          key: "name_TypeDoc_relation",
          ellipsis: true,
        },
        {
          title: intl("correspondent"),
          dataIndex: "name_firm_plat",
          key: "name_firm_plat",
          ellipsis: true,
        },
        {
          title: intl("place-of-registration"),
          dataIndex: "name_otdel",
          key: "name_otdel",
          ellipsis: true,
        },
      ],
    },
    {
      title: intl("additionally"),
      children: [
        {
          title: intl("notes"),
          dataIndex: "Prim_TypeDoc",
          key: "Prim_TypeDoc",
          ellipsis: true,
        },
        {
          title: intl("user"),
          dataIndex: "name_user",
          key: "name_user",
          ellipsis: true,
        },
        {
          title: intl("create-draft-document"),
          dataIndex: "isCreateProject",
          key: "isCreateProject",
          ellipsis: true,
        },
      ],
    },
  ];
}

export function nomenclatureDocumentsColumns(intl = () => String()) {
  return [
    {
      title: intl("valid"),
      dataIndex: "isActive",
      key: "isActive",
      align: "center",
      render: (_, record) => <Checkbox defaultChecked={!!record?.isActive} />,
    },
    {
      title: intl("structural-unit"),
      dataIndex: "NAME_OTDEL",
      key: "NAME_OTDEL",
      ellipsis: true,
    },
    {
      title: "№",
      dataIndex: "ReestrNum",
      key: "ReestrNum",
    },
    {
      title: intl("year"),
      dataIndex: "ReestrDate",
      key: "ReestrDate",
    },
    {
      title: intl("date-of-approval"),
      dataIndex: "ApprovalDate",
      key: "ApprovalDate",
    },
    {
      title: intl("document"),
      dataIndex: "ApprovalDoc",
      key: "ApprovalDoc",
      ellipsis: true,
    },
    {
      title: intl("notes"),
      dataIndex: "ApprovalDescription",
      key: "ApprovalDescription",
      ellipsis: true,
    },
    {
      title: intl("add-num-nomenclature"),
      dataIndex: "isAddNum",
      key: "isAddNum",
      align: "center",
      render: (_, record) => <Checkbox defaultChecked={!!record?.isAddNum} />,
    },
    {
      title: intl("add-letter-c"),
      dataIndex: "isAddC",
      key: "isAddC",
      align: "center",
      render: (_, record) => <Checkbox defaultChecked={!!record?.isAddC} />,
    },
    {
      title: intl("general-notes"),
      dataIndex: "Description",
      key: "Description",
      ellipsis: true,
    },
    {
      title: intl("user"),
      dataIndex: "NAME",
      key: "NAME",
      ellipsis: true,
    },
  ];
}

export function nomenclatureDocumentsColumns2(intl = () => String()) {
  return [
    {
      title: intl("index"),
      dataIndex: "Code_OfficialDoc",
      key: "Code_OfficialDoc",
      ellipsis: true,
    },
    {
      title: intl("heading"),
      dataIndex: "Name_OfficialDoc",
      key: "Name_OfficialDoc",
      ellipsis: true,
    },
    {
      title: intl("shelf-life"),
      dataIndex: "LimitationText",
      key: "LimitationText",
      ellipsis: true,
    },
    {
      title: intl("notes"),
      dataIndex: "Prim_OfficialDoc",
      key: "Prim_OfficialDoc",
      ellipsis: true,
    },
    {
      title: intl("user"),
      dataIndex: "name_user",
      key: "name_user",
      ellipsis: true,
    },
  ];
}

export function employeeDirectoryColumns(intl = () => String()) {
  return [
    {
      title: intl("fio"),
      dataIndex: "fio",
      key: "fio",
      ellipsis: true,
    },
    {
      title: intl("for-envelopes"),
      dataIndex: "fio_dp",
      key: "fio_dp",
      ellipsis: true,
    },
    {
      title: intl("position"),
      dataIndex: "posada",
      key: "posada",
      ellipsis: true,
    },
    {
      title: intl("head"),
      dataIndex: "IS_KERIVNIK",
      key: "IS_KERIVNIK",
      align: "center",
      render: (_, record) => <Checkbox defaultChecked={!!record.IS_KERIVNIK} />,
    },
    {
      title: intl("right-control"),
      dataIndex: "IS_CONTROL",
      key: "IS_CONTROL",
      align: "center",
      render: (_, record) => <Checkbox defaultChecked={!!record.IS_CONTROL} />,
    },
    {
      title: intl("structural-unit"),
      dataIndex: "name_otdel",
      key: "name_otdel",
      ellipsis: true,
    },
    {
      title: intl("work-phone"),
      dataIndex: "WORK_TEL",
      key: "WORK_TEL",
      ellipsis: true,
    },
    {
      title: intl("fax"),
      dataIndex: "WORK_FAX",
      key: "WORK_FAX",
      ellipsis: true,
    },
    {
      title: intl("mobile-phone"),
      dataIndex: "MOBILE_TEL",
      key: "MOBILE_TEL",
      ellipsis: true,
    },
    {
      title: intl("email"),
      dataIndex: "WORK_E_MAIL",
      key: "WORK_E_MAIL",
      ellipsis: true,
    },
    {
      title: intl("no-cabinet"),
      dataIndex: "N_ROOM",
      key: "N_ROOM",
      ellipsis: true,
    },
    {
      title: intl("released"),
      dataIndex: "is_uvolen",
      key: "is_uvolen",
      align: "center",
      render: (_, record) => <Checkbox defaultChecked={!!record.is_uvolen} />,
    },
  ];
}

export function directoryPositionsColumns(intl = () => String()) {
  return [
    {
      title: intl("position"),
      children: [
        {
          title: intl("category"),
          dataIndex: "KATEGORIA",
          key: "KATEGORIA",
          ellipsis: true,
        },
        {
          title: intl("code-kp"),
          dataIndex: "Code",
          key: "Code",
          align: "center",
        },
        {
          title: intl("job-title"),
          dataIndex: "Name",
          key: "Name",
          ellipsis: true,
        },
        {
          title: intl("position"),
          dataIndex: "POSADA",
          key: "POSADA",
          ellipsis: true,
        },
      ],
    },
    {
      title: intl("other"),
      children: [
        {
          title: intl("description"),
          dataIndex: "OPISAN",
          key: "OPISAN",
          ellipsis: true,
        },
      ],
    },
  ];
}

export function departmentsColumns(intl = () => String()) {
  return [
    {
      title: intl("structural-unit"),
      children: [
        {
          title: intl("code"),
          dataIndex: "Kod",
          key: "Kod",
          align: "center",
        },
        {
          title: intl("type2"),
          dataIndex: "name_subdivision",
          key: "name_subdivision",
          ellipsis: true,
        },
        {
          title: intl("full-name"),
          dataIndex: "NAME_OTDEL",
          key: "NAME_OTDEL",
          ellipsis: true,
        },
        {
          title: intl("short-name"),
          dataIndex: "name_otdel_short",
          key: "name_otdel_short",
          align: "center",
        },
      ],
    },
    {
      title: intl("reorganization"),
      children: [
        {
          title: intl("reorganized-into"),
          dataIndex: "name_TypeDoc_relation",
          key: "name_TypeDoc_relation",
          ellipsis: true,
        },
      ],
    },
    {
      title: intl("other"),
      children: [
        {
          title: intl("liquidated"),
          dataIndex: "is_liquidated",
          key: "is_liquidated",
          align: "center",
          render: (_, record) => (
            <Checkbox defaultChecked={!!record.is_liquidated} />
          ),
        },
        {
          title: intl("notes"),
          dataIndex: "prim",
          key: "prim",
          ellipsis: true,
        },
      ],
    },
  ];
}

export function countriesDirecoryColumns(intl = () => String()) {
  return [
    {
      title: intl("basic-information"),
      children: [
        {
          title: intl("code"),
          dataIndex: "KOD_COUNTRY",
          key: "KOD_COUNTRY",
          align: "center",
        },
        {
          title: intl("short-name"),
          dataIndex: "country",
          key: "country",
          ellipsis: true,
        },
        {
          title: intl("official-name"),
          dataIndex: "NAZVA_COUNTRY",
          key: "NAZVA_COUNTRY",
          ellipsis: true,
        },
        {
          title: intl("type2"),
          dataIndex: "Name_TypeRegion",
          key: "Name_TypeRegion",
          ellipsis: true,
        },
        {
          title: intl("short-name-country"),
          dataIndex: "country_eng",
          key: "country_eng",
          ellipsis: true,
        },
      ],
    },
    {
      title: intl("code"),
      children: [
        {
          title: intl("digital"),
          dataIndex: "code_num",
          key: "code_num",
          ellipsis: true,
        },
        {
          title: `${intl("alpha")}-2`,
          dataIndex: "code_alfa_2",
          key: "code_alfa_2",
          ellipsis: true,
        },
        {
          title: `${intl("alpha")}-3`,
          dataIndex: "code_alfa_3",
          key: "code_alfa_3",
          ellipsis: true,
        },
      ],
    },
    {
      title: intl("user"),
      children: [
        {
          title: intl("login"),
          dataIndex: "user_name",
          key: "user_name",
          ellipsis: true,
        },
      ],
    },
  ];
}

export function correspondentsDirectoryColumns(intl = () => String()) {
  return [
    {
      title: intl("code"),
      dataIndex: "Code",
      key: "Code",
      align: "center",
      width: 80,
    },
    {
      title: intl("code-okpo"),
      dataIndex: "OKPO",
      key: "OKPO",
      align: "center",
      width: 100,
    },
    {
      title: intl("name"),
      dataIndex: "Name",
      key: "Name",
      ellipsis: true,
    },
    {
      title: intl("abbreviated"),
      dataIndex: "NFP",
      key: "NFP",
      ellipsis: true,
    },
    {
      title: intl("address"),
      dataIndex: "post_adres",
      key: "post_adres",
      ellipsis: true,
    },
    {
      title: intl("email"),
      dataIndex: "SET_ADRES",
      key: "SET_ADRES",
      ellipsis: true,
    },
    {
      title: intl("performer"),
      dataIndex: "IsExecuterDocFlow",
      key: "IsExecuterDocFlow",
      align: "center",
      width: 110,
      render: (_, record) => (
        <Checkbox defaultChecked={!!record.IsExecuterDocFlow} />
      ),
    },
  ];
}

export function actionsWithDocumentsColumns(intl = () => String()) {
  return [
    {
      title: intl("code"),
      dataIndex: "Code",
      key: "Code",
      align: "center",
    },
    {
      title: intl("action-stage"),
      dataIndex: "Name",
      key: "Name",
      align: "center",
      ellipsis: true,
    },
    {
      title: intl("notes"),
      dataIndex: "Notes",
      key: "Notes",
      ellipsis: true,
    },
    {
      title: intl("content-action-stage"),
      dataIndex: "content",
      key: "content",
      ellipsis: true,
    },
    {
      title: intl("control-action"),
      dataIndex: "isKontrol",
      key: "isKontrol",
      align: "center",
      render: (_, record) => (
        <Checkbox checked={!!record.isKontrol} />
      ),
    },
    {
      title: intl("track-the-deadline"),
      dataIndex: "isExec",
      key: "isExec",
      align: "center",
      render: (_, record) => (
        <Checkbox checked={!!record.isExec} />
      ),
    },
    {
      title: intl("set-date-execution"),
      dataIndex: "KolvoDayExec",
      key: "KolvoDayExec",
    },
    {
      title: intl("mandatory-entry-file"),
      dataIndex: "DoFile",
      key: "DoFile",
      align: "center",
      render: (_, record) => (
        <Checkbox checked={!!record.DoFile} />
      ),
    },
    {
      title: intl("fill-execution-date"),
      dataIndex: "AddDateKFactAfterGet",
      key: "AddDateKFactAfterGet",
      align: "center",
      render: (_, record) => (
        <Checkbox checked={!!record.AddDateKFactAfterGet} />
      ),
    },
    {
      title: intl("use-for-document-types"),
      dataIndex: "ConstTypeDocName",
      key: "ConstTypeDocName",
      ellipsis: true,
    },
    {
      title: intl("fill-by-default-for-document-types"),
      dataIndex: "ConstTypeDocNameDef",
      key: "ConstTypeDocNameDef",
      ellipsis: true,
    },
    {
      title: intl("fill-controlling-person"),
      dataIndex: "AddControllersFromInsertStep",
      key: "AddControllersFromInsertStep",
      align: "center",
      render: (_, record) => (
        <Checkbox checked={!!record.AddControllersFromInsertStep} />
      ),
    },
    {
      title: intl("enter-mailing-list-after-document-registration"),
      dataIndex: "AddToReestrSendAsDoc",
      key: "AddToReestrSendAsDoc",
      align: "center",
      render: (_, record) => (
        <Checkbox checked={!!record.AddToReestrSendAsDoc} />
      ),
    },
    {
      title: intl("allowed-edit-the-file"),
      dataIndex: "IsEditFile",
      key: "IsEditFile",
      align: "center",
      render: (_, record) => (
        <Checkbox checked={!!record.IsEditFile} />
      ),
    },
  ];
}
