import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import {
  Col,
  Row,
  Space,
  DatePicker,
  Table,
  Select,
  ConfigProvider,
  Modal,
  Button,
} from "antd";
import "moment/locale/uk";
import locale from "antd/es/locale/uk_UA";

import * as Actions from "../../../../Store/actions";
import { translate } from "../../../../Translation";
import { request } from "../../../../Services";
import Pie from "../../../../Components/Diagrams/Pie";
import styles from "./styles.module.css";

const { Option } = Select;

class ChartPerformers extends Component {
  state = {
    dateFrom: moment(Date.now()).startOf("month"),
    dateTo: moment(Date.now()),
    selectValue: null,
    id: null,
    data: [],
    dataExecutor: [],
    dataDetail: [],
    loading: false,
    visibleModal: false,
  };

  componentDidMount() {
    if (this.props.location.state !== undefined) {
      this.onChangeDateFrom(this.props.location.state.dateFrom);
      this.onChangeDateTo(this.props.location.state.dateTo);
      this.onSelectChange(this.props.location.state);
    } else {
      this.fetchSelectData();
    }
  }

  intl = (selector) => {
    return translate(selector, this.props.lang);
  };

  fetchSelectData = () => {
    this.setState({ loading: true }, async () => {
      const data = await request("/statistics/problems/performers", {
        method: "post",
        body: this.state,
        cancelTokenKey: "performers",
      });
      console.log("data: ", data);
      this.setState({ data: data?.content, loading: false });
    });
  };

  fetchCharts = () => {
    this.setState({ loading: true }, async () => {
      const dataExecutor = await request(
        "/statistics/problems/performers-executor",
        {
          method: "post",
          body: this.state,
          cancelTokenKey: "performers-executor",
        }
      );
      console.log("dataExecutor: ", dataExecutor);
      const dataDetail = await request(
        "/statistics/problems/performers-detail",
        {
          method: "post",
          body: this.state,
          cancelTokenKey: "performers-detail",
        }
      );
      console.log("dataDetail: ", dataDetail);
      this.setState({
        dataExecutor: dataExecutor?.content,
        dataDetail: dataDetail?.content,
        loading: false,
      });
    });
  };

  onChangeDateFrom = (date) => {
    this.setState(
      { dateFrom: moment(date, "DD-MM-YYYY").startOf("date") },
      () => {
        this.fetchSelectData();
        if (this.state.id !== null) {
          this.fetchCharts();
        }
      }
    );
  };

  onChangeDateTo = (date) => {
    this.setState({ dateTo: moment(date, "DD-MM-YYYY").endOf("date") }, () => {
      this.fetchSelectData();
      if (this.state.id !== null) {
        this.fetchCharts();
      }
    });
  };

  onSelectChange = (data) => {
    this.setState({ id: data.key, selectValue: data.value }, () => {
      this.fetchCharts();
    });
  };

  showModal = () => {
    this.setState({ visibleModal: true });
  };

  handleOk = () => {
    this.setState({ visibleModal: false });
  };

  exportModal = () => {
    const header =
      "<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
      "xmlns:w='urn:schemas-microsoft-com:office:word' " +
      "xmlns='http://www.w3.org/TR/REC-html40'>" +
      "<head><meta charset='utf-8'><title>Export HTML to Word Document with JavaScript</title></head><body>";
    const footer = "</body></html>";
    const sourceHTML = header + document.getElementById("1").innerHTML + footer;
    const source =
      "data:application/vnd.ms-word;charset=utf-8," +
      encodeURIComponent(sourceHTML);
    const fileDownload = document.createElement("a");
    document.body.appendChild(fileDownload);
    fileDownload.href = source;
    fileDownload.download = "document.doc";
    fileDownload.click();
    document.body.removeChild(fileDownload);
  };

  render() {
    const {
      dateFrom,
      dateTo,
      data,
      dataExecutor,
      dataDetail,
      id,
      selectValue,
    } = this.state;
    const { location } = this.props;

    const configModalTable = [
      {
        title: this.intl("index-of-treatment"),
        dataIndex: "Index_z",
        key: "Index_z",
        align: "center",
      },
      {
        title: this.intl("data-of-treatment"),
        dataIndex: "date_Z",
        key: "date_Z",
        align: "center",
      },
    ];

    return (
      <Row>
        <Col lg={24} md={24} sm={24}>
          <Space direction="vertical" size={12}>
            <ConfigProvider locale={locale}>
              {dateFrom ? (
                <DatePicker
                  defaultValue={
                    location.state
                      ? moment(location.state.dateFrom, "DD-MM-YYYY")
                      : dateFrom
                  }
                  format={"DD-MM-YYYY"}
                  picker="date"
                  onChange={(_, dates) => this.onChangeDateFrom(dates)}
                  style={{ marginLeft: "1rem" }}
                />
              ) : null}
              {dateTo ? (
                <DatePicker
                  defaultValue={
                    location.state
                      ? moment(location.state.dateTo, "DD-MM-YYYY")
                      : dateTo
                  }
                  format={"DD-MM-YYYY"}
                  picker="date"
                  onChange={(_, dates) => this.onChangeDateTo(dates)}
                  style={{ marginLeft: "1rem" }}
                />
              ) : null}
            </ConfigProvider>
          </Space>
        </Col>
        <Col lg={24} md={24} sm={24}>
          <Select
            showSearch
            defaultValue={
              location.state !== undefined
                ? location.state.value
                : data?.length > 0
                ? data[0].name_question
                : null
            }
            style={{ width: 520, marginLeft: "1rem", marginTop: "2rem" }}
            onChange={(_, data) => this.onSelectChange(data)}
          >
            {data?.length > 0
              ? data.map((item) => (
                  <Option
                    key={item.gta_questions_id}
                    value={item.name_question}
                  >
                    {item.name_question}
                  </Option>
                ))
              : null}
          </Select>

          <Button
            className={styles["button"]}
            type="primary"
            onClick={this.showModal}
            style={{ marginLeft: "2rem" }}
          >
            {this.intl("detail")}
          </Button>
          <Modal
            visible={this.state.visibleModal}
            onOk={this.handleOk}
            onCancel={this.handleOk}
            footer={[
              <Button
                className={styles["button"]}
                key="back"
                type="primary"
                onClick={this.exportModal}
                disabled={!dataDetail?.length > 0}
              >
                {this.intl("save")}
              </Button>,
            ]}
          >
            <Table
              id="1"
              pagination={false}
              columns={configModalTable}
              dataSource={dataDetail?.map((item) => ({
                ...item,
              }))}
            />
          </Modal>
        </Col>
        <Col lg={11} md={11} sm={24}>
          {dataExecutor?.length > 0 ? (
            <Pie
              data={dataExecutor}
              settingsConfig={{
                angleField: "count_z",
                colorField: "Executers",
                style: {
                  height: "500px",
                  // width: "800px",
                  marginTop: "7rem",
                },
              }}
            />
          ) : null}
        </Col>
        <Col lg={{ span: 11, offset: 2 }} md={{ span: 11, offset: 2 }} sm={24}>
          <div className={styles.NavbarChart} style={{ marginTop: "10rem" }}>
            <ul>
              {dataExecutor?.length > 0
                ? dataExecutor.map((item) => (
                    <li>
                      <Link
                        className={styles.Link}
                        to={{
                          pathname: "/statistics/problems/address",
                          state: {
                            value: item.Executers,
                            key: item.id_executors_id,
                            selectId: id,
                            selectValue: selectValue,
                            dateFrom: moment(dateFrom)._d,
                            dateTo: moment(dateTo)._d,
                          },
                        }}
                      >
                        {item.Executers + "    /   " + item.count_z}
                      </Link>
                    </li>
                  ))
                : null}
            </ul>
          </div>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    lang: state.lang.value,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: (language) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
    raiseMessage: (message) =>
      dispatch({ type: Actions.RAISE_MESSAGE, message }),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChartPerformers)
);
