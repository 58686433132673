import { useState } from "react";
import { connect } from "react-redux";
import { Button, Row, Col, Modal, Form, Space, Input, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import * as Actions from "../../../Store/actions";
import { translate } from "../../../Translation";
import { request } from "../../../Services";
import styles from "./styles.module.css";

function ModalAccessSettings(props) {
  const [visible, setVisible] = useState(false);
  const formData = new FormData();
  const { disabled } = props;

  const intl = (selector) => {
    return translate(selector, props.lang);
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFormFinish = async (values) => {
    console.log("Success:", values);
    formData.append("mailTo", values.mailTo);
    formData.append("mailSubject", values.mailSubject);
    formData.append("mailText", values.mailText);
    formData.append("mailAttachment", values.uploadFile?.file.originFileObj);

    await request("/mail/sendMail", {
      method: "post",
      body: formData,
      cancelTokenKey: "sendMail",
    });
    setVisible(false);
  };

  const onFormFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  function validator(subject, value) {
    return new Promise((resolve, reject) => {
      if (!value) {
        return reject(new Error(subject.message));
      }

      return resolve();
    });
  }

  const uploadProps = {
    name: "file",
    action: "http://localhost:3030/mail/sendMail",
    // action: "http://kdocument.tk:3030/mail/sendMail",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        console.log(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        console.log(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <>
      <Button
        className={styles["modal-button"]}
        onClick={showModal}
        disabled={disabled}
      >
        {intl("send-mail")}
      </Button>
      <Modal
        width={1200}
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose={true}
      >
        <Row>
          <Col lg={24} md={24} sm={24} style={{ padding: "0 1rem" }}>
            <Form onFinish={onFormFinish} onFinishFailed={onFormFailed}>
              <Form.Item
                label={intl("to")}
                name="mailTo"
                rules={[
                  {
                    required: true,
                    message: intl("to-required"),
                    validator: validator,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={intl("subject")}
                name="mailSubject"
                rules={[
                  {
                    required: true,
                    message: intl("subject-required"),
                    validator: validator,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item name="mailText">
                <Input.TextArea rows={10} />
              </Form.Item>
              <Form.Item name="uploadFile">
                <Upload {...uploadProps}>
                  <Button icon={<UploadOutlined />}>
                    {intl("select-file")}
                  </Button>
                </Upload>
              </Form.Item>

              <Form.Item>
                <Space style={{ position: "absolute", right: 0 }}>
                  <Button onClick={handleCancel}>{intl("cancel")}</Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className={styles["modal-button"]}
                  >
                    {intl("ok")}
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

function mapStateToProps(state) {
  return {
    lang: state.lang.value,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: (language) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalAccessSettings);
