import { useState, useContext } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Space,
  ConfigProvider,
  DatePicker,
  Upload,
  Table,
  Popconfirm,
  Checkbox,
  Tabs,
  Empty,
  Spin,
} from "antd";
import {
  MinusOutlined,
  PlusOutlined,
  UploadOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/uk";
import locale from "antd/lib/locale/uk_UA";

import * as Actions from "../../../Store/actions";
import { translate } from "../../../Translation";
import { environment } from "../../../Services/environment";
import Drawer from "../../../Components/UI/Drawer";
import { request } from "../../../Services";
import UIFile from "../../../Components/UI/File";
import { registryColumns, passingColumns, relationColumns } from "../utils";
import { ModalContext } from "../../../Contexts/modal.context";
import User from "../../../Services/user";
import styles from "./styles.module.scss";

const { TextArea } = Input;
const { TabPane } = Tabs;

function FormComponent(props) {
  const [form] = Form.useForm();
  const [tableData, setTableData] = useState([]);
  const [count, setCount] = useState(0);
  const [tableDateControl, setTableDateControl] = useState(null);
  const [tableAction, setTableAction] = useState({ id: null, name: null });
  const [summaryContent, setSummaryContent] = useState("");
  const {
    passing,
    registry,
    relation,
    includedFiles,
    documentData,
    isEdit,
    fileTabs,
    loading,
    onTableChange,
    name,
  } = props;
  const modalContext = useContext(ModalContext);
  const formData = new FormData();

  const intl = (selector) => {
    return translate(selector, props.lang);
  };

  const onFormFinish = async (values) => {
    console.log("Success:", values);

    formData.append("id", documentData?.bta_documents_id);
    formData.append("executorPost", documentData?.Executor_Post);
    formData.append("reestrNo", documentData?.Reestr_no);
    formData.append("fio", documentData?.Executors_FIO);
    formData.append("textResolution", values.textResolution);
    formData.append("author", values.author);
    formData.append(
      "dateResolution",
      values.dateResolution.format("DD-MM-YYYY")
    );
    formData.append("password", values.password);
    formData.append("signKey", values.signKey?.file.originFileObj);
    formData.append("certificate", values.certificate?.file.originFileObj);
    const currentUser = User.decode();

    await request("/resolutions/updateCards", {
      method: "post",
      body: values,
      cancelTokenKey: "updateCards",
    });
    await request("/resolutions/insertCards", {
      method: "post",
      body: { ...tableData, userId: currentUser?.id },
      cancelTokenKey: "insertCards",
    });
    // await request(`/resolutions/insertFiles`, { method: 'post', body: { id: documentData?.bta_documents_id, data: values, file: instance.url }, cancelTokenKey: 'insertFiles', headers: { "Content-Type": "multipart/form-data" } });
    await request("/resolutions/insertFiles", {
      method: "post",
      body: formData,
      cancelTokenKey: "insertFiles",
      headers: { "Content-Type": "multipart/form-data" },
    });
    modalContext.close(name);
    onTableChange();
  };

  const onFormFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  function validator(subject, value) {
    return new Promise((resolve, reject) => {
      if (!value) {
        return reject(new Error(subject.message));
      }

      return resolve();
    });
  }

  const onChangeDateControl = (date, index) => {
    setTableDateControl(date);
    setTableData((prevState) => {
      const newState = [...prevState];
      newState[index].dateControl = date;
      return newState;
    });
  };

  const onChangeCheckbox = (e, index) => {
    setTableData((prevState) => {
      const newState = [...prevState];
      newState[index].mainExecutor = e.target.checked;
      return newState;
    });
  };

  const onChangeDrawer = (data, key, index) => {
    setTableData((prevState) => {
      const newState = [...prevState];
      if (key === "executors") {
        newState[index].performerId = data.id;
        newState[index].performerName = data.name;
        newState[index].peformerType = data.type;
        return newState;
      } else {
        newState[index].action = data.id;
        return newState;
      }
    });
    form.setFieldsValue({
      textResolution: `${tableData.map((i) => i.performerName).join(", ")}  ${
        documentData?.text_rezolizii ?? summaryContent
      }`,
    });
  };

  const onTextResolutionChange = (e) => {
    setSummaryContent(e.target.value);
  };

  const handleAdd = () => {
    const newData = {
      key: count,
      performerId: null,
      performerName: null,
      peformerType: null,
      dateControl: tableDateControl,
      mainExecutor: false,
      action: tableAction.id,
      documentsId: documentData?.bta_documents_id,
      parentId: documentData?.id,
    };
    setTableData((prevState) => {
      return [...prevState, newData];
    });
    setCount(count + 1);
  };

  const handleDelete = (key) => {
    const dataSource = [...tableData];
    setTableData(dataSource.filter((item) => item.key !== key));
  };

  const uploadProps = {
    name: "file",
    action: `${environment.api}resolutions/insertFiles`,
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
    onChange(info) {
      if (info.file.status === "done") {
        // form.setFieldsValue({ signKey: info.file.originFileObj });
        // form.setFieldsValue({ certificate: info.file.originFileObj });
        console.log(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        console.log(`${info.file.name} file upload failed.`);
      }
    },
  };

  const columns = [
    {
      title: intl("performer"),
      dataIndex: "performer",
      key: "key",
      render: (_, record, index) => (
        <Drawer
          url="/resolutions/executors"
          options={{ method: "get", cancelTokenKey: "executors" }}
          setTableData={(data, type) =>
            onChangeDrawer(data, "executors", index)
          }
          isEdit={isEdit}
        />
      ),
    },
    {
      title: intl("date-control"),
      dataIndex: "dateControl",
      key: "key",
      render: (_, record, index) => (
        <DatePicker
          value={tableData[index].dateControl}
          format={"DD-MM-YYYY"}
          picker="date"
          onChange={(date) => onChangeDateControl(date, index)}
          disabled={isEdit}
        />
      ),
    },
    {
      title: intl("main-executor"),
      dataIndex: "mainExecutor",
      key: "key",
      render: (_, record, index) => (
        <Checkbox
          onChange={(e) => onChangeCheckbox(e, index)}
          disabled={isEdit}
        />
      ),
    },
    {
      title: intl("action"),
      dataIndex: "action",
      key: "key",
      render: (_, record, index) => (
        <Drawer
          url="/resolutions/actions"
          options={{ method: "get", cancelTokenKey: "actions" }}
          setTableData={(data) => onChangeDrawer(data, "actions", index)}
          documentName={tableAction.name}
          isEdit={isEdit}
        />
      ),
    },
    {
      title: intl("state"),
      dataIndex: "state",
      key: "key",
      render: (_, record) => (
        <Popconfirm
          title={intl("sure-delete")}
          onConfirm={() => handleDelete(record.key)}
        >
          <a>
            <MinusOutlined />
          </a>
        </Popconfirm>
      ),
    },
  ];

  const registryCol = registryColumns(intl);
  const passingCol = passingColumns(intl);
  const relationCol = relationColumns(intl);
  const dateFormat = "DD.MM.YYYY";

  return (
    <ConfigProvider locale={locale}>
      <Form
        className={styles["form"]}
        layout="vertical"
        form={form}
        onFinish={onFormFinish}
        onFinishFailed={onFormFailed}
        initialValues={{
          id: documentData?.id,
          summary: documentData?.text_rezolizii,
          dateResolution: moment(
            documentData?.dateK_fact ?? new Date(),
            dateFormat
          ),
        }}
      >
        <Row>
          <Col lg={12} md={12} sm={24}>
            <Form.Item name="id" hidden />
            <Form.Item
              label={intl("author")}
              name="author"
              rules={[
                {
                  required: true,
                  message: intl("author-required"),
                  validator: validator,
                },
              ]}
            >
              <Drawer
                url="/resolutions/clerks"
                options={{ method: "get", cancelTokenKey: "clerks" }}
                documentName={documentData?.Executors_FIO}
                documentId={documentData?.Executors_FIO_id}
                isEdit={isEdit}
              />
            </Form.Item>
            <Form.Item
              label={intl("summary")}
              name="summary"
              rules={[
                {
                  required: true,
                  message: intl("summary-receipt"),
                  validator: validator,
                },
              ]}
            >
              <TextArea
                rows={4}
                onChange={onTextResolutionChange}
                disabled={isEdit}
              />
            </Form.Item>
            <Form.Item label={intl("action")} name="action">
              <Drawer
                url="/resolutions/actions"
                options={{ method: "get", cancelTokenKey: "actions" }}
                setTableAction={setTableAction}
                isEdit={isEdit}
              />
            </Form.Item>
            <Row>
              <Col lg={6} md={6} sm={24}>
                <Form.Item
                  label={intl("date-resolution")}
                  name="dateResolution"
                  rules={[
                    {
                      required: true,
                      message: intl("date-resolution-required"),
                      validator: validator,
                    },
                  ]}
                >
                  <DatePicker format={dateFormat} disabled={isEdit} />
                </Form.Item>
              </Col>
              <Col
                lg={{ span: 11, offset: 1 }}
                md={{ span: 11, offset: 1 }}
                sm={24}
              >
                <Form.Item label={intl("date-control")} name="dateControl">
                  <DatePicker
                    format={dateFormat}
                    onChange={(date) => setTableDateControl(date)}
                    disabled={isEdit}
                    // placeholder={documentData?.dateK_fact === null && ""}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col
            lg={{ span: 11, offset: 1 }}
            md={{ span: 11, offset: 1 }}
            sm={24}
          >
            <Form.Item label={intl("text-resolution")} name="textResolution">
              <TextArea rows={8} disabled={isEdit} />
            </Form.Item>
          </Col>
        </Row>

        {!fileTabs ? (
          <>
            <Row>
              <Col lg={24} md={24} sm={24}>
                <Button
                  onClick={handleAdd}
                  type="primary"
                  style={{ float: "right" }}
                >
                  {" "}
                  <PlusOutlined />{" "}
                </Button>
                <Table
                  key={(record) => record.id}
                  dataSource={tableData}
                  columns={columns}
                />
              </Col>
            </Row>

            <Row style={{ marginTop: "1rem" }}>
              <Col lg={4} md={4} sm={24}>
                <Form.Item label={intl("electronic-signature")} name="signKey">
                  <Upload {...uploadProps}>
                    <Button icon={<UploadOutlined />}>
                      {intl("select-file")}
                    </Button>
                  </Upload>
                </Form.Item>
                <Form.Item label={intl("password")} name="password">
                  <Input.Password />
                </Form.Item>
              </Col>
              <Col
                lg={{ span: 4, offset: 1 }}
                md={{ span: 4, offset: 1 }}
                sm={24}
              >
                <Form.Item label={intl("certificate")} name="certificate">
                  <Upload {...uploadProps}>
                    <Button icon={<UploadOutlined />}>
                      {intl("select-file")}
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
          </>
        ) : null}

        <Row>
          <Col lg={24} md={24} sm={24}>
            {fileTabs ? (
              <Tabs defaultActiveKey="1">
                <TabPane
                  tab={intl("included-files")}
                  key="1"
                  disabled={includedFiles?.length === 0}
                >
                  {includedFiles?.length > 0 ? (
                    <div
                      style={{
                        padding: "1rem",
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      {includedFiles?.map((file) => {
                        return (
                          <UIFile
                            key={file.id}
                            /* onClick={() => fetchIncludedFile(file, includedFiles.id)} */ title={
                              file.filename
                            }
                            download
                            loading={loading}
                          />
                        );
                      })}
                    </div>
                  ) : (
                    <Empty description={intl("no-data")} />
                  )}
                </TabPane>
                <TabPane
                  tab={intl("control-terms")}
                  key="2"
                  disabled={registry?.length === 0}
                >
                  {registry?.length > 0 ? (
                    <Table
                      columns={registryCol}
                      pagination={false}
                      rowKey={(record) => record.id}
                      scroll={{ x: 1024 }}
                      locale={{ emptyText: intl("no-data") }}
                      loading={{
                        spinning: loading,
                        indicator: (
                          <Spin
                            indicator={
                              <LoadingOutlined style={{ fontSize: 24 }} spin />
                            }
                          />
                        ),
                      }}
                      dataSource={registry?.map((item) => ({
                        ...item,
                        key: item.id,
                      }))}
                    />
                  ) : (
                    <Empty description={intl("no-data")} />
                  )}
                </TabPane>
                <TabPane
                  tab={intl("passing-document")}
                  key="3"
                  disabled={passing?.length === 0}
                >
                  {passing?.length > 0 ? (
                    <Table
                      columns={passingCol}
                      pagination={false}
                      rowKey={(record) => record.id}
                      scroll={{ x: 1024 }}
                      locale={{ emptyText: intl("no-data") }}
                      loading={{
                        spinning: loading,
                        indicator: (
                          <Spin
                            indicator={
                              <LoadingOutlined style={{ fontSize: 24 }} spin />
                            }
                          />
                        ),
                      }}
                      dataSource={passing?.map((item) => ({
                        ...item,
                        key: item.id,
                      }))}
                    />
                  ) : (
                    <Empty description={intl("no-data")} />
                  )}
                </TabPane>
                <TabPane
                  tab={intl("linked-documents")}
                  key="4"
                  disabled={relation?.length === 0}
                >
                  {relation?.length > 0 ? (
                    <Table
                      columns={relationCol}
                      pagination={false}
                      rowKey={(record) => record.id}
                      scroll={{ x: 1024 }}
                      locale={{ emptyText: intl("no-data") }}
                      loading={{
                        spinning: loading,
                        indicator: (
                          <Spin
                            indicator={
                              <LoadingOutlined style={{ fontSize: 24 }} spin />
                            }
                          />
                        ),
                      }}
                      dataSource={relation?.map((item) => ({
                        ...item,
                        key: item.id,
                      }))}
                    />
                  ) : (
                    <Empty description={intl("no-data")} />
                  )}
                </TabPane>
              </Tabs>
            ) : null}
          </Col>
        </Row>

        <Form.Item>
          <Space style={{ position: "absolute", right: 0 }}>
            <Button
              onClick={() => {
                if (isEdit) {
                  window.history.back();
                  return false;
                } else {
                  modalContext.close(name);
                }
              }}
            >
              {intl("cancel")}
            </Button>
            {isEdit ? null : (
              <Button
                type="primary"
                htmlType="submit"
                className={styles["button"]}
              >
                {intl("ok")}
              </Button>
            )}
          </Space>
        </Form.Item>
      </Form>
    </ConfigProvider>
  );
}

function mapStateToProps(state) {
  return {
    lang: state.lang.value,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: (language) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
    raiseMessage: (message) =>
      dispatch({ type: Actions.RAISE_MESSAGE, message }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormComponent);
