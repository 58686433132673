import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Form, Input, Row, Col, Modal, Space, message } from "antd";

import * as Actions from "../../../Store/actions";
import { translate, translateDictionary } from "../../../Translation";
import { request } from "../../../Services";
import User from "../../../Services/user";
import styles from "./styles.module.css";

class ModalCreateMailFolder extends Component {
  formRef = React.createRef();
  state = {
    isModalVisible: false,
    disabledFlag: null,
  };

  intl = (selector) => {
    return translate(selector, this.props.lang);
  };

  showModal = () => {
    this.setState({ isModalVisible: true });
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  onFormFinish = async (values) => {
    console.log("values: ", values);
    const { id, roleId } = User.decode();
    const str = translateDictionary(values.nameFolder);

    const { content } = await request("/administration/getpages", {
      method: "get",
      cancelTokenKey: "getpages",
    });
    const item = content.find((i) => i.route === `/cabinet/${str}`);
    if (item) {
      this.messageInfo(this.intl("folder-already-created"));
    } else {
      await request("/profile/createmailfolder", {
        method: "post",
        body: { ...values, str: str, roleId: roleId, userId: id },
        cancelTokenKey: "createmailfolder",
      });
      this.setState({ isModalVisible: false });
      this.messageInfo(this.intl("alert-changes"));
    }
  };

  onFormFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  messageInfo = (text) => {
    message.info(text);
  };

  render() {
    const { isModalVisible } = this.state;
    return (
      <>
        <Button className={styles["modal-button"]} onClick={this.showModal}>
          {this.intl("create-folder")}
        </Button>
        <Modal
          width={800}
          visible={isModalVisible}
          onCancel={this.handleCancel}
          footer={null}
          destroyOnClose={true}
        >
          <Row>
            <Col lg={24} md={24} sm={24} style={{ padding: "0 1rem" }}>
              <Form
                ref={this.formRef}
                layout="vertical"
                onFinish={this.onFormFinish}
                onFinishFailed={this.onFormFailed}
              >
                <Row>
                  <Col lg={24} md={24} sm={24}>
                    <Form.Item
                      name="nameFolder"
                      label={this.intl("name-folder")}
                      rules={[
                        {
                          required: true,
                          message: this.intl("name-folder-required"),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item>
                  <Space style={{ position: "absolute", right: 0 }}>
                    <Button onClick={this.handleCancel}>
                      {this.intl("cancel")}
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className={styles["button"]}
                    >
                      {this.intl("ok")}
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    lang: state.lang.value,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: (language) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalCreateMailFolder);
