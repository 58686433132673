import { useContext } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Space,
  ConfigProvider,
  Checkbox,
  Typography,
  DatePicker,
} from "antd";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/uk";
import locale from "antd/lib/locale/uk_UA";

import * as Actions from "../../../Store/actions";
import { translate } from "../../../Translation";
import Drawer from "../../../Components/UI/Drawer";
import { request } from "../../../Services";
import { ModalContext } from "../../../Contexts/modal.context";
import User from "../../../Services/user";
import styles from "./styles.module.scss";

const { Title } = Typography;
const { TextArea } = Input;

function FormComponent(props) {
  const [form] = Form.useForm();
  const dateFormat = "DD.MM.YYYY";
  const { userId, rowData, rowId, fetchTableData, pathname, name } = props;
  const modalContext = useContext(ModalContext);

  const intl = (selector) => {
    return translate(selector, props.lang);
  };

  const onFormFinish = async (values) => {
    console.log("Success:", values);
    const currentUser = User.decode();

    rowId === 0
      ? await request(`${pathname}/adddata`, {
          method: "post",
          body: { ...values, userId: currentUser?.id },
          cancelTokenKey: "adddata",
        })
      : await request(`${pathname}/updatedata`, {
          method: "post",
          body: { ...values, id: rowId, userId: userId },
          cancelTokenKey: "updatedata",
        });
    modalContext.close(name);
    fetchTableData();
  };

  const onFormFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  function validator(subject, value) {
    return new Promise((resolve, reject) => {
      if (!value) {
        return reject(new Error(subject.message));
      }

      return resolve();
    });
  }

  return (
    <ConfigProvider locale={locale}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFormFinish}
        onFinishFailed={onFormFailed}
        initialValues={{
          numNomenclature: rowData?.ReestrNum,
          year: rowData?.ReestrDate,
          from: moment(rowData?.ApprovalDate ?? new Date(), dateFormat),
          valid: !!rowData?.isActive ?? false,
          document: rowData?.ApprovalDoc,
          approvalNotes: rowData?.ApprovalDescription,
          notes: rowData?.Description,
          addNum: !!rowData?.isAddNum ?? false,
          addC: !!rowData?.isAddC ?? false,
        }}
      >
        <Row>
          <Col lg={24} md={24} sm={24}>
            <Row>
              <Col
                lg={12}
                md={12}
                sm={24}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Form.Item
                  label={intl("nomenclature-no")}
                  name="numNomenclature"
                  rules={[
                    {
                      required: true,
                      message: intl("nomenclature-no-required"),
                      validator: validator,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={intl("year")}
                  name="year"
                  rules={[
                    {
                      required: true,
                      message: intl("year-required"),
                      validator: validator,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col
                lg={{ span: 11, offset: 1 }}
                md={{ span: 11, offset: 1 }}
                sm={24}
              >
                <Form.Item label={intl("structural-unit")} name="departments">
                  <Drawer
                    url="/directories/name-departments/getdata"
                    options={{ method: "get", cancelTokenKey: "departments" }}
                    documentName={rowData?.NAME_OTDEL}
                    documentId={rowData?.sta_otdel_id}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={24}>
                <Title level={4}>{intl("approved")}</Title>
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Form.Item label={intl("from")} name="from">
                    <DatePicker format={dateFormat} />
                  </Form.Item>
                  <Form.Item name="valid" valuePropName="checked">
                    <Checkbox>{intl("valid")}</Checkbox>
                  </Form.Item>
                </Row>
                <Form.Item label={intl("document")} name="document">
                  <Input />
                </Form.Item>
                <Form.Item name="approvalNotes">
                  <TextArea rows={4} />
                </Form.Item>
              </Col>
              <Col
                lg={{ span: 11, offset: 1 }}
                md={{ span: 11, offset: 1 }}
                sm={24}
              >
                <Title level={4}>{intl("notes")}</Title>
                <Form.Item name="notes">
                  <TextArea rows={12} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={24}>
                <Form.Item name="addNum" valuePropName="checked">
                  <Checkbox>{intl("add-num-nomenclature")}</Checkbox>
                </Form.Item>
              </Col>
              <Col
                lg={{ span: 11, offset: 1 }}
                md={{ span: 11, offset: 1 }}
                sm={24}
              >
                <Form.Item name="addC" valuePropName="checked">
                  <Checkbox>{intl("add-letter-c")}</Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Form.Item>
          <Space style={{ display: "flex", justifyContent: "end" }}>
            <Button onClick={() => modalContext.close(name)}>
              {intl("cancel")}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className={styles["button"]}
            >
              {intl("ok")}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </ConfigProvider>
  );
}

function mapStateToProps(state) {
  return {
    lang: state.lang.value,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: (language) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
    raiseMessage: (message) =>
      dispatch({ type: Actions.RAISE_MESSAGE, message }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormComponent);
