const apiUrlProduction = "https://k-doc-api.herokuapp.com/";
// const apiUrlDevelopment = "http://192.168.2.212:3030/";
const apiUrlDevelopment = "https://apidemokdocument.devrubu.com/";
// const url = "http://192.168.2.212:3000/";
const url = "https://demokdocument.devrubu.com/";
// const apiUrlWidget = "http://sign-widget-test.eu.iit.com.ua/";
const apiUrlWidget = "http://localhost:80/";

export const environment = {
  api:
    process.env.NODE_ENV === "production"
      ? apiUrlProduction
      : apiUrlDevelopment,
  url,
  apiWidget: apiUrlWidget,
  production: process.env.NODE_ENV === "production",
  development: process.env.NODE_ENV === "development",
};
