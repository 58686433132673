import { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Row, Typography, Button, Checkbox, Form, Input } from "antd";

import AuthLayout from "../HOC/AuthLayout";
import * as Actions from "../Store/actions";
import { translate } from "../Translation";
import { request } from "../Services";
import { generatePassword } from "../Components/Modals/CreateUsers/utils/generatePassword";
import styles from "./HomePage.module.scss";

const { Title, Text } = Typography;

function PasswordRecovery(props) {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const intl = (selector) => {
    return translate(selector, props.lang);
  };

  const onFinish = async (values) => {
    console.log("Success:", values);
    const newPassword = generatePassword();
    setLoading(true);
    await request("/mail/systemMail", {
      method: "post",
      body: { ...values, password: newPassword },
      cancelTokenKey: "systemMail",
    });
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <AuthLayout activeLink={props.match.path} {...props}>
      <Row>
        <Col lg={24} md={24} sm={24} style={{ marginBottom: "15px" }}>
          <Title level={4}>{intl("password-recovery")}</Title>
          <Text>{intl("sent-new-password")}</Text>
        </Col>
      </Row>
      <Row>
        <Col lg={24} md={24} sm={24}>
          <Form
            layout="vertical"
            className={styles["recovery-form"]}
            wrapperCol={{
              span: 16,
            }}
            initialValues={{
              confirm: false,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              className={styles["form-item"]}
              name="email"
              label={intl("login")}
              rules={[
                {
                  required: true,
                  message: intl("login-required"),
                },
                {
                  pattern:
                    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: intl("email-pattern"),
                },
              ]}
            >
              <Input type="email" className={styles["form-input"]} />
            </Form.Item>

            <Form.Item
              name="confirm"
              className={styles["remember-checkbox"]}
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) => (
                    // value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
                    value ? setDisabled(false) : setDisabled(true),
                    Promise.resolve()
                  ),
                },
              ]}
            >
              <Checkbox>{intl("confirm-password-change")}</Checkbox>
            </Form.Item>

            <Form.Item>
              <Button
                className={styles["recovery-button"]}
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={disabled}
              >
                {intl("change-password")}
              </Button>
              <Link to="/" className={styles["forgot-button"]}>
                {intl("return")}
              </Link>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </AuthLayout>
  );
}

function mapStateToProps(state) {
  return {
    lang: state.lang.value,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: (language) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
    raiseMessage: (message) =>
      dispatch({ type: Actions.RAISE_MESSAGE, message }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordRecovery);
