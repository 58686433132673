import React, { Component } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import User from "./Services/user";

import HomePage from "./Pages/HomePage";
import LoginPage from "./Pages/LoginPage";
import PasswordRecovery from "./Pages/PasswordRecovery";
import ProfilePage from "./Pages/ProfilePage";

import CabinetHome from "./Pages/Cabinet/CabinetHome";
import CabinetOutcoming from "./Pages/Cabinet/CabinetOutcoming";
import CabinetAdministrative from "./Pages/Cabinet/CabinetAdministrative";
import CabinetCitizensAppeal from "./Pages/Cabinet/CabinetCitizensAppeal";
import CabinetResolutions from "./Pages/Cabinet/CabinetResolutions";
import CabinetOthers from "./Pages/Cabinet/CabinetOthers";
import CabinetTasks from "./Pages/Cabinet/CabinetTasks";
import CabinetDynamicPage from "./Pages/Cabinet/CabinetDynamicPage";
import ViewPage from "./Pages/Cabinet/ViewPage";

import ReceiptsPage from "./Pages/Statistics/ReceiptsPage";
import ProblemsPage from "./Pages/Statistics/ProblemsPage";
import ProblemsPerformersPage from "./Pages/Statistics/ProblemsPages/PerformersPage";
import ProblemsAddressPage from "./Pages/Statistics/ProblemsPages/AddressPage";
import ProblemsDistrictPage from "./Pages/Statistics/ProblemsPages/DistrictPage";
import OtherQuestionsPage from "./Pages/Statistics/OtherQuestionsPage";
import OtherQuestionsPerformersPage from "./Pages/Statistics/OtherQuestionsPages/PerformersPage";
import OtherQuestionsAddressPage from "./Pages/Statistics/OtherQuestionsPages/AddressPage";
import OtherQuestionsDistrictPage from "./Pages/Statistics/OtherQuestionsPages/DistrictPage";
import ActivityPage from "./Pages/Statistics/ActivityPage";
import RatingManagersPage from "./Pages/Statistics/RatingManagersPage";
import RatingServiceProvidersPage from "./Pages/Statistics/RatingServiceProvidersPage";
import StructuralUnitsPage from "./Pages/Statistics/StructuralUnitsPage";

import TypesDocuments from "./Pages/Directories/TypesDocuments";
import CharacteristicsDocuments from "./Pages/Directories/CharacteristicsDocuments";
import DirectoryPositions from "./Pages/Directories/DirectoryPositions";
import Departments from "./Pages/Directories/Departments";
import EmployeeDirectory from "./Pages/Directories/EmployeeDirectory";
import CorrespondentsDirectory from "./Pages/Directories/CorrespondentsDirectory";
import CountriesDirectory from "./Pages/Directories/CountriesDirectory";
import KoatuuDirectory from "./Pages/Directories/KoatuuDirectory";
import NomenclatureDocuments from "./Pages/Directories/NomenclatureDocuments";
import ActionsWithDocuments from "./Pages/Directories/ActionsWithDocuments";

import UsersPage from "./Pages/Administration/UsersPage";
import AccessPages from "./Pages/Administration/AccessPages";
import AccessButtons from "./Pages/Administration/AccessButtons";
import ServersPage from "./Pages/Administration/ServersPage";

import PrivacyPolicy from "./Pages/Footer/PrivacyPolicy";
import TermsOfUse from "./Pages/Footer/TermsOfUse";
import Support from "./Pages/Footer/Support";

const routes = [
  {
    path: "/",
    component: HomePage,
    exact: true,
  },
  {
    path: "/auth",
    component: LoginPage,
    exact: true,
  },
  {
    path: "/password-recovery",
    component: PasswordRecovery,
    exact: true,
  },
  {
    path: "/cabinet/profile",
    component: ProfilePage,
    exact: true,
  },

  {
    path: "/cabinet",
    component: CabinetHome,
    exact: true,
  },
  {
    path: "/cabinet/outcoming",
    component: CabinetOutcoming,
    exact: true,
  },
  {
    path: "/cabinet/administrative",
    component: CabinetAdministrative,
    exact: true,
  },
  {
    path: "/cabinet/citizens-appeal",
    component: CabinetCitizensAppeal,
    exact: true,
  },
  {
    path: "/cabinet/resolutions",
    component: CabinetResolutions,
    exact: true,
  },
  {
    path: "/cabinet/others",
    component: CabinetOthers,
    exact: true,
  },
  {
    path: "/cabinet/tasks",
    component: CabinetTasks,
    exact: true,
  },
  {
    path: "/cabinet/incomingview",
    component: ViewPage,
    exact: true,
  },
  {
    path: "/cabinet/outcomingview",
    component: ViewPage,
    exact: true,
  },
  {
    path: "/cabinet/administrativeview",
    component: ViewPage,
    exact: true,
  },
  {
    path: "/cabinet/citizensappealview",
    component: ViewPage,
    exact: true,
  },
  {
    path: "/cabinet/resolutionsview",
    component: ViewPage,
    exact: true,
  },



  {
    path: "/statistics/receipts",
    component: ReceiptsPage,
    exact: true,
  },
  {
    path: "/statistics/problems",
    component: ProblemsPage,
    exact: true,
  },
  {
    path: "/statistics/problems/performers",
    component: ProblemsPerformersPage,
    exact: true,
  },
  {
    path: "/statistics/problems/address",
    component: ProblemsAddressPage,
    exact: true,
  },
  {
    path: "/statistics/problems/district",
    component: ProblemsDistrictPage,
    exact: true,
  },
  {
    path: "/statistics/other-questions",
    component: OtherQuestionsPage,
    exact: true,
  },
  {
    path: "/statistics/other-questions/performers",
    component: OtherQuestionsPerformersPage,
    exact: true,
  },
  {
    path: "/statistics/other-questions/address",
    component: OtherQuestionsAddressPage,
    exact: true,
  },
  {
    path: "/statistics/other-questions/district",
    component: OtherQuestionsDistrictPage,
    exact: true,
  },
  {
    path: "/statistics/activity",
    component: ActivityPage,
    exact: true,
  },
  {
    path: "/statistics/rating-managers",
    component: RatingManagersPage,
    exact: true,
  },
  {
    path: "/statistics/rating-service-providers",
    component: RatingServiceProvidersPage,
    exact: true,
  },
  {
    path: "/statistics/structural-units",
    component: StructuralUnitsPage,
    exact: true,
  },



  {
    path: "/directories/types-documents",
    component: TypesDocuments,
    exact: true,
  },
  {
    path: "/directories/categories-documents",
    component: CharacteristicsDocuments,
    exact: true,
  },
  {
    path: "/directories/sending-documents",
    component: CharacteristicsDocuments,
    exact: true,
  },
  {
    path: "/directories/relationships-documents",
    component: CharacteristicsDocuments,
    exact: true,
  },
  {
    path: "/directories/category-positions",
    component: CharacteristicsDocuments,
    exact: true,
  },
  {
    path: "/directories/job-title",
    component: CharacteristicsDocuments,
    exact: true,
  },
  {
    path: "/directories/directory-positions",
    component: DirectoryPositions,
    exact: true,
  },
  {
    path: "/directories/structural-unit",
    component: CharacteristicsDocuments,
    exact: true,
  },
  {
    path: "/directories/departments",
    component: Departments,
    exact: true,
  },
  {
    path: "/directories/employee-directory",
    component: EmployeeDirectory,
    exact: true,
  },
  {
    path: "/directories/groups-correspondents",
    component: CharacteristicsDocuments,
    exact: true,
  },
  {
    path: "/directories/indexes-correspondence",
    component: CharacteristicsDocuments,
    exact: true,
  },
  {
    path: "/directories/types-receipts",
    component: CharacteristicsDocuments,
    exact: true,
  },
  {
    path: "/directories/forms-receipts",
    component: CharacteristicsDocuments,
    exact: true,
  },
  {
    path: "/directories/correspondents-directory",
    component: CorrespondentsDirectory,
    exact: true,
  },
  {
    path: "/directories/types-toponyms-directory",
    component: CharacteristicsDocuments,
    exact: true,
  },
  {
    path: "/directories/countries-directory",
    component: CountriesDirectory,
    exact: true,
  },
  {
    path: "/directories/region-directory",
    component: KoatuuDirectory,
    exact: true,
  },
  {
    path: "/directories/town-directory",
    component: KoatuuDirectory,
    exact: true,
  },
  {
    path: "/directories/village-directory",
    component: KoatuuDirectory,
    exact: true,
  },
  {
    path: "/directories/district-directory",
    component: KoatuuDirectory,
    exact: true,
  },
  {
    path: "/directories/streets-directory",
    component: KoatuuDirectory,
    exact: true,
  },
  {
    path: "/directories/nomenclature",
    component: NomenclatureDocuments,
    exact: true,
  },
  {
    path: "/directories/types-appeals",
    component: CharacteristicsDocuments,
    exact: true,
  },
  {
    path: "/directories/types-of-appeals",
    component: CharacteristicsDocuments,
    exact: true,
  },
  {
    path: "/directories/signs-receipt",
    component: CharacteristicsDocuments,
    exact: true,
  },
  {
    path: "/directories/subjects-appeals",
    component: CharacteristicsDocuments,
    exact: true,
  },
  {
    path: "/directories/categories-authors-appeals",
    component: CharacteristicsDocuments,
    exact: true,
  },
  {
    path: "/directories/social-status-authors-appeals",
    component: CharacteristicsDocuments,
    exact: true,
  },
  {
    path: "/directories/groups-performers",
    component: CharacteristicsDocuments,
    exact: true,
  },
  {
    path: "/directories/departments-organizations",
    component: CharacteristicsDocuments,
    exact: true,
  },
  {
    path: "/directories/actions-documents",
    component: ActionsWithDocuments,
    exact: true,
  },


  {
    path: "/administration/users",
    component: UsersPage,
    exact: true,
  },
  {
    path: "/administration/access-pages",
    component: AccessPages,
    exact: true,
  },
  {
    path: "/administration/access-buttons",
    component: AccessButtons,
    exact: true,
  },
  {
    path: "/administration/servers",
    component: ServersPage,
    exact: true,
  },



  {
    path: "/footer/privacy-policy",
    component: PrivacyPolicy,
    exact: true,
  },
  {
    path: "/footer/terms-of-use",
    component: TermsOfUse,
    exact: true,
  },
  {
    path: "/footer/support",
    component: Support,
    exact: true,
  },

  {
    path: "/cabinet/:page",
    component: CabinetDynamicPage,
    exact: true,
  },
];

export default class Router extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          {routes.map((route) => (
            <Route key={route.path} {...route} />
          ))}
          {/* {User.decode().roleId == 3 ? <Redirect to="/charts/problems" /> : <Redirect to="/" />} */}
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
    );
  }
}
