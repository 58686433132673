import { useState, useContext, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Space,
  ConfigProvider,
  Drawer,
  Table,
} from "antd";
import { EllipsisOutlined, CloseOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import "moment/locale/uk";
import locale from "antd/lib/locale/uk_UA";

import * as Actions from "../../../Store/actions";
import { translate } from "../../../Translation";
import DrawerComponent from "../../../Components/UI/Drawer";
import { request } from "../../../Services";
import { ModalContext } from "../../../Contexts/modal.context";
import User from "../../../Services/user";
import styles from "./styles.module.scss";

const InputComponent = ({
  onChange,
  intl,
  url,
  options,
  setIdCountry,
  setIdRegion,
  setIdTown,
  setIdVillage,
  rowname,
  rowid,
}) => {
  const [visible, setVisible] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [name, setName] = useState(rowname ?? undefined);

  useEffect(() => {
    if (rowid) triggerChange(rowid);
  }, [rowid]);

  const showDrawer = () => {
    setVisible(true);
    (async () => {
      const data = await request(url, options);
      if (data) {
        setTableData(data.content);
      }
    })();
  };

  const closeDrawer = () => {
    setVisible(false);
  };

  const onClearContent = () => {
    triggerChange(undefined);
    setName(undefined);
    setIdCountry?.(undefined);
    setIdRegion?.(undefined);
    setIdTown?.(undefined);
    setIdVillage?.(undefined);
  };

  const onClickRow = (record) => {
    triggerChange(record.id);
    setName(record.Name);
    setIdCountry?.(record.id);
    setIdRegion?.(record.id);
    setIdTown?.(record.id);
    setIdVillage?.(record.id);
  };

  const triggerChange = (changedValue) => {
    onChange?.(changedValue);
  };

  const columns = [
    {
      dataIndex: "Name",
      key: "Name",
      align: "center",
    },
  ];

  return (
    <span style={{ display: "flex" }}>
      <Input value={name} />
      <Button icon={<EllipsisOutlined />} onClick={() => showDrawer()} />
      <Button icon={<CloseOutlined />} onClick={onClearContent} />
      <Drawer
        key="top"
        placement="top"
        onClose={closeDrawer}
        visible={visible}
        height="80%"
        destroyOnClose={true}
        footer={
          <div style={{ textAlign: "right" }}>
            <Button onClick={closeDrawer} style={{ marginRight: 8 }}>
              {intl("cancel")}
            </Button>
            <Button
              onClick={closeDrawer}
              type="primary" /* disabled={disabledBtn} */
            >
              {intl("ok")}
            </Button>
          </div>
        }
      >
        <Row>
          <Col lg={24} md={24} sm={24}>
            <Table
              dataSource={tableData}
              columns={columns}
              rowKey={(record) => record.id}
              onRow={(record) => {
                return {
                  onClick: () => onClickRow(record),
                  onDoubleClick: () => closeDrawer(),
                };
              }}
              /* rowClassName={setRowClassName} */
            />
          </Col>
        </Row>
      </Drawer>
    </span>
  );
};

function FormComponent(props) {
  const [form] = Form.useForm();
  const [idCountry, setIdCountry] = useState(undefined);
  const [idRegion, setIdRegion] = useState(undefined);
  const [idTown, setIdTown] = useState(undefined);
  const [idVillage, setIdVillage] = useState(undefined);
  const { rowData, rowId, fetchTableData, pathname, name } = props;
  const modalContext = useContext(ModalContext);

  const intl = (selector) => {
    return translate(selector, props.lang);
  };

  const onFormFinish = async (values) => {
    console.log("Success:", values);
    const currentUser = User.decode();

    rowId === 0
      ? await request(`${pathname}/adddata`, {
          method: "post",
          body: { ...values, userId: currentUser?.id },
          cancelTokenKey: "adddata",
        })
      : await request(`${pathname}/updatedata`, {
          method: "post",
          body: { ...values, id: rowId },
          cancelTokenKey: "updatedata",
        });
    modalContext.close(name);
    fetchTableData();
  };

  const onFormFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  function validator(subject, value) {
    return new Promise((resolve, reject) => {
      if (!value) {
        return reject(new Error(subject.message));
      }

      return resolve();
    });
  }

  return (
    <ConfigProvider locale={locale}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFormFinish}
        onFinishFailed={onFormFailed}
        initialValues={{
          streetName: rowData?.Name,
          previousStreetName: rowData?.OldName,
        }}
      >
        <Row>
          <Col lg={24} md={24} sm={24}>
            <Form.Item label={intl("country")} name="country">
              {/* <div style={{ display: "flex" }}>
                                <Input value={nameCountry} disabled={props.isEdit} />
                                <Button icon={<EllipsisOutlined />} onClick={() => showDrawer("/directories/countries-directory/getdata", { method: "get", cancelTokenKey: "country" })} disabled={props.isEdit} />
                                <Button icon={<CloseOutlined />} onClick={onClearContent} disabled={props.isEdit} />
                            </div> */}
              <InputComponent
                intl={intl}
                url="/directories/countries-directory/getdata"
                options={{ method: "get", cancelTokenKey: "country" }}
                setIdCountry={setIdCountry}
                rowname={rowData?.nazva_country}
                rowid={rowData?.CountryId}
              />
            </Form.Item>
            <Form.Item label={intl("administrative-unit-2")} name="adminUnit2">
              <InputComponent
                intl={intl}
                url="/directories/streetregion/getdata"
                options={{
                  method: "post",
                  body: { id: idCountry ?? rowData?.STA_OBLAST_ID },
                  cancelTokenKey: "region",
                }}
                setIdRegion={setIdRegion}
                rowname={rowData?.name_obl}
                rowid={rowData?.STA_OBLAST_ID}
              />
            </Form.Item>
            <Form.Item label={intl("administrative-unit-3")} name="adminUnit3">
              <InputComponent
                intl={intl}
                url="/directories/streettown/getdata"
                options={{
                  method: "post",
                  body: { id: idRegion ?? rowData?.ID_STA_TOWN },
                  cancelTokenKey: "town",
                }}
                setIdTown={setIdTown}
                rowname={rowData?.TOWN}
                rowid={rowData?.ID_STA_TOWN}
              />
            </Form.Item>
            <Form.Item label={intl("administrative-unit-4")} name="adminUnit4">
              <InputComponent
                intl={intl}
                url="/directories/streetvillage/getdata"
                options={{
                  method: "post",
                  body: { id: idTown ?? rowData?.STA_REGION_ID },
                  cancelTokenKey: "village",
                }}
                setIdVillage={setIdVillage}
                rowname={rowData?.name_region}
                rowid={rowData?.STA_REGION_ID}
              />
            </Form.Item>
            <Form.Item label={intl("administrative-unit-5")} name="adminUnit5">
              <InputComponent
                intl={intl}
                url="/directories/streetdistrict/getdata"
                options={{
                  method: "post",
                  body: { id: idVillage ?? rowData?.STA_VILLAGE_ID },
                  cancelTokenKey: "district",
                }}
                rowname={rowData?.village}
                rowid={rowData?.STA_VILLAGE_ID}
              />
            </Form.Item>
            <Row>
              <Col lg={12} md={12} sm={24}>
                <Form.Item label={intl("type2")} name="type">
                  <DrawerComponent
                    url="/directories/types-toponyms-directory/getdata"
                    options={{ method: "get", cancelTokenKey: "type" }}
                    documentName={rowData?.name_typeregion}
                    documentId={rowData?.STA_TypeRegion_ID}
                  />
                </Form.Item>
              </Col>
              <Col
                lg={{ span: 11, offset: 1 }}
                md={{ span: 11, offset: 1 }}
                sm={24}
              >
                <Form.Item
                  label={intl("definition-full-name")}
                  name="definitionFullName"
                >
                  <DrawerComponent
                    url="/directories/relation-typename/getdata"
                    options={{
                      method: "get",
                      cancelTokenKey: "definitionFullName",
                    }}
                    documentName={rowData?.relation_typename}
                    documentId={rowData?.sta_Relation_TypeNAme_id}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col lg={16} md={16} sm={24}>
                <Form.Item
                  label={intl("street-name")}
                  name="streetName"
                  rules={[
                    {
                      required: true,
                      message: intl("street-name-required"),
                      validator: validator,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col
                lg={{ span: 7, offset: 1 }}
                md={{ span: 7, offset: 1 }}
                sm={24}
              >
                <Form.Item
                  label={intl("previous-street-name")}
                  name="previousStreetName"
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Form.Item>
          <Space style={{ display: "flex", justifyContent: "end" }}>
            <Button onClick={() => modalContext.close(name)}>
              {intl("cancel")}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className={styles["button"]}
            >
              {intl("ok")}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </ConfigProvider>
  );
}

function mapStateToProps(state) {
  return {
    lang: state.lang.value,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: (language) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
    raiseMessage: (message) =>
      dispatch({ type: Actions.RAISE_MESSAGE, message }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormComponent);
