import { useState } from "react";
import { Table, Spin, ConfigProvider } from "antd";
import { connect } from "react-redux";
import locale from "antd/es/locale/uk_UA";

import * as Actions from "../../../../Store/actions";
import { translate } from "../../../../Translation";
import { request } from "../../../../Services";
import { tasksColumns } from "./utils";
import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined";
import styles from "./styles.module.scss";

function TableComponent(props) {
  const [rowId, setRowId] = useState(null);
  const { data, pagination, loading } = props.state;

  const intl = (selector) => {
    return translate(selector, props.lang);
  };

  const columns = tasksColumns(intl);

  const onTableChange = (pagination, filters, sorter) => {
    props.fetchData(props.path, {
      method: "post",
      body: {
        ...props.state,
        sortField: sorter.field,
        sortOrder: sorter.order,
        pagination,
        ...filters,
      },
      cancelTokenKey: "table",
    });
  };

  const fetchRegistryDocuments = async (btaDocId) => {
    const data = await request(
      props.registry.url + `${btaDocId}`,
      props.registry.options
    );
    console.log("registryDocuments: ", data);
    if (data) {
      props.changeFilesData("registryDocuments", data.content);
    }
  };

  const fetchIncludedFiles = async (btaDocId) => {
    const data = await request(
      props.files.url + `${btaDocId}`,
      props.files.options
    );
    console.log("includedFiles: ", data);
    if (data) {
      props.changeFilesData("includedFiles", data.content);
    }
  };

  const fetchPassingDocuments = async (btaDocId) => {
    const data = await request(
      props.passing.url + `${btaDocId}`,
      props.passing.options
    );
    console.log("passingDocuments: ", data);
    if (data) {
      props.changeFilesData("passingDocuments", data.content);
    }
  };

  const fetchRelationDocuments = async (btaDocId) => {
    const data = await request(
      props.relation.url + `${btaDocId}`,
      props.relation.options
    );
    console.log("relationDocuments: ", data);
    if (data) {
      props.changeFilesData("relationDocuments", data.content);
    }
  };

  const onClickRow = (record) => {
    setRowId(record.bta_documents_id);
    // props.changeDocumentData(record);
  };

  const setRowClassName = (record) => {
    return record.bta_documents_id === rowId ? styles["table-select-row"] : "";
  };

  return (
    <ConfigProvider locale={locale}>
      <Table
        className={styles.table}
        columns={columns}
        rowKey={(record) => record.id}
        scroll={{ x: 1024, y: 500 }}
        pagination={{ position: ["bottomCenter"], showSizeChanger: true }}
        showSorterTooltip={false}
        loading={{
          spinning: loading,
          indicator: (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          ),
        }}
        onRow={(record) => {
          return {
            onClick: () => {
              onClickRow(record);
              // fetchRegistryDocuments(record.bta_document_id);
              // fetchIncludedFiles(record.bta_document_id);
              // fetchPassingDocuments(record.bta_document_id);
              // fetchRelationDocuments(record.bta_document_id);
            },
          };
        }}
        rowClassName={setRowClassName}
        // onChange={onTableChange}
        dataSource={data.map((item) => ({
          ...item,
          key: item.id,
        }))}
        size="small"
        bordered
      />
    </ConfigProvider>
  );
}

function mapStateToProps(state) {
  return {
    lang: state.lang.value,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: (language) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
    raiseMessage: (message) =>
      dispatch({ type: Actions.RAISE_MESSAGE, message }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TableComponent);
