import { Checkbox, Space, Dropdown, Menu } from "antd";
import { MoreOutlined } from "@ant-design/icons";

export function registryColumns(intl = () => String()) {
  return [
    // {
    //   title: intl("id"),
    //   dataIndex: "id",
    //   key: "id",
    //   width: 100,
    // },
    // {
    //   title: intl("num-pp"),
    //   dataIndex: "num_pp",
    //   key: "num_pp",
    //   width: 200,
    // },
    // {
    //   title: intl("bta-document-id"),
    //   dataIndex: "bta_document_id",
    //   key: "bta_document_id",
    //   width: 200,
    // },
    {
      title: intl("date-of-receiving"),
      dataIndex: "DateStart",
      key: "DateStart",
      width: 200,
      render: (_, record) => record?.DateStart?.split("T")[0],
    },
    {
      title: intl("date-of-control"),
      dataIndex: "DateControl",
      key: "DateControl",
      width: 200,
      render: (_, record) => record?.DateControl?.split("T")[0],
    },
    {
      title: intl("execution-date"),
      dataIndex: "DateExec",
      key: "DateExec",
      width: 200,
      render: (_, record) => record?.DateExec?.split("T")[0],
    },
    {
      title: intl("executer"),
      dataIndex: "ExecutersName",
      key: "ExecutersName",
      width: 200,
    },
    {
      title: intl("responsible-person"),
      dataIndex: "ExecutersFIO",
      key: "ExecutersFIO",
      width: 200,
    },
    {
      title: intl("controller"),
      dataIndex: "Controllerl",
      key: "Controllerl",
      width: 200,
    },
    {
      title: intl("main-executor"),
      dataIndex: "isMainExecutors",
      key: "isMainExecutors",
      width: 200,
      render: (_, record) => (
        <Checkbox defaultChecked={!!record?.isMainExecutors} />
      ),
    },
    {
      title: intl("task-resolution"),
      dataIndex: "text_rezolizii",
      key: "text_rezolizii",
      width: 200,
    },
  ];
}

export function passingColumns(intl = () => String()) {
  return [
    // {
    //   title: intl("id"),
    //   dataIndex: "id",
    //   key: "id",
    //   width: 100,
    // },
    // {
    //   title: intl("bta-document-id"),
    //   dataIndex: "bta_document_id",
    //   key: "bta_document_id",
    //   width: 200,
    // },
    {
      title: intl("date-of-control"),
      dataIndex: "DateControl",
      key: "DateControl",
      width: 200,
    },
    {
      title: intl("execution-date"),
      dataIndex: "DateExec",
      key: "DateExec",
      width: 200,
    },
    {
      title: intl("paragraph-no"),
      dataIndex: "PunktNo",
      key: "PunktNo",
      width: 200,
    },
    {
      title: intl("additions"),
      dataIndex: "DodatokNo",
      key: "DodatokNo",
      width: 200,
    },
    {
      title: intl("executer"),
      dataIndex: "ExecutersName",
      key: "ExecutersName",
      width: 200,
    },
    {
      title: intl("responsible-person"),
      dataIndex: "ExecutersFIO",
      key: "ExecutersFIO",
      width: 200,
    },
    {
      title: intl("controller"),
      dataIndex: "Controllerl",
      key: "Controllerl",
      width: 200,
    },
  ];
}

export function relationColumns(intl = () => String()) {
  return [
    // {
    //   title: intl("id"),
    //   dataIndex: "id",
    //   key: "id",
    //   width: 100,
    // },
    // {
    //   title: intl("bta-document-id"),
    //   dataIndex: "bta_document_id",
    //   key: "bta_document_id",
    //   width: 200,
    // },
    {
      title: intl("type-of-connection"),
      dataIndex: "TypeRelDoc",
      key: "TypeRelDoc",
      width: 200,
    },
    {
      title: intl("document"),
      dataIndex: "DocRekvizit",
      key: "DocRekvizit",
      width: 200,
    },
    {
      title: intl("notes"),
      dataIndex: "PrimDocRelation",
      key: "PrimDocRelation",
      width: 200,
    },
    {
      title: intl("incoming-no-correspondent"),
      dataIndex: "EnterNo",
      key: "EnterNo",
      width: 200,
    },
    {
      title: intl("date-to-correspondent"),
      dataIndex: "EnterDate",
      key: "EnterDate",
      width: 200,
    },
  ];
}

export function recipientColumns(intl = () => String()) {
  return [
    {
      title: intl("type-of-shipment"),
      dataIndex: "Name_TypeSend",
      key: "Name_TypeSend",
    },
    {
      title: intl("recipient"),
      dataIndex: "Correspondent_custom",
      key: "Correspondent_custom",
    },
    {
      title: intl("name-recipient"),
      dataIndex: "FIO_Correspondent_custom",
      key: "FIO_Correspondent_custom",
    },
    {
      title: intl("no-letterhead"),
      dataIndex: "TurnBlankNum",
      key: "TurnBlankNum",
    },
    {
      title: intl("notes"),
      dataIndex: "prim",
      key: "prim",
    },
  ];
}

export function checkMailColumns(intl = () => String()) {
  return [
    {
      title: intl("theme"),
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: intl("from"),
      dataIndex: "from",
      key: "from",
    },
    {
      title: intl("date"),
      dataIndex: "date",
      key: "date",
    },
    {
      title: intl("text"),
      dataIndex: "text",
      key: "text",
    },
    {
      title: intl("attachments"),
      dataIndex: "filename",
      key: "filename",
      render: (filename) =>
        filename.map((item) => {
          return <p>{item}</p>;
        }),
    },
  ];
}

export function filesColumns(intl = () => String(), menu) {
  return [
    {
      title: intl("file"),
      dataIndex: "fileName",
      key: "fileName",
    },
    {
      title: intl("type"),
      dataIndex: "fileExtention",
      key: "fileExtention",
    },
    {
      title: intl("version-number"),
      dataIndex: "numVersion",
      key: "numVersion",
    },
    {
      title: intl("date-of-change"),
      dataIndex: "dateChange",
      key: "dateChange",
      render: (_, record) => record?.dateChange?.split("T")[0],
    },
    {
      title: intl("original"),
      dataIndex: "isOriginal",
      key: "isOriginal",
      render: (_, record) => <Checkbox defaultChecked={!!record?.isOriginal} />,
    },
    {
      title: intl("to-send"),
      dataIndex: "isSendEmail",
      key: "isSendEmail",
      render: (_, record) => (
        <Checkbox defaultChecked={!!record?.isSendEmail} />
      ),
    },
    {
      title: intl("barcode-added"),
      dataIndex: "isSetBarCode",
      key: "isSetBarCode",
      render: (_, record) => (
        <Checkbox defaultChecked={!!record?.isSetBarCode} />
      ),
    },
    {
      title: intl("signature"),
      dataIndex: "isSign",
      key: "isSign",
      render: (_, record) => <Checkbox defaultChecked={!!record?.isSign} />,
    },
    {
      title: intl("notes-to-the-signature"),
      dataIndex: "signNote",
      key: "signNote",
    },
    {
      title: intl("file-notes"),
      dataIndex: "fileNote",
      key: "fileNote",
    },
    {
      title: "",
      key: "operation",
      render: () => (
        <Space size="middle">
          <Dropdown overlay={menu}>
            <a>
              <MoreOutlined style={{ color: "#000" }} />
            </a>
          </Dropdown>
        </Space>
      ),
    },
  ];
}
