// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_form__20zmv .ant-input[disabled],\n.styles_form__20zmv .ant-picker-input > input[disabled] {\n  color: rgba(0, 0, 0, 0.7);\n}\n\n.styles_button__wVLbu,\n.styles_button__wVLbu:focus {\n  background: #5abab9;\n  font-size: 16px;\n  border: none;\n  color: #ffffff;\n  line-height: 1;\n  width: 100%;\n}\n\n.styles_button__wVLbu:hover {\n  background: #5abab9;\n  color: #3f434e;\n}", "",{"version":3,"sources":["webpack://src/Containers/Cabinet/Resolutions/styles.module.scss"],"names":[],"mappings":"AAEI;;EAEE,yBAAA;AADN;;AAMA;;EAEE,mBAAA;EACA,eAAA;EACA,YAAA;EACA,cAAA;EACA,cAAA;EACA,WAAA;AAHF;;AAMA;EACE,mBAAA;EACA,cAAA;AAHF","sourcesContent":[".form {\n  :global {\n    .ant-input[disabled],\n    .ant-picker-input > input[disabled] {\n      color: rgba(0, 0, 0, 0.7);\n    }\n  }\n}\n\n.button,\n.button:focus {\n  background: #5abab9;\n  font-size: 16px;\n  border: none;\n  color: #ffffff;\n  line-height: 1;\n  width: 100%;\n}\n\n.button:hover {\n  background: #5abab9;\n  color: #3f434e;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "styles_form__20zmv",
	"button": "styles_button__wVLbu"
};
export default ___CSS_LOADER_EXPORT___;
