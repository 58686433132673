// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_delete-button__1XvDb,\n.styles_delete-button__1XvDb:focus,\n.styles_delete-button2__21GUo,\n.styles_delete-button2__21GUo:focus {\n  background: #5abab9;\n  /* font-size: 16px; */\n  border: none;\n  color: #ffffff;\n  line-height: 1;\n  width: 100%;\n  /* margin-left: 1rem; */\n}\n\n.styles_delete-button__1XvDb,\n.styles_delete-button__1XvDb:focus,\n.styles_delete-button__1XvDb > button {\n  font-size: 16px;\n}\n\n.styles_delete-button2__21GUo,\n.styles_delete-button2__21GUo:focus {\n  width: auto;\n  margin-left: 1rem;\n  /* display: ; */\n}\n\n.styles_delete-button__1XvDb > button,\n.styles_delete-button2__21GUo > button {\n  width: 100%;\n  border: none;\n}\n\n.styles_delete-button__1XvDb:hover,\n.styles_delete-button2__21GUo:hover {\n  background: #5abab9;\n  color: #3f434e;\n}\n", "",{"version":3,"sources":["webpack://src/Components/UI/Popconfirm/styles.module.css"],"names":[],"mappings":"AAAA;;;;EAIE,mBAAmB;EACnB,qBAAqB;EACrB,YAAY;EACZ,cAAc;EACd,cAAc;EACd,WAAW;EACX,uBAAuB;AACzB;;AAEA;;;EAGE,eAAe;AACjB;;AAEA;;EAEE,WAAW;EACX,iBAAiB;EACjB,eAAe;AACjB;;AAEA;;EAEE,WAAW;EACX,YAAY;AACd;;AAEA;;EAEE,mBAAmB;EACnB,cAAc;AAChB","sourcesContent":[".delete-button,\n.delete-button:focus,\n.delete-button2,\n.delete-button2:focus {\n  background: #5abab9;\n  /* font-size: 16px; */\n  border: none;\n  color: #ffffff;\n  line-height: 1;\n  width: 100%;\n  /* margin-left: 1rem; */\n}\n\n.delete-button,\n.delete-button:focus,\n.delete-button > button {\n  font-size: 16px;\n}\n\n.delete-button2,\n.delete-button2:focus {\n  width: auto;\n  margin-left: 1rem;\n  /* display: ; */\n}\n\n.delete-button > button,\n.delete-button2 > button {\n  width: 100%;\n  border: none;\n}\n\n.delete-button:hover,\n.delete-button2:hover {\n  background: #5abab9;\n  color: #3f434e;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"delete-button": "styles_delete-button__1XvDb",
	"delete-button2": "styles_delete-button2__21GUo"
};
export default ___CSS_LOADER_EXPORT___;
