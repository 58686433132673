import { useState, useRef } from "react";
import { Input, Button, Space } from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";

export function cabinetColumns(intl = () => String()) {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  let searchInput = useRef();

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`${intl("search")}...`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {intl("search")}
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            {intl("reset")}
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            {intl("filter")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  return [
    // {
    //   title: intl('id'),
    //   dataIndex: 'id',
    //   key: 'id',
    //   width: 100,
    //   fixed: 'left',
    // },
    {
      title: intl("document-number"),
      dataIndex: "DocNum",
      key: "DocNum",
      align: "center",
      ellipsis: true,
      ...getColumnSearchProps("DocNum"),
    },
    {
      title: intl("document-date"),
      dataIndex: "DocDate",
      key: "DocDate",
      ellipsis: true,
      ...getColumnSearchProps("DocDate"),
      // render: (DocDate) => {return (<p>{moment(DocDate).format("DD-MM-YYYY")}</p>)}
    },
    {
      title: intl("document-type"),
      dataIndex: "DocType",
      key: "DocType",
      ellipsis: true,
      ...getColumnSearchProps("DocType"),
    },
    {
      title: intl("content"),
      dataIndex: "Content",
      key: "Content",
      ellipsis: true,
      ...getColumnSearchProps("Content"),
    },
    {
      title: intl("place-of-registration"),
      dataIndex: "PlaseRegistration",
      key: "PlaseRegistration",
      ellipsis: true,
      ...getColumnSearchProps("PlaseRegistration"),
    },
    {
      title: intl("co-executors"),
      dataIndex: "СoExecutors",
      key: "СoExecutors",
      ellipsis: true,
      ...getColumnSearchProps("СoExecutors"),
    },
    {
      title: intl("date-of-control"),
      dataIndex: "DateControl",
      key: "DateControl",
      ellipsis: true,
      ...getColumnSearchProps("DateControl"),
    },
    {
      title: intl("recipient"),
      dataIndex: "Recipient",
      key: "Recipient",
      ellipsis: true,
      ...getColumnSearchProps("Recipient"),
    },
    {
      title: intl("resolution"),
      dataIndex: "Resolution",
      key: "Resolution",
      ellipsis: true,
      ...getColumnSearchProps("Resolution"),
    },
    {
      title: intl("current-stage"),
      dataIndex: "actions",
      key: "actions",
    },
  ];
}
