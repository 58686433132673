import axios from "axios";
import rateLimit from "axios-rate-limit";

import { environment } from "./environment";

export const api = axios.create({
  baseURL: environment.api,
});

export const apiFileDownload = rateLimit(api, {
  maxRequests: 1,
  perMilliseconds: 3000,
  maxRPS: 1,
});
