import React from "react";

import styles from "../styles.module.css";

export function leftTableColumns(intl = () => String()) {
  return [
    {
      title: intl("documents"),
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: intl("current-year"),
      dataIndex: "CurrentYear",
      key: "CurrentYear",
      className: styles.Columns,
      // align: 'right',
    },
    {
      title: intl("previous-year"),
      dataIndex: "PriorYear",
      key: "PriorYear",
      className: styles.Columns,
      // align: 'right'
    },
    {
      title: intl("difference"),
      dataIndex: "DiffYear",
      key: "DiffYear",
      className: styles.Columns,
      // align: 'right',
      render: (_, record) => {
        const propertyStyles = [styles.Green];

        if (record.DiffYear < 0) {
          propertyStyles.push(styles.Red);
        }

        return (
          <span className={propertyStyles.join(" ")}>{record.DiffYear}</span>
        );
      },
    },
  ];
}

export function rightTableColumns(intl = () => String()) {
  return [
    {
      title: intl("documents"),
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: intl("current-year"),
      dataIndex: "CurrentYear",
      key: "CurrentYear",
      width: "auto",
      className: styles.Columns,
      // align: 'right'
    },
    {
      title: intl("previous-year"),
      dataIndex: "PriorYear",
      key: "PriorYear",
      width: "auto",
      className: styles.Columns,
      // align: 'right',
    },
    {
      title: intl("difference"),
      dataIndex: "DiffYear",
      key: "DiffYear",
      className: styles.Columns,
      // align: 'right',
      render: (_, record) => {
        const propertyStyles = [styles.Green];

        if (record.DiffYear < 0) {
          propertyStyles.push(styles.Red);
        }

        return (
          <span className={propertyStyles.join(" ")}>{record.DiffYear}</span>
        );
      },
    },
  ];
}
