import { Component } from "react";

import User from "../Services/user";

class HomePage extends Component {
  componentDidMount() {
    if (User.token) {
      const currentUser = User.decode();
      if (!currentUser) {
        this.props.history.replace("/auth");
      } else {
        this.props.history.replace("/cabinet");
      }
    } else {
      this.props.history.replace("/auth");
    }
  }

  render() {
    return null;
  }
}

export default HomePage;
