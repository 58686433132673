import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Layout,
  Menu,
  Button,
  Dropdown,
  Space,
  Breadcrumb,
} from "antd";
// UI Icons
import MenuUnfoldOutlined from "@ant-design/icons/lib/icons/MenuUnfoldOutlined";
import MenuFoldOutlined from "@ant-design/icons/lib/icons/MenuFoldOutlined";
import DownOutlined from "@ant-design/icons/lib/icons/DownOutlined";
import LogoutOutlined from "@ant-design/icons/lib/icons/LogoutOutlined";
import UserOutlined from "@ant-design/icons/lib/icons/UserOutlined";
import FileDoneOutlined from "@ant-design/icons/lib/icons/FileDoneOutlined";
import GlobalOutlined from "@ant-design/icons/lib/icons/GlobalOutlined";

import User from "../Services/user";
import * as Actions from "../Store/actions";
import { translate, availableLanguages } from "../Translation";
import { decryptData } from "../Services/encryption";
import logo from "../Assets/logo-white.png";
import styles from "./styles.module.scss";
// import Splitter from "../Components/UI/Splitter";

const { Header, Sider, Content, Footer } = Layout;
const { SubMenu } = Menu;

function CabinetLayout(props) {
  const [collapsed, setCollapsed] = useState(
    localStorage.sidebar_collapsed
      ? localStorage.sidebar_collapsed === "true"
      : false
  );
  const [openKeys, setOpenKeys] = useState(
    JSON.parse(localStorage.getItem("open-keys")) || ["sub1"]
  );
  const [parent, setParent] = useState(undefined);
  const [parentItems, setParentItems] = useState(undefined);
  const { page } = useParams();

  useEffect(() => {
    getParentRoutes();
  }, []);

  const intl = (selector) => {
    return translate(selector, props.lang);
  };

  const routesObj = {
    "/cabicabinet/profile": { title: intl("profile") },
    "/cabinet": {
      id: ["1"],
      title: intl("incoming"),
      breadcrump: intl("documents"),
    },
    "/cabinet/incomingview": {
      id: ["1"],
      title: intl("incoming"),
      breadcrump: intl("documents"),
    },
    "/cabinet/outcoming": {
      id: ["2"],
      title: intl("outcoming"),
      breadcrump: intl("documents"),
    },
    "/cabinet/outcomingview": {
      id: ["2"],
      title: intl("outcoming"),
      breadcrump: intl("documents"),
    },
    "/cabinet/administrative": {
      id: ["3"],
      title: intl("administrative"),
      breadcrump: intl("documents"),
    },
    "/cabinet/administrativeview": {
      id: ["3"],
      title: intl("administrative"),
      breadcrump: intl("documents"),
    },
    "/cabinet/citizens-appeal": {
      id: ["4"],
      title: intl("citizens-appeal"),
      breadcrump: intl("documents"),
    },
    "/cabinet/citizensappealview": {
      id: ["4"],
      title: intl("citizens-appeal"),
      breadcrump: intl("documents"),
    },
    "/cabinet/resolutions": {
      id: ["5"],
      title: intl("resolutions"),
      breadcrump: intl("documents"),
    },
    "/cabinet/resolutionsview": {
      id: ["5"],
      title: intl("resolutions"),
      breadcrump: intl("documents"),
    },
    "/cabinet/others": {
      id: ["6"],
      title: intl("others"),
      breadcrump: intl("documents"),
    },
    "/statistics/receipts": {
      id: ["7"],
      title: intl("receipts"),
      breadcrump: intl("statistics"),
    },
    "/statistics/problems": {
      id: ["8"],
      title: intl("problems"),
      breadcrump: intl("statistics"),
    },
    "/statistics/problems/performers": {
      id: ["9"],
      title: intl("by-performers"),
      breadcrump: intl("statistics"),
    },
    "/statistics/problems/address": {
      id: ["10"],
      title: intl("by-address"),
      breadcrump: intl("statistics"),
    },
    "/statistics/problems/district": {
      id: ["11"],
      title: intl("by-district"),
      breadcrump: intl("statistics"),
    },
    "/statistics/other-questions": {
      id: ["12"],
      title: intl("other-questions"),
      breadcrump: intl("statistics"),
    },
    "/statistics/other-questions/performers": {
      id: ["13"],
      title: intl("by-performers"),
      breadcrump: intl("statistics"),
    },
    "/statistics/other-questions/address": {
      id: ["14"],
      title: intl("by-address"),
      breadcrump: intl("statistics"),
    },
    "/statistics/other-questions/district": {
      id: ["15"],
      title: intl("by-district"),
      breadcrump: intl("statistics"),
    },
    "/statistics/activity": {
      id: ["16"],
      title: intl("activity-of-residents"),
      breadcrump: intl("statistics"),
    },
    "/statistics/rating-managers": {
      id: ["17"],
      title: intl("rating-of-managers"),
      breadcrump: intl("statistics"),
    },
    "/statistics/rating-service-providers": {
      id: ["18"],
      title: intl("rating-service-providers"),
      breadcrump: intl("statistics"),
    },
    "/statistics/structural-units": {
      id: ["19"],
      title: intl("structural-units"),
      breadcrump: intl("statistics"),
    },
    "/directories/types-documents": {
      id: ["20"],
      title: intl("types-of-documents"),
      breadcrump: intl("directories"),
    },
    "/directories/categories-documents": {
      id: ["23"],
      title: intl("categories-of-documents"),
      breadcrump: intl("directories"),
    },
    "/directories/sending-documents": {
      id: ["24"],
      title: intl("types-of-sending-documents"),
      breadcrump: intl("directories"),
    },
    "/directories/relationships-documents": {
      id: ["25"],
      title: intl("relationships-documents"),
      breadcrump: intl("directories"),
    },
    "/directories/category-positions": {
      id: ["26"],
      title: intl("category-of-positions"),
      breadcrump: intl("directories"),
    },
    "/directories/job-title": {
      id: ["27"],
      title: intl("job-title"),
      breadcrump: intl("directories"),
    },
    "/directories/directory-positions": {
      id: ["28"],
      title: intl("directory-of-positions"),
      breadcrump: intl("directories"),
    },
    "/directories/structural-unit": {
      id: ["29"],
      title: intl("structural-unit"),
      breadcrump: intl("directories"),
    },
    "/directories/departments": {
      id: ["30"],
      title: intl("departments"),
      breadcrump: intl("directories"),
    },
    "/directories/employee-directory": {
      id: ["31"],
      title: intl("employee-directory"),
      breadcrump: intl("directories"),
    },
    "/directories/correspondents-directory": {
      id: ["32"],
      title: intl("directory-of-correspondents"),
      breadcrump: intl("directories"),
    },
    "/directories/types-toponyms-directory": {
      id: ["33"],
      title: intl("types-toponyms"),
      breadcrump: intl("directories"),
    },
    "/directories/countries-directory": {
      id: ["34"],
      title: intl("directory-countries"),
      breadcrump: intl("directories"),
    },
    "/directories/region-directory": {
      id: ["35"],
      title: intl("directory-regions"),
      breadcrump: intl("directories"),
    },
    "/directories/town-directory": {
      id: ["36"],
      title: intl("directory-cities"),
      breadcrump: intl("directories"),
    },
    "/directories/village-directory": {
      id: ["37"],
      title: intl("directory-villages"),
      breadcrump: intl("directories"),
    },
    "/directories/district-directory": {
      id: ["38"],
      title: intl("directory-districts"),
      breadcrump: intl("directories"),
    },
    "/directories/streets-directory": {
      id: ["39"],
      title: intl("directory-streets"),
      breadcrump: intl("directories"),
    },
    "/directories/nomenclature": {
      id: ["40"],
      title: intl("directory-of-nomenclature"),
      breadcrump: intl("directories"),
    },
    "/administration/users": {
      id: ["41"],
      title: intl("users"),
      breadcrump: intl("administration"),
    },
    "/administration/access-pages": {
      id: ["42"],
      title: intl("configuring-page-access"),
      breadcrump: intl("administration"),
    },
    "/administration/servers": {
      id: ["43"],
      title: intl("servers-settings"),
      breadcrump: intl("administration"),
    },
    "/cabinet/tasks": {
      id: ["44"],
      title: intl("tasks"),
      breadcrump: intl("documents"),
    },
    "/administration/access-buttons": {
      id: ["45"],
      title: intl("buttons-access-settings"),
      breadcrump: intl("administration"),
    },
    "/directories/groups-correspondents": {
      id: ["46"],
      title: intl("groups-of-correspondents"),
      breadcrump: intl("directories"),
    },
    "/directories/indexes-correspondence": {
      id: ["47"],
      title: intl("indexes-of-correspondence"),
      breadcrump: intl("directories"),
    },
    "/directories/types-receipts": {
      id: ["48"],
      title: intl("types-of-receipts"),
      breadcrump: intl("directories"),
    },
    "/directories/forms-receipts": {
      id: ["49"],
      title: intl("forms-of-receipts"),
      breadcrump: intl("directories"),
    },
    "/directories/types-appeals": {
      id: ["50"],
      title: intl("types-of-appeals"),
      breadcrump: intl("directories"),
    },
    "/directories/types-of-appeals": {
      id: ["51"],
      title: intl("types-of-appeals2"),
      breadcrump: intl("directories"),
    },
    "/directories/signs-receipt": {
      id: ["52"],
      title: intl("signs-of-receipt"),
      breadcrump: intl("directories"),
    },
    "/directories/subjects-appeals": {
      id: ["53"],
      title: intl("subjects-of-appeals"),
      breadcrump: intl("directories"),
    },
    "/directories/categories-authors-appeals": {
      id: ["54"],
      title: intl("categories-of-authors-of-appeals"),
      breadcrump: intl("directories"),
    },
    "/directories/social-status-authors-appeals": {
      id: ["55"],
      title: intl("social-status-of-the-authors-of-appeals"),
      breadcrump: intl("directories"),
    },
    "/directories/groups-performers": {
      id: ["56"],
      title: intl("groups-of-performers"),
      breadcrump: intl("directories"),
    },
    "/directories/departments-organizations": {
      id: ["57"],
      title: intl("departments-organizations"),
      breadcrump: intl("directories"),
    },
    "/directories/actions-documents": {
      id: ["58"],
      title: intl("actions-with-documents"),
      breadcrump: intl("directories"),
    },
    "/footer/privacy-policy": {
      id: ["146"],
      /* title: intl("privacy-policy"), */ breadcrump: intl("privacy-policy"),
    },
    "/footer/terms-of-use": {
      id: ["147"],
      /* title: intl("terms-of-use"), */ breadcrump: intl("terms-of-use"),
    },
    "/footer/support": {
      id: ["148"],
      /* title: intl("support"), */ breadcrump: intl("support"),
    },
    "/cabinet/:page": {
      title: props?.title,
      breadcrump: intl("documents"),
    },
    // [`/cabinet/${page}`]: { title: props?.title, breadcrump: intl("documents") },
  };

  const getParentRoutes = async () => {
    // const currentUser = User.decode();
    // if (!currentUser) return null;
    // setParent(
    //   currentUser?.accesses?.map(
    //     (i) => i?.routes.map((route) => route.parentRoute)[0]
    //   )
    // );
    // setParentItems(currentUser?.accesses?.map((i) => i.routes[0]));
    const accesses = decryptData();
    if (accesses) {
      setParent(accesses.map((i) => i?.parentRoute));
      setParentItems(accesses);
    } else {
      props.history.replace("/auth");
    }
  };

  const toggle = () => {
    setCollapsed(
      !collapsed,
      () => (localStorage.sidebar_collapsed = collapsed)
    );
  };

  const onOpenChange = (openKeys) => {
    setOpenKeys(() => {
      localStorage.setItem("open-keys", JSON.stringify(openKeys));
      return openKeys;
    });
  };

  const resolveActiveSelectedMenu = (path) => {
    if (path.includes(":page")) return `/cabinet/${page}`;
    else return routesObj[path]?.id || ["0"];
    // return routesObj[path]?.id || ["0"];
  };

  const resolveBreadcrump = (path) => {
    return routesObj?.breadcrump || null;
  };

  const resolveActiveRoute = (path) => {
    return routesObj[path]?.title || null;
  };

  const handleLogoutClick = () => {
    User.unset();
    props.history.push("/auth");
  };

  const handleProfileClick = () => {
    props.history.push("/cabinet/profile");
  };

  const currentUser = User.decode();
  if (!currentUser) return null;
  const firstName = currentUser ? currentUser.firstName : String();
  const lastName = currentUser ? currentUser.lastName : String();

  const activeLink = resolveActiveSelectedMenu(props.activeLink);
  const logoStyles = [styles.logo];
  if (collapsed) {
    logoStyles.push(styles.collapsed);
  }
  // const [siderWidth, setSiderWidth] = useState(200);

  return (
    <Layout className={styles["cabinet-main"]}>
      {/* <Splitter onDrag={(newWidth) => setSiderWidth(newWidth)}> */}
        <Sider
          className={styles["cabinet-sider"]}
          trigger={null}
          collapsible
          collapsed={collapsed}
          // width={siderWidth}
        >
          <div className={logoStyles.join(" ")}>
            <img src={logo} alt="" />
          </div>
          <Menu
            className={styles["cabinet-menu"]}
            mode="inline"
            onOpenChange={onOpenChange}
            defaultOpenKeys={openKeys}
            defaultSelectedKeys={activeLink}
          >
            {parent?.includes("docs") ? (
              <SubMenu
                key="sub1"
                icon={<FileDoneOutlined />}
                title={intl("documents")}
              >
                {parentItems
                  ?.filter((i) => i.parentRoute === "docs")
                  .map((i) => (
                    <Menu.Item key={i.idRoute} title={i.name}>
                      <Link to={i.route}>{i.name}</Link>
                    </Menu.Item>
                  ))}
                {parentItems
                  ?.filter((i) => i.parentRoute === "dynamic-docs")
                  .map((i) => (
                    <Menu.Item key={i.idRoute} title={i.name}>
                      <Link to={{ pathname: i.route, state: i.id }}>
                        {i.name}
                      </Link>
                    </Menu.Item>
                  ))}
              </SubMenu>
            ) : null}

            {parent?.includes("stats") ? (
              <SubMenu
                key="sub2"
                icon={<FileDoneOutlined />}
                title={intl("statistics")}
              >
                {parent?.includes("problems") ? (
                  <SubMenu
                    key="sub2-2"
                    icon={<FileDoneOutlined />}
                    title={intl("problems")}
                  >
                    {parentItems
                      ?.filter((i) => i.parentRoute === "problems")
                      .map((i) => (
                        <Menu.Item key={i.idRoute} title={i.name}>
                          <Link to={i.route}>{i.name}</Link>
                        </Menu.Item>
                      ))}
                  </SubMenu>
                ) : null}
                {parent?.includes("others") ? (
                  <SubMenu
                    key="sub2-3"
                    icon={<FileDoneOutlined />}
                    title={intl("other-questions")}
                  >
                    {parentItems
                      ?.filter((i) => i.parentRoute === "others")
                      .map((i) => (
                        <Menu.Item key={i.idRoute} title={i.name}>
                          <Link to={i.route}>{i.name}</Link>
                        </Menu.Item>
                      ))}
                  </SubMenu>
                ) : null}
                {parentItems
                  ?.filter((i) => i.parentRoute === "stats")
                  .map((i) => (
                    <Menu.Item key={i.idRoute} title={i.name}>
                      <Link to={i.route}>{i.name}</Link>
                    </Menu.Item>
                  ))}
              </SubMenu>
            ) : null}

            <SubMenu
              key="sub3"
              icon={<FileDoneOutlined />}
              title={intl("directories")}
            >
              {parent?.includes("char-docs") ? (
                <SubMenu
                  key="sub3-2"
                  icon={<FileDoneOutlined />}
                  title={intl("characteristics-of-documents")}
                >
                  {parentItems
                    ?.filter((i) => i.parentRoute === "char-docs")
                    .map((i) => (
                      <Menu.Item key={i.idRoute} title={i.name}>
                        <Link to={{ pathname: i.route }}>{i.name}</Link>
                      </Menu.Item>
                    ))}
                </SubMenu>
              ) : null}
              {parent?.includes("hr-depart") ? (
                <SubMenu
                  key="sub3-3"
                  icon={<FileDoneOutlined />}
                  title={intl("hr-department")}
                >
                  {parentItems
                    ?.filter((i) => i.parentRoute === "hr-depart")
                    .map((i) => (
                      <Menu.Item key={i.idRoute} title={i.name}>
                        <Link to={{ pathname: i.route }}>{i.name}</Link>
                      </Menu.Item>
                    ))}
                </SubMenu>
              ) : null}
              {parent?.includes("general") ? (
                <SubMenu
                  key="sub3-4"
                  icon={<FileDoneOutlined />}
                  title={intl("general")}
                >
                  {parentItems
                    ?.filter((i) => i.parentRoute === "general")
                    .map((i) => (
                      <Menu.Item key={i.idRoute} title={i.name}>
                        <Link to={{ pathname: i.route }}>{i.name}</Link>
                      </Menu.Item>
                    ))}
                </SubMenu>
              ) : null}
              {parent?.includes("koatuu") ? (
                <SubMenu
                  key="sub3-5"
                  icon={<FileDoneOutlined />}
                  title={intl("koatuu")}
                >
                  {parentItems
                    ?.filter((i) => i.parentRoute === "koatuu")
                    .map((i) => (
                      <Menu.Item key={i.idRoute} title={i.name}>
                        <Link to={{ pathname: i.route }}>{i.name}</Link>
                      </Menu.Item>
                    ))}
                </SubMenu>
              ) : null}
              {parent?.includes("char-appeals") ? (
                <SubMenu
                  key="sub3-6"
                  icon={<FileDoneOutlined />}
                  title={intl("characteristics-of-appeals")}
                >
                  {parentItems
                    ?.filter((i) => i.parentRoute === "char-appeals")
                    .map((i) => (
                      <Menu.Item key={i.idRoute} title={i.name}>
                        <Link to={{ pathname: i.route }}>{i.name}</Link>
                      </Menu.Item>
                    ))}
                </SubMenu>
              ) : null}
              {parent?.includes("performers") ? (
                <SubMenu
                  key="sub3-7"
                  icon={<FileDoneOutlined />}
                  title={intl("performers")}
                >
                  {parentItems
                    ?.filter((i) => i.parentRoute === "performers")
                    .map((i) => (
                      <Menu.Item key={i.idRoute} title={i.name}>
                        <Link to={{ pathname: i.route }}>{i.name}</Link>
                      </Menu.Item>
                    ))}
                </SubMenu>
              ) : null}
              {parent?.includes("char-doc-move") ? (
                <SubMenu
                  key="sub3-8"
                  icon={<FileDoneOutlined />}
                  title={intl("document-movement-characteristics")}
                >
                  {parentItems
                    ?.filter((i) => i.parentRoute === "char-doc-move")
                    .map((i) => (
                      <Menu.Item key={i.idRoute} title={i.name}>
                        <Link to={{ pathname: i.route }}>{i.name}</Link>
                      </Menu.Item>
                    ))}
                </SubMenu>
              ) : null}
            </SubMenu>

            {parent?.includes("admin") ? (
              <SubMenu
                key="sub4"
                icon={<FileDoneOutlined />}
                title={intl("administration")}
              >
                {parentItems
                  ?.filter((i) => i.parentRoute === "admin")
                  .map((i) => (
                    <Menu.Item key={i.idRoute} title={i.name}>
                      <Link to={i.route}>{i.name}</Link>
                    </Menu.Item>
                  ))}
              </SubMenu>
            ) : null}
          </Menu>
        </Sider>
        <Layout className={styles["cabinet-content-layout"]}>
          <Header className={styles["cabinet-header"]}>
            <Row>
              <Col span={12}>
                <Space size={24}>
                  {React.createElement(
                    collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                    {
                      className: styles.trigger,
                      onClick: toggle,
                    }
                  )}
                  <Breadcrumb className={styles["cabinet-breadcrumb"]}>
                    <Breadcrumb.Item>{intl("cabinet")}</Breadcrumb.Item>
                    <Breadcrumb.Item>
                      {resolveBreadcrump(/* activeLink */ props.activeLink)}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      {resolveActiveRoute(props.activeLink)}
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </Space>
              </Col>
              <Col span={12}>
                <Row justify="end">
                  <Space>
                    <Button className={styles.information}>
                      {intl("information")}
                    </Button>
                    <Dropdown
                      className={styles["cabiten-dropdown"]}
                      overlay={() => (
                        <Menu>
                          {availableLanguages.map((language) => (
                            <Menu.Item
                              key={language.key}
                              onClick={() =>
                                props.changeLanguage(language.code)
                              }
                            >
                              {language.title}
                            </Menu.Item>
                          ))}
                        </Menu>
                      )}
                    >
                      <Button>
                        <GlobalOutlined /> {intl("language")} <DownOutlined />
                      </Button>
                    </Dropdown>
                    <Dropdown
                      className={styles["cabiten-dropdown"]}
                      overlay={() => (
                        <Menu>
                          <Menu.Item
                            key="21"
                            icon={<UserOutlined />}
                            onClick={handleProfileClick}
                          >
                            {intl("profile")}
                          </Menu.Item>
                          <Menu.Divider />
                          <Menu.Item
                            key="22"
                            icon={<LogoutOutlined />}
                            danger
                            onClick={handleLogoutClick}
                          >
                            {intl("logout")}
                          </Menu.Item>
                        </Menu>
                      )}
                    >
                      <Button>
                        {firstName} {lastName} <DownOutlined />
                      </Button>
                    </Dropdown>
                  </Space>
                </Row>
              </Col>
            </Row>
          </Header>
          <Content id="content" className={styles["cabinet-content"]}>
            {props.children}
          </Content>
          <Footer>
            <Row justify="end">
              <Col className={styles["cabinet-footer"]} span={8}>
                <Link
                  to="/footer/privacy-policy"
                  className={styles["footer-link"]}
                >
                  {intl("privacy-policy")}
                </Link>
                <Link
                  to="/footer/terms-of-use"
                  className={styles["footer-link"]}
                >
                  {intl("terms-of-use")}
                </Link>
                <Link to="/footer/support" className={styles["footer-link"]}>
                  {intl("support")}
                </Link>
              </Col>
            </Row>
          </Footer>
        </Layout>
      {/* </Splitter> */}
    </Layout>
  );
}

function mapStateToProps(state) {
  return {
    lang: state.lang.value,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: (language) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CabinetLayout);
