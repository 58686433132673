import { useState } from "react";
import { Popconfirm, Button } from "antd";
import styles from "./styles.module.css";

export default function ({ intl = () => String(), onDeleteRow, disabled, directories }) {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showPopconfirm = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    onDeleteRow();
    setConfirmLoading(false);
  };

  return (
    <Popconfirm
      title={intl("question-delete-entry")}
      open={open}
      onConfirm={handleOk}
      okButtonProps={{
        loading: confirmLoading,
      }}
      okText={intl("ok")}
      cancelText={intl("cancel")}
    >
      <Button
        className={directories ? styles["delete-button2"] : styles["delete-button"]}
        onClick={showPopconfirm}
        disabled={disabled}
      >
        {intl("remove")}
      </Button>
    </Popconfirm>
  );
}
