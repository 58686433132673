import React from "react";

import styles from "../styles.module.css";

export function tableColumns(intl = () => String()) {
  return [
    {
      title: false,
      dataIndex: "Dept",
      key: "Dept",
      width: "500px",
      render: (_, record) => {
        const propertyStyles = [styles.Column];

        return <span className={propertyStyles.join(" ")}>{record.Dept}</span>;
      },
    },
    {
      title: intl("completed-ahead-of-schedule"),
      dataIndex: "Count_early",
      key: "Count_early",
      className: styles.Columns,
      // align: 'right',
      width: "300px",
    },
    {
      title: intl("completed-in-violation"),
      dataIndex: "Count_outstanding",
      key: "Count_outstanding",
      className: styles.Columns,
      // align: 'right',
      width: "300px",
    },
    {
      title: intl("completed"),
      dataIndex: "Count_intime",
      key: "Count_intime",
      className: styles.Columns,
      // align: 'right',
      width: "300px",
    },
  ];
}
