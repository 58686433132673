import { useState, useRef } from "react";
import { connect } from "react-redux";
import { Button, Row, Col, Modal, Form, Space } from "antd";

import * as Actions from "../../../Store/actions";
import { translate } from "../../../Translation";
import { request } from "../../../Services";
import SelectComponent from "../../UI/Select";
import styles from "./styles.module.css";

function ModalAccessSettings(props) {
  const form = useRef();
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState(undefined);

  const intl = (selector) => {
    return translate(selector, props.lang);
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFormFinish = async (values) => {
    console.log("Success:", values);

    await request("/dynamic/insertdata", {
      method: "post",
      body: { ...values, id: props.id },
      cancelTokenKey: "insertdata",
    });
    setVisible(false);
  };

  const onFormFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  function validator(subject, value) {
    return new Promise((resolve, reject) => {
      if (!value) {
        return reject(new Error(subject.message));
      }

      return resolve();
    });
  }

  const fetchData = async () => {
    const { content } = await request("/administration/getpages", {
      method: "get",
      cancelTokenKey: "getpages",
    });
    const folders = content.filter((i) => i.parent_route === "dynamic-docs");
    setData(folders || []);
  };

  const onChangeSelect = (_, value) => {
    form.current.setFieldsValue({ folder: value });
  };

  return (
    <>
      <Button
        className={styles["modal-button"]}
        onClick={showModal}
        disabled={props.disabled}
      >
        {intl("move")}
      </Button>
      <Modal
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose={true}
      >
        <Row>
          <Col lg={24} md={24} sm={24} style={{ padding: "0 1rem" }}>
            <Form
              ref={form}
              onFinish={onFormFinish}
              onFinishFailed={onFormFailed}
            >
              <Form.Item
                label={intl("where")}
                name="folder"
                rules={[
                  {
                    required: true,
                    message: intl("folder-required"),
                    validator: validator,
                  },
                ]}
              >
                <SelectComponent
                  rolesData={data}
                  onChangeSelect={onChangeSelect}
                  fetchData={fetchData}
                />
              </Form.Item>

              <Form.Item>
                <Space style={{ position: "absolute", right: 0 }}>
                  <Button onClick={handleCancel}>{intl("cancel")}</Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className={styles["modal-button"]}
                  >
                    {intl("ok")}
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

function mapStateToProps(state) {
  return {
    lang: state.lang.value,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: (language) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalAccessSettings);
