// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_widget__33JMS {\n  width: 900px;\n  height: 600px;\n  border: none;\n  padding: 0 20px;\n}", "",{"version":3,"sources":["webpack://src/Components/UI/SignWidget/styles.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".widget {\n  width: 900px;\n  height: 600px;\n  border: none;\n  padding: 0 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"widget": "styles_widget__33JMS"
};
export default ___CSS_LOADER_EXPORT___;
