import Axios from "axios";
import { api } from "./axios";
import { environment } from "./environment";
import User from "./user";

const cancelTokens = new Map();

/**
 * @param {string} httpUrl - Request endpoint
 * @param {{
 *  method: 'get' | 'post' | 'put' | 'patch' | 'delete'
 *  body?: any,
 *  cancelTokenKey: string,
 *  adapter?: AxiosInstance,
 *  headers?: {
 *    'Content-Type'?: string
 *  }
 * }} httpOptions - Request options (key property is required)
 *
 * @returns {any | null} Server response or null
 */
export async function request(httpUrl, httpOptions) {
  console.time(httpOptions.cancelTokenKey + "::request");

  if (cancelTokens.has(httpOptions.cancelTokenKey)) {
    cancelTokens.get(httpOptions.cancelTokenKey).cancel();
  }

  cancelTokens.set(httpOptions.cancelTokenKey, Axios.CancelToken.source());

  const httpHeaders = {
    headers: { authorization: "Bearer " + User.token },
    cancelToken: cancelTokens.get(httpOptions.cancelTokenKey).token,
  };

  try {
    let response = null;
    let adapter = api;

    if (httpOptions.adapter) {
      adapter = httpOptions.adapter;
    }

    if (httpOptions.method === "get") {
      response = await adapter[httpOptions.method.toLocaleLowerCase()](
        httpUrl,
        httpHeaders
      );
    } else {
      response = await adapter[httpOptions.method.toLocaleLowerCase()](
        httpUrl,
        httpOptions.body,
        httpHeaders
      );
    }

    if (environment.development) {
      console.timeLog(response.data);
    }

    if (response.data?.success) {
      return response.data;
    }

    if (environment.development && response.data?.error) {
      console.timeLog(response.data.error);
    }

    return null;
  } catch (exception) {
    if (environment.development) {
      console.timeLog(exception.message);
    }

    return null;
  } finally {
    console.timeEnd(httpOptions.cancelTokenKey + "::request");
  }
}
