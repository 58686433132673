import React from "react";
import { Select } from "antd";

const { Option } = Select;

export default function ({
  name,
  roleName,
  rolesData,
  fetchData,
  disabled,
  onChangeSelect,
  mode,
  all,
  labelInValue
}) {
  return (
    <Select
      key={name}
      defaultValue={roleName}
      mode={mode || null}
      allowClear
      showSearch
      filterOption={(input, option) =>
        option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      style={{ width: "100%" }}
      onDropdownVisibleChange={fetchData}
      onChange={(value) => onChangeSelect(name, value)}
      disabled={disabled}
      labelInValue={labelInValue}
    >
      {all ? (
        <Option key="all" value="all" style={{ fontWeight: 500 }}>
          {all}
        </Option>
      ) : null}
      {rolesData?.length > 0
        ? rolesData.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          ))
        : null}
    </Select>
  );
}
