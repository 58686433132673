import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Col, Row, Space, DatePicker, Table } from "antd";

import * as Actions from "../../../../Store/actions";

class ChartDistrict extends Component {
  render() {
    return <Row>Helllo!!</Row>;
  }
}

function mapStateToProps(state) {
  return {
    lang: state.lang.value,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: (language) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
    raiseMessage: (message) =>
      dispatch({ type: Actions.RAISE_MESSAGE, message }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChartDistrict);
