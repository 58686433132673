import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import AuthLayout from "../HOC/AuthLayout";
import User from "../Services/user";
import * as Actions from "../Store/actions";
import { translate } from "../Translation";
import { login } from "../Services/Auth";
import { request } from "../Services";
import { encryptData } from "../Services/encryption";
import styles from "./HomePage.module.scss";

// UI Components
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Button from "antd/lib/button";
import Checkbox from "antd/lib/checkbox";
import Row from "antd/lib/row";
import Col from "antd/lib/col";

class LoginPage extends Component {
  state = {
    loading: false,
  };

  decode = async () => {
    const currentUser = User.decode();
    if (!currentUser) return null;

    const { content } = await request("/auth/accesses", {
      method: "post",
      body: { roleId: currentUser.roleId, userId: currentUser?.id },
      cancelTokenKey: "accesses",
    });
    encryptData(content);

    if (currentUser.roleId === 3 && currentUser.del === true) {
      this.props.history.replace("/charts/receipts");
    } else if (currentUser.del === false) {
      this.props.raiseMessage("message-invalid-del-access");
    } else {
      this.props.history.replace("/cabinet");
    }
  };

  componentDidMount() {
    if (User.token) {
      this.decode();
      // this.props.history.replace("/cabinet");
    }
  }

  intl = (selector) => {
    return translate(selector, this.props.lang);
  };

  onFinish = (values) => {
    this.setState({ loading: true }, async () => {
      const { type, content: token } = await login(values);

      this.setState({ loading: false }, async () => {
        if (type === "ok") {
          User.store(token);
          this.decode();
          // this.props.history.replace("/cabinet");
        } else if (type === "invalid") {
          this.props.raiseMessage("message-invalid-email-or-password");
        } else {
          this.props.raiseMessage("message-server-is-not-responding");
        }
      });
    });
  };

  render() {
    return (
      <AuthLayout activeLink={this.props.match.path} {...this.props}>
        <Form
          layout="vertical"
          className={styles["login-form"]}
          onFinish={this.onFinish}
        >
          <Form.Item
            className={styles["form-item"]}
            name="email"
            label={this.intl("login")}
            rules={[
              {
                required: true,
                message: this.intl("login-required"),
              },
              {
                pattern:
                  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: this.intl("email-pattern"),
              },
            ]}
          >
            <Input type="email" className={styles["form-input"]} />
          </Form.Item>
          <Form.Item
            className={styles["form-item"]}
            name="password"
            label={this.intl("password")}
            rules={[
              {
                required: true,
                message: this.intl("password-required"),
              },
            ]}
          >
            <Input.Password className={styles["form-input"]} />
          </Form.Item>

          <Form.Item>
            <Row>
              <Col span={24} className={styles["form-buttons"]}>
                <div>
                  <Button
                    className={styles["login-button"]}
                    type="primary"
                    htmlType="submit"
                    loading={this.state.loading}
                  >
                    {this.intl("sign-in")}
                  </Button>
                  <Link
                    to="/password-recovery"
                    className={styles["forgot-button"]}
                  >
                    {this.intl("forgot-password")}
                  </Link>
                </div>
                {/* <Checkbox
                  className={styles["remember-checkbox"]}
                  valuePropName="checked"
                >
                  {this.intl("remember-me")}
                </Checkbox> */}
              </Col>
              {/* <Col span={12}>
                <Row justify="end"></Row>
              </Col> */}
            </Row>
          </Form.Item>
        </Form>
      </AuthLayout>
    );
  }
}

function mapStateToProps(state) {
  return {
    lang: state.lang.value,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: (language) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
    raiseMessage: (message) =>
      dispatch({ type: Actions.RAISE_MESSAGE, message }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
