import { useState, useEffect } from "react";
import { Button, ConfigProvider, List } from "antd";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/uk";
import locale from "antd/lib/locale/uk_UA";

import * as Actions from "../../../../Store/actions";
import { translate } from "../../../../Translation";
import { request } from "../../../../Services";
import User from "../../../../Services/user";
import styles from "./styles.module.css";

function ListComponent(props) {
  const [listData, setListData] = useState([]);

  const intl = (selector) => {
    return translate(selector, props.lang);
  };

  const fetchData = async () => {
    const currentUser = User.decode();
    const { content } = await request("/tasks/getlistdata", {
      method: "post",
      body: { id: currentUser?.id },
      cancelTokenKey: "getdata",
    });
    if (content) {
      setListData(content);
    }
  };

  useEffect(fetchData, []);

  const handleClick = async (id) => {
    props.handleChangeListId(id);
    props.fetchData("/tasks/gettabledata", {
      method: "post",
      body: { listId: id, from: props.from, to: props.to },
      cancelTokenKey: "table",
    });
    // const { content } = await request("/tasks/gettabledata", { method: "post", body: { listId: id, from: props.from, to: props.to }, cancelTokenKey: "table" });
    // if (content) { props.fetchTableData(content); }
  };

  return (
    <ConfigProvider locale={locale}>
      <List
        grid={{ gutter: 15, column: 5 }}
        className={styles["list"]}
        dataSource={listData}
        renderItem={(item) => (
          <List.Item>
            <Button
              className={styles["button"]}
              onClick={() => handleClick(item?.id)}
            >
              {item?.GroupTask}
            </Button>
          </List.Item>
        )}
      />
    </ConfigProvider>
  );
}

function mapStateToProps(state) {
  return {
    lang: state.lang.value,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: (language) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
    raiseMessage: (message) =>
      dispatch({ type: Actions.RAISE_MESSAGE, message }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ListComponent);
