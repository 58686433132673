import React, { Component } from "react";
import { connect } from "react-redux";

import CabinetLayout from "../HOC/CabinetLayout";
import User from "../Services/user";
import { environment } from "../Services/environment";
import * as Actions from "../Store/actions";
import { translate } from "../Translation";
import ModalCreateMailFolder from "../Components/Modals/CreateMailFolder";
import styles from "./styles.module.scss";

// UI components
import Button from "antd/lib/button";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Space from "antd/lib/space";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Title from "antd/lib/typography/Title";

class ProfilePage extends Component {
  state = {
    loading: false,
    isModalVisible: false,
  };

  componentDidMount() {
    const currentUser = User.decode();
    if (!currentUser) {
      return this.props.history.replace("/auth");
    }
  }

  intl = (selector) => {
    return translate(selector, this.props.lang);
  };

  onPersonalInfoFinish = (values) => {
    const currentUser = User.decode();
    if (!currentUser) return null;

    this.setState({ loading: true }, () => {
      fetch(`${environment.api}users/personal`, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + User.token,
        },
        body: JSON.stringify({ ...currentUser, ...values }),
        method: "put",
      })
        .then((response) => response.json())
        .then((response) => {
          if (environment.development) {
            console.log(
              "[ProfilePage]: change personal info",
              currentUser.id,
              response
            );
          }
          if (response.success) {
            User.store(response.content);
          }
        })
        .catch((error) => {
          if (environment.development) {
            console.log(
              "[ProfilePage]: error while change personal info",
              currentUser.id,
              error.message
            );
          }
        })
        .finally(() => this.setState({ loading: false }));
    });
  };

  onAccountInfoFinish = (values) => {
    const currentUser = User.decode();
    if (!currentUser) return null;

    this.setState({ loading: true }, () => {
      fetch(`${environment.api}users/account`, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + User.token,
        },
        body: JSON.stringify({ /* ...currentUser,  */ ...values }),
        method: "put",
      })
        .then((response) => response.json())
        .then((response) => {
          if (environment.development) {
            console.log(
              "[ProfilePage]: change account info",
              currentUser.id,
              response
            );
          }
          if (response.success) {
            User.store(response.content);
          }
        })
        .catch((error) => {
          if (environment.development) {
            console.log(
              "[ProfilePage]: error while change account info",
              currentUser.id,
              error.message
            );
          }
        })
        .finally(() => this.setState({ loading: false }));
    });
  };

  showModal = () => {
    this.setState({ isModalVisible: true });
  };

  handleOk = () => {
    this.setState({ isModalVisible: false });
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  render() {
    const currentUser = User.decode();
    const { btausers, role } = currentUser;
    if (!currentUser) return null;

    return (
      <CabinetLayout activeLink={this.props.match.path} {...this.props}>
        <Row>
          <Col lg={24} md={24} sm={24}>
            <ModalCreateMailFolder />
          </Col>
        </Row>
        <Row className={styles["row-form"]}>
          <Col lg={6} md={6} sm={24}>
            <Title level={4}>{this.intl("personal-info")}</Title>
            <Form layout="vertical" onFinish={this.onPersonalInfoFinish}>
              <Form.Item
                name="lastName"
                label={this.intl("last-name")}
                initialValue={currentUser.lastName}
                rules={[
                  {
                    required: true,
                    message: this.intl("last-name-required"),
                  },
                ]}
              >
                <Input type="text" />
              </Form.Item>
              <Form.Item
                name="firstName"
                label={this.intl("first-name")}
                initialValue={currentUser.firstName}
                rules={[
                  {
                    required: true,
                    message: this.intl("first-name-required"),
                  },
                ]}
              >
                <Input type="text" />
              </Form.Item>
              <Form.Item
                name="middleName"
                label={this.intl("middle-name")}
                initialValue={currentUser.middleName}
                rules={[
                  {
                    required: true,
                    message: this.intl("middle-name-required"),
                  },
                ]}
              >
                <Input type="text" />
              </Form.Item>

              <Form.Item>
                <Space>
                  <Button
                    className={styles.button}
                    htmlType="submit"
                    loading={this.state.loading}
                  >
                    {this.intl("update-info")}
                  </Button>
                </Space>
              </Form.Item>

              <Form.Item label={this.intl("role")}>
                <Input
                  defaultValue={role?.map((i) => i.role)}
                  disabled={true}
                />
              </Form.Item>
              <Form.Item label={this.intl("sed-user")}>
                <Input
                  defaultValue={btausers?.map((i) => i.NAME)}
                  disabled={true}
                />
              </Form.Item>
            </Form>
          </Col>
          <Col lg={{ span: 6, offset: 1 }} md={{ span: 6, offset: 1 }} sm={24}>
            <Title level={4}>{this.intl("update-password")}</Title>
            <Form layout="vertical" onFinish={this.onAccountInfoFinish}>
              <Form.Item
                name="currentPassword"
                label={this.intl("current-password")}
                rules={[
                  {
                    required: true,
                    message: this.intl("current-password-required"),
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                name="newPassword"
                label={this.intl("password")}
                rules={[
                  {
                    required: true,
                    message: this.intl("password-required"),
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                name="confirmNewPassword"
                label={this.intl("confirm-password")}
                rules={[
                  {
                    required: true,
                    message: this.intl("confirm-password-required"),
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("newPassword") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        this.intl("confirm-password-reference")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item>
                <Space>
                  <Button
                    className={styles.button}
                    htmlType="submit"
                    loading={this.state.loading}
                  >
                    {this.intl("update-data")}
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </CabinetLayout>
    );
  }
}

function mapStateToProps(state) {
  return {
    lang: state.lang.value,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: (language) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
