import { connect } from "react-redux";
import { Row, Col, Layout, Menu, Button, Dropdown, Space, Alert } from "antd";
import CaretDownOutlined from "@ant-design/icons/lib/icons/CaretDownOutlined";

import * as Actions from "../Store/actions";
import { translate, availableLanguages } from "../Translation";
import LogoDoc from "../Assets/logo-doc.png";
import styles from "./styles.module.scss";

const { Header, Content, Sider } = Layout;

function AuthLayout(props) {
  const intl = (selector) => {
    return translate(selector, props.lang);
  };

  return (
    <Layout className={styles["auth-main"]}>
      <Sider className={styles["auth-sider"]}>
        <a className={styles["auth-sider-link"]}>{intl("terms-link")}</a>
      </Sider>
      <Layout className={styles["auth-content"]}>
        <Header className={styles["auth-header"]}>
          <Row justify="end">
            <Col span={2}>
              <Space>
                <Dropdown
                  className={styles["auth-dropdown"]}
                  overlay={() => (
                    <Menu>
                      {availableLanguages.map((language) => (
                        <Menu.Item
                          key={language.key}
                          onClick={() => props.changeLanguage(language.code)}
                        >
                          {language.title}
                        </Menu.Item>
                      ))}
                    </Menu>
                  )}
                >
                  <Button>
                    {intl("language")}
                    <CaretDownOutlined
                      className={styles["auth-caret-dropdown"]}
                    />
                  </Button>
                </Dropdown>
              </Space>
            </Col>
          </Row>
        </Header>
        <Content>
          <Row justify="center">
            <Col xs={20} sm={16} md={12} lg={12} xl={12}>
              <img
                src={LogoDoc}
                alt="Logo Doc"
                className={styles["logo-doc"]}
              />
              {props.message ? (
                <Alert
                  className={styles["auth-alert"]}
                  message={intl(props.message)}
                  type="error"
                  onClose={props.closeMessage}
                  banner
                  closable
                />
              ) : null}
              {props.children}
            </Col>
          </Row>
        </Content>
      </Layout>
    </Layout>
  );
}

function mapStateToProps(state) {
  return {
    lang: state.lang.value,
    message: state.authLayout.value,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: (language) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
    closeMessage: () => dispatch({ type: Actions.CLOSE_MESSAGE }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthLayout);
