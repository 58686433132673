import { api } from "../axios";
import { environment } from "../environment";

export async function login(values) {
  try {
    const response = await api.post("/auth/login", values);
    if (response.data?.success) {
      if (environment.development) {
        console.log("[AuthService]: login", response.data);
      }
      return { type: "ok", content: response.data.content };
    }

    return { type: "invalid" };
  } catch (error) {
    if (environment.development) {
      console.error("[AuthService]: error while login", error.message);
    }
    return { type: "unexpected" };
  }
}
