import { useState, useEffect } from "react";
import { Row, Col, Drawer, Button, Table, Input } from "antd";
import { EllipsisOutlined, CloseOutlined } from "@ant-design/icons";
import { connect } from "react-redux";

import * as Actions from "../../../Store/actions";
import { translate } from "../../../Translation";
import { request } from "../../../Services";
import styles from "./styles.module.scss";
import { drawerColumns, caseLeftColumns, caseRightColumns } from "./utils";

function DrawerComponent(props) {
  const [visible, setVisible] = useState(false);
  const [content, setContent] = useState(props.documentName ?? undefined);
  const [codeContent, setCodeContent] = useState(undefined);
  const [tableData, setTableData] = useState(undefined);
  const [tableCaseData, setTableCaseData] = useState(undefined);
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [tableRowId, setTableRowId] = useState(undefined);

  const intl = (selector) => {
    return translate(selector, props.lang);
  };

  const tableColumns = drawerColumns(intl);
  const leftTableColumns = caseLeftColumns(intl);
  const rightTableColumns = caseRightColumns(intl);

  useEffect(() => {
    if (props.documentId) {
      triggerChange(props.documentId);
      setContent(props.documentName);
      setCodeContent(props.documentCode);
    }
  }, [props.documentId]);

  const showDrawer = async () => {
    setVisible(true);

    const { content } = await request(props.url, props.options);
    if (content) {
      setTableData(content);
    }
  };

  const closeDrawer = () => {
    setVisible(false);
  };

  const onClickRow = (record) => {
    triggerChange(record.id);
    setContent(record.Name);
    setCodeContent(record.Code);
    setTableRowId(record.id);
    setDisabledBtn(false);
    props.setSelectId?.(record.id);
    props.setTableData?.({
      id: record.id,
      name: record.Name,
      type: record.typerec,
    });
    props.setTableAction?.({ id: record.id, name: record.Name });
  };

  const onClickCaseRow = (record) => {
    triggerChange(record.id);
    setContent(record.Name_OfficialDoc);
    setCodeContent(record.Code_OfficialDoc);
    setTableRowId(record.id);
    setDisabledBtn(false);
  };

  const triggerChange = (changedValue) => {
    props.onChange?.(changedValue);
  };

  const onClearContent = () => {
    triggerChange(undefined);
    setContent(undefined);
    setCodeContent(undefined);
    setTableRowId(undefined);
    setDisabledBtn(true);
    props.setSelectId?.(undefined);
    props.setTableData?.({ id: undefined, name: undefined, type: undefined });
    props.setTableAction?.({ id: undefined, name: undefined });
  };

  const setRowClassName = (record) => {
    return record.id === tableRowId ? styles["table-select-row"] : null;
  };

  const fetchTableCase = async (id) => {
    let data = null;
    props.table !== undefined
      ? (data = await request(
          `/incoming/tableOfficialDocDocuments?docId=${id}`,
          { method: "get", cancelTokenKey: "case" }
        ))
      : (data = await request(
          `/outcoming/modalOfficialDocDocuments?docId=${id}`,
          { method: "get", cancelTokenKey: "case" }
        ));
    if (data) {
      setTableCaseData(data.content);
    }
  };

  const code =
    props.code !== undefined ? (
      <Input
        value={codeContent}
        style={{ width: "20%" }}
        disabled={props.isEdit}
      />
    ) : null;
  const table =
    props.table !== undefined ? (
      <Col lg={{ span: 12, offset: 2 }} md={{ span: 12, offset: 2 }} sm={24}>
        <Table
          className={styles.table}
          dataSource={tableCaseData}
          columns={rightTableColumns}
          rowKey={(record) => record.id}
          onRow={(record) => {
            return {
              onClick: () => {
                onClickCaseRow(record);
              },
              onDoubleClick: () => closeDrawer(),
            };
          }}
          rowClassName={setRowClassName}
          pagination={{ position: ["bottomCenter"] }}
          size="small"
          bordered
        />
      </Col>
    ) : null;

  return (
    <div style={{ display: "flex" }}>
      {code}
      <Input value={content} style={props.style} disabled={props.isEdit} />
      <Button
        icon={<EllipsisOutlined />}
        onClick={showDrawer}
        disabled={props.isEdit}
      />
      <Button
        icon={<CloseOutlined />}
        onClick={onClearContent}
        disabled={props.isEdit}
      />
      <Drawer
        placement="top"
        closable={false}
        onClose={closeDrawer}
        visible={visible}
        key="top"
        height="80%"
        destroyOnClose={true}
        footer={
          <div style={{ textAlign: "right" }}>
            <Button onClick={closeDrawer} style={{ marginRight: 8 }}>
              {intl("cancel")}
            </Button>
            <Button onClick={closeDrawer} type="primary" disabled={disabledBtn}>
              {intl("ok")}
            </Button>
          </div>
        }
      >
        <Row>
          <Col
            lg={props.table ? 8 : { span: 14, offset: 6 }}
            md={props.table ? 8 : { span: 14, offset: 6 }}
            sm={24}
          >
            <Table
              className={styles.table}
              dataSource={tableData}
              columns={props.table ? leftTableColumns : tableColumns}
              rowKey={(record) => record.id}
              onRow={(record) => {
                return {
                  onClick: () => {
                    onClickRow(record);
                    props.table ? fetchTableCase(record.id) : null;
                  },
                  onDoubleClick: () => props.table ?? closeDrawer(),
                };
              }}
              rowClassName={setRowClassName}
              pagination={{ position: ["bottomCenter"] }}
              size="small"
              bordered
            />
          </Col>
          {table}
        </Row>
      </Drawer>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    lang: state.lang.value,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: (language) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
    raiseMessage: (message) =>
      dispatch({ type: Actions.RAISE_MESSAGE, message }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DrawerComponent);
