import { Component } from "react";

import FileOutlined from "@ant-design/icons/lib/icons/FileOutlined";
import DownloadOutlined from "@ant-design/icons/lib/icons/DownloadOutlined";
import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined";

import styles from "./File.module.css";

class UIFile extends Component {
  state = { hovered: false };

  onMouseEnter = () => {
    this.setState({ hovered: true });
  };
  onMouseLeave = () => {
    this.setState({ hovered: false });
  };

  render() {
    return (
      <div
        onClick={!this.props.loading ? this.props.onClick : null}
        onMouseEnter={this.props.download ? this.onMouseEnter : null}
        onMouseLeave={this.props.download ? this.onMouseLeave : null}
        className={styles.root}
      >
        {this.props.loading ? (
          <div className={styles.loading}>
            <LoadingOutlined />
          </div>
        ) : null}
        <div className={styles.cover}>
          {this.state.hovered && !this.props.loading ? (
            <DownloadOutlined className={styles.icon} />
          ) : (
            <FileOutlined className={styles.icon} />
          )}
        </div>

        <div className={styles.title}>{this.props.title}</div>
      </div>
    );
  }
}

export default UIFile;
