import * as Actions from "../actions";

const initialState = { raiseMessage: (message) => {}, value: String() };
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Actions.RAISE_MESSAGE:
      return {
        ...state,
        value: action.message || String(),
      };
    case Actions.CLOSE_MESSAGE:
      return {
        ...state,
        value: String(),
      };
    default:
      return state;
  }
}
