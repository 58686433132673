// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_Row__2bZ2U {\n    background: linear-gradient(#b0e977, #f1eaea);\n}\n\n.styles_Table__3iJY5 table {\n    font-size: 16px;\n}\n\n.styles_Table__3iJY5 div {\n    background: transparent;\n}\n\n.styles_Table__3iJY5 div table thead tr th {\n    background: transparent;\n}\n\n.styles_Table__3iJY5 table thead tr th {\n    text-align: center;\n}\n\n.styles_Column__m2a9F {\n    font-size: 20px;\n}\n\n.styles_Columns__3OxJJ {\n    text-align: right;\n}\n\n@media (max-width: 1064px) {\n    .styles_Table__3iJY5 table {\n        font-size: 12px;\n    }\n    .styles_Column__m2a9F {\n        font-size: 16px;\n    }\n}\n\n@media (max-width: 910px) {\n    .styles_Table__3iJY5 table {\n        font-size: 9px;\n    }\n    .styles_Column__m2a9F {\n        font-size: 10px;\n    }\n}", "",{"version":3,"sources":["webpack://src/Containers/Statistics/StructuralUnits/styles.module.css"],"names":[],"mappings":"AAAA;IACI,6CAA6C;AACjD;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI;QACI,eAAe;IACnB;IACA;QACI,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,cAAc;IAClB;IACA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".Row {\n    background: linear-gradient(#b0e977, #f1eaea);\n}\n\n.Table table {\n    font-size: 16px;\n}\n\n.Table div {\n    background: transparent;\n}\n\n.Table div table thead tr th {\n    background: transparent;\n}\n\n.Table table thead tr th {\n    text-align: center;\n}\n\n.Column {\n    font-size: 20px;\n}\n\n.Columns {\n    text-align: right;\n}\n\n@media (max-width: 1064px) {\n    .Table table {\n        font-size: 12px;\n    }\n    .Column {\n        font-size: 16px;\n    }\n}\n\n@media (max-width: 910px) {\n    .Table table {\n        font-size: 9px;\n    }\n    .Column {\n        font-size: 10px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Row": "styles_Row__2bZ2U",
	"Table": "styles_Table__3iJY5",
	"Column": "styles_Column__m2a9F",
	"Columns": "styles_Columns__3OxJJ"
};
export default ___CSS_LOADER_EXPORT___;
