// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_button__3j4KX,\n.styles_button__3j4KX:focus {\n  background: #5abab9;\n  font-size: 16px;\n  border: none;\n  color: #ffffff;\n  line-height: 1;\n  width: 100%;\n}\n\n.styles_button__3j4KX:hover {\n  background: #5abab9;\n  color: #3f434e;\n}", "",{"version":3,"sources":["webpack://src/Containers/Directories/Departments/styles.module.scss"],"names":[],"mappings":"AAAA;;EAEE,mBAAA;EACA,eAAA;EACA,YAAA;EACA,cAAA;EACA,cAAA;EACA,WAAA;AACF;;AAEA;EACE,mBAAA;EACA,cAAA;AACF","sourcesContent":[".button,\n.button:focus {\n  background: #5abab9;\n  font-size: 16px;\n  border: none;\n  color: #ffffff;\n  line-height: 1;\n  width: 100%;\n}\n\n.button:hover {\n  background: #5abab9;\n  color: #3f434e;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "styles_button__3j4KX"
};
export default ___CSS_LOADER_EXPORT___;
