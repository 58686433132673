import { useState, useContext, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Space,
  ConfigProvider,
  Checkbox,
  Tabs,
} from "antd";
import { connect } from "react-redux";
import "moment/locale/uk";
import locale from "antd/lib/locale/uk_UA";

import * as Actions from "../../../Store/actions";
import { translate } from "../../../Translation";
import Drawer from "../../../Components/UI/Drawer";
import { request } from "../../../Services";
import { ModalContext } from "../../../Contexts/modal.context";
import User from "../../../Services/user";
import styles from "./styles.module.scss";

const { TabPane } = Tabs;

function FormComponent(props) {
  const [form] = Form.useForm();
  const [checkboxData, setCheckboxData] = useState(undefined);
  const [checkedIds, setCheckedIds] = useState({});
  const { rowData, rowId, fetchTableData, pathname, name } = props;
  const modalContext = useContext(ModalContext);

  const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 19 },
  };

  const layoutChb = {
    wrapperCol: { span: 19, offset: 5 },
  };

  const layoutBtn = {
    wrapperCol: { span: 24 },
  };

  const intl = (selector) => {
    return translate(selector, props.lang);
  };

  useEffect(async () => {
    const data = await request(`${pathname}/typeDocuments`, {
      method: "get",
      cancelTokenKey: "typeDocuments",
    });
    if (data) {
      setCheckboxData(data.content);
    }
  }, []);

  const onFormFinish = async (values) => {
    console.log("Success:", values);
    const currentUser = User.decode();

    rowId === 0
      ? await request(`${pathname}/newData`, {
          method: "post",
          body: { ...values, userId: currentUser?.id, checked: checkedIds },
          cancelTokenKey: "newData",
        })
      : await request(`${pathname}/updateData`, {
          method: "post",
          body: { ...values, id: rowId, checked: checkedIds },
          cancelTokenKey: "updateData",
        });
    modalContext.close(name);
    fetchTableData();
  };

  const onFormFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  function validator(subject, value) {
    return new Promise((resolve, reject) => {
      if (!value) {
        return reject(new Error(subject.message));
      }

      return resolve();
    });
  }

  function onChangeCheckbox(event) {
    setCheckedIds({
      ...checkedIds,
      [event.target.value]: +event.target.checked,
    });
  }

  return (
    <ConfigProvider locale={locale}>
      <Form
        {...layout}
        form={form}
        onFinish={onFormFinish}
        onFinishFailed={onFormFailed}
        initialValues={{
          code: rowData?.Code_TypeDoc,
          nameSpecies: rowData?.Name_TypeDoc,
          notes: rowData?.Prim_TypeDoc,
          popupMenu: false,
        }}
      >
        <Row>
          <Col lg={12} md={12} sm={24}>
            <Form.Item label={intl("code")} name="code">
              <Input />
            </Form.Item>
            <Form.Item
              label={intl("species-name")}
              name="nameSpecies"
              rules={[
                {
                  required: true,
                  message: intl("species-name-required"),
                  validator: validator,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label={intl("category")} name="category">
              <Drawer
                url="/directories/types-documents/category"
                options={{ method: "get", cancelTokenKey: "category" }}
                documentName={rowData?.name_KategTypeDoc}
                documentId={rowData?.eta_KategTypeDoc_id}
              />
            </Form.Item>
            <Form.Item label={intl("notes")} name="notes">
              <Input />
            </Form.Item>
            <Form.Item {...layoutChb} name="popupMenu" valuePropName="checked">
              <Checkbox>{intl("display-toolbar-menu")}</Checkbox>
            </Form.Item>
          </Col>
          <Col
            lg={{ span: 10, offset: 2 }}
            md={{ span: 10, offset: 2 }}
            sm={24}
          >
            <Form.Item
              label={intl("types-of-documents")}
              name="typesDocuments"
              valuePropName="checked"
              rules={[
                {
                  required: true,
                  message: intl("types-of-documents-required"),
                  validator: validator,
                },
              ]}
            >
              <Checkbox.Group style={{ width: "100%" }}>
                <Row>
                  {checkboxData?.map((item) => (
                    <Col span={12}>
                      <Checkbox
                        key={item?.id}
                        value={item?.id}
                        onChange={onChangeCheckbox}
                      >
                        {item?.Name}
                      </Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={24}>
            <Tabs defaultActiveKey="1">
              <TabPane tab={intl("default-parameters")} key="1">
                <Form.Item
                  label={intl("type-of-connection")}
                  name="typeConnection"
                >
                  <Drawer
                    url="/directories/types-documents/typeConnection"
                    options={{
                      method: "get",
                      cancelTokenKey: "typeConnection",
                    }}
                    documentName={rowData?.name_TypeDoc_relation}
                    documentId={rowData?.TypeRelationDoc_id}
                  />
                </Form.Item>
                <Form.Item label={intl("correspondent")} name="correspondent">
                  <Drawer
                    url="/directories/types-documents/correspondent"
                    options={{ method: "get", cancelTokenKey: "correspondent" }}
                    documentName={rowData?.name_firm_plat}
                    documentId={rowData?.sta_kontragent_id}
                  />
                </Form.Item>
                <Form.Item
                  label={intl("place-of-registration")}
                  name="placeRegistration"
                >
                  <Drawer
                    url="/directories/types-documents/placeRegistration"
                    options={{
                      method: "post",
                      body: { id: rowId },
                      cancelTokenKey: "placeRegistration",
                    }}
                    documentName={rowData?.name_otdel}
                    documentId={rowData?.sta_otdel_id}
                  />
                </Form.Item>
                {/* <Form.Item
                                    label={intl("label-route-template")}
                                    name="routeTemplate"
                                >
                                    <Drawer url="/directories/types-documents/routeTemplate" options={{ method: "post", body: {id: rowId}, cancelTokenKey: "routeTemplate" }} documentName={tableData?.NAME_OTDEL} documentId={tableData?.otdelId} />
                                </Form.Item> */}
              </TabPane>
              <TabPane tab={intl("other-parameters")} key="2">
                Content of Tab Pane 2
              </TabPane>
              <TabPane tab={intl("templates")} key="3">
                Content of Tab Pane 3
              </TabPane>
              <TabPane tab={intl("details")} key="4">
                Content of Tab Pane 3
              </TabPane>
            </Tabs>
          </Col>
        </Row>

        <Form.Item {...layoutBtn}>
          <Space style={{ display: "flex", justifyContent: "end" }}>
            <Button onClick={() => modalContext.close(name)}>
              {intl("cancel")}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className={styles["button"]}
            >
              {intl("ok")}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </ConfigProvider>
  );
}

function mapStateToProps(state) {
  return {
    lang: state.lang.value,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: (language) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
    raiseMessage: (message) =>
      dispatch({ type: Actions.RAISE_MESSAGE, message }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormComponent);
