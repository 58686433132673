import { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Col, Row, Space, DatePicker, Table, ConfigProvider } from "antd";
import { Column } from "@ant-design/charts";
import "moment/locale/uk";
import locale from "antd/es/locale/uk_UA";

import * as Actions from "../../../Store/actions";
import { translate } from "../../../Translation";
import { request } from "../../../Services";
import styles from "./styles.module.css";
import { tableColumns } from "./utils";

class ChartStructuralUnits extends Component {
  state = {
    dateFrom: moment(Date.now()).startOf("month"),
    dateTo: moment(Date.now()),
    data: [],
    dataTable: [],
    loading: false,
  };

  componentDidMount() {
    this.fetchCharts();
  }

  intl = (selector) => {
    return translate(selector, this.props.lang);
  };

  fetchCharts = () => {
    this.setState({ loading: true }, async () => {
      const data = await request("/statistics/structural-units", {
        method: "post",
        body: this.state,
        cancelTokenKey: "structural-units",
      });
      console.log("data: ", data);
      const dataTable = await request("/statistics/structural-units-table", {
        method: "post",
        body: this.state,
        cancelTokenKey: "structural-units-table",
      });
      console.log("dataTable: ", dataTable);
      this.setState({
        data: data?.content,
        dataTable: dataTable?.content,
        loading: false,
      });
    });
  };

  onChangeDateFrom = (date) => {
    this.setState(
      { dateFrom: moment(date, "DD-MM-YYYY").startOf("date") },
      () => {
        if (this.state.dateFrom && this.state.dateTo) {
          this.fetchCharts();
        }
      }
    );
  };

  onChangeDateTo = (date) => {
    this.setState({ dateTo: moment(date, "DD-MM-YYYY").endOf("date") }, () => {
      if (this.state.dateFrom && this.state.dateTo) {
        this.fetchCharts();
      }
    });
  };

  render() {
    const { dateFrom, dateTo, data, dataTable } = this.state;

    const config = {
      data,
      xField: "Dept",
      yField: "CountKateg",
      seriesField: "NameKateg",
      label: {
        position: "top",
        formatter: (i) => {
          if (i.NameKateg === "Виконано") return i.CountKateg;
        },
      },
      xAxis: {
        label: {
          autoRotate: true,
          autoHide: false,
          autoEllipsis: false,
        },
      },
      legend: false,
    };

    const columnsTable = tableColumns(this.intl);

    return (
      <Row>
        <Col lg={24} md={24} sm={24}>
          <Space direction="vertical" size={12}>
            <ConfigProvider locale={locale}>
              {dateFrom ? (
                <DatePicker
                  defaultValue={dateFrom}
                  format={"DD-MM-YYYY"}
                  picker="date"
                  onChange={(_, dates) => this.onChangeDateFrom(dates)}
                  style={{ marginLeft: "1rem" }}
                />
              ) : null}
              {dateTo ? (
                <DatePicker
                  defaultValue={dateTo}
                  format={"DD-MM-YYYY"}
                  picker="date"
                  onChange={(_, dates) => this.onChangeDateTo(dates)}
                  style={{ marginLeft: "1rem" }}
                />
              ) : null}
            </ConfigProvider>
          </Space>
        </Col>

        <Col lg={24} md={24} sm={24}>
          {data.length > 0 ? (
            <Column {...config} style={{ marginTop: "5rem" }} />
          ) : null}
          {dataTable.length > 0 ? (
            <Table
              className={styles.Table}
              pagination={false}
              columns={columnsTable}
              dataSource={dataTable.map((item) => ({
                ...item,
              }))}
              style={{
                marginTop: "2rem",
                marginLeft: "2rem",
                marginRight: "2rem",
              }}
            />
          ) : null}
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    lang: state.lang.value,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: (language) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
    raiseMessage: (message) =>
      dispatch({ type: Actions.RAISE_MESSAGE, message }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChartStructuralUnits);
