import { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Col, Row, Space, DatePicker, ConfigProvider } from "antd";
import { Bar } from "@ant-design/charts";
import "moment/locale/uk";
import locale from "antd/es/locale/uk_UA";

import * as Actions from "../../../Store/actions";
import { translate } from "../../../Translation";
import { request } from "../../../Services";
// import styles from './RatingManagers.module.css';

class ChartRatingManagers extends Component {
  state = {
    dateFrom: moment(Date.now()).startOf("month"),
    dateTo: moment(Date.now()),
    data: [],
    loading: false,
  };

  componentDidMount() {
    this.fetchCharts();
  }

  intl = (selector) => {
    return translate(selector, this.props.lang);
  };

  fetchCharts = () => {
    this.setState({ loading: true }, async () => {
      const data = await request("/statistics/rating-managers", {
        method: "post",
        body: this.state,
        cancelTokenKey: "rating-managers",
      });
      console.log("data: ", data);
      this.setState({ data: data?.content, loading: false });
    });
  };

  onChangeDateFrom = (date) => {
    this.setState(
      { dateFrom: moment(date, "DD-MM-YYYY").startOf("date") },
      () => {
        if (this.state.dateFrom && this.state.dateTo) {
          this.fetchCharts();
        }
      }
    );
  };

  onChangeDateTo = (date) => {
    this.setState({ dateTo: moment(date, "DD-MM-YYYY").endOf("date") }, () => {
      if (this.state.dateFrom && this.state.dateTo) {
        this.fetchCharts();
      }
    });
  };

  render() {
    const { dateFrom, dateTo, data } = this.state;

    const config = {
      data: data,
      xField: "count_z",
      yField: "Organization",
      meta: {
        count_z: { alias: "Кількість звернень" },
      },
      label: { visible: true },
    };

    return (
      <Row>
        <Col lg={24} md={24} sm={24}>
          <Space direction="vertical" size={12}>
            <ConfigProvider locale={locale}>
              {dateFrom ? (
                <DatePicker
                  defaultValue={dateFrom}
                  format={"DD-MM-YYYY"}
                  picker="date"
                  onChange={(_, dates) => this.onChangeDateFrom(dates)}
                  style={{ marginLeft: "1rem" }}
                />
              ) : null}
              {dateTo ? (
                <DatePicker
                  defaultValue={dateTo}
                  format={"DD-MM-YYYY"}
                  picker="date"
                  onChange={(_, dates) => this.onChangeDateTo(dates)}
                  style={{ marginLeft: "1rem" }}
                />
              ) : null}
            </ConfigProvider>
          </Space>
        </Col>

        <Col lg={24} md={24} sm={24}>
          {data.length > 0 ? (
            <Bar {...config} style={{ marginTop: "5rem" }} />
          ) : null}
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    lang: state.lang.value,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: (language) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
    raiseMessage: (message) =>
      dispatch({ type: Actions.RAISE_MESSAGE, message }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChartRatingManagers);
