import { useRef, useState } from "react";
import { connect } from "react-redux";
import { Button, Row, Col, Modal, Checkbox, Form, Space } from "antd";

import * as Actions from "../../../Store/actions";
import { translate } from "../../../Translation";
import { request } from "../../../Services";
import SelectComponent from "../../UI/Select";
import styles from "./styles.module.css";

function ModalAccessSettings(props) {
  const form = useRef();
  const [visible, setVisible] = useState(false);
  const [rolesData, setRolesData] = useState(undefined);
  const [pagesData, setPagesData] = useState(undefined);
  const [buttonsData, setPButtonsData] = useState(undefined);
  const { name, selectData, disabled, pages, buttons, fetchTableData } = props;

  const intl = (selector) => {
    return translate(selector, props.lang);
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const fetchRolesData = async () => {
    const { content } = await request("/administration/getroles", {
      method: "get",
      cancelTokenKey: "getroles",
    });
    setRolesData(content || []);
  };

  const fetchPagesData = async () => {
    const { content } = await request("/administration/getpages", {
      method: "get",
      cancelTokenKey: "getpages",
    });
    setPagesData(content || []);
  };

  const fetchButtonsData = async () => {
    const { content } = await request("/administration/getbuttons", {
      method: "get",
      cancelTokenKey: "getbuttons",
    });
    setPButtonsData(content || []);
  };

  const onFormFinish = async (values) => {
    console.log("values: ", values);
    pages
      ? await request("/administration/insertaccesspages", {
          method: "post",
          body: values,
          cancelTokenKey: "insertaccess",
        })
      : await request("/administration/updateaccesspages", {
          method: "post",
          body: { ...values, id: selectData?.id },
          cancelTokenKey: "updateaccess",
        });
    buttons
      ? await request("/administration/insertaccessbuttons", {
          method: "post",
          body: values,
          cancelTokenKey: "insertaccess",
        })
      : await request("/administration/updateaccessbuttons", {
          method: "post",
          body: { ...values, id: selectData?.id },
          cancelTokenKey: "updateaccess",
        });
    setVisible(false);
    fetchTableData();
  };

  const onFormFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onChangeSelect = (name, value) => {
    if (name === "role") {
      form.current.setFieldsValue({ role: value });
    } else if (name === "pages") {
      form.current.setFieldsValue({
        pages: value == "all" ? pagesData?.map((i) => i.id) : value,
      });
    } else {
      form.current.setFieldsValue({
        buttons: value == "all" ? buttonsData?.map((i) => i.id) : value,
      });
    }
  };

  return (
    <>
      <Button
        className={styles["modal-button"]}
        onClick={showModal}
        disabled={disabled}
      >
        {name}
      </Button>
      <Modal
        width={400}
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose={true}
      >
        <Row>
          <Col lg={24} md={24} sm={24} style={{ padding: "0 1rem" }}>
            <Form
              ref={form}
              layout="vertical"
              onFinish={onFormFinish}
              onFinishFailed={onFormFailed}
              initialValues={{
                role: selectData?.roleId,
                pages: selectData?.routeId,
                buttons: selectData?.buttonId,
                del: selectData?.isAccess || false,
              }}
            >
              <Row>
                <Col lg={24} md={24} sm={24}>
                  <Form.Item
                    name="role"
                    label={intl("role")}
                    rules={[
                      {
                        required: true,
                        message: intl("role-required"),
                      },
                    ]}
                  >
                    <SelectComponent
                      name="role"
                      roleName={selectData?.role}
                      onChangeSelect={onChangeSelect}
                      rolesData={rolesData}
                      fetchData={fetchRolesData}
                    />
                  </Form.Item>
                  <Form.Item
                    name="pages"
                    label={intl("pages")}
                    rules={[
                      {
                        required: true,
                        message: intl("pages-required"),
                      },
                    ]}
                  >
                    <SelectComponent
                      name="pages"
                      roleName={selectData?.routeName}
                      onChangeSelect={onChangeSelect}
                      rolesData={pagesData}
                      fetchData={fetchPagesData}
                      mode="multiple"
                      all={intl("select-all")}
                    />
                  </Form.Item>
                  {buttons ? (
                    <Form.Item
                      name="buttons"
                      label={intl("buttons")}
                      rules={[
                        {
                          required: true,
                          message: intl("buttons-required"),
                        },
                      ]}
                    >
                      <SelectComponent
                        name="buttons"
                        roleName={selectData?.buttonName}
                        onChangeSelect={onChangeSelect}
                        rolesData={buttonsData}
                        fetchData={fetchButtonsData}
                        mode="multiple"
                        all={intl("select-all")}
                      />
                    </Form.Item>
                  ) : null}
                  <Form.Item
                    name="del"
                    label={intl("access")}
                    valuePropName="checked"
                  >
                    <Checkbox>{intl("allow")}</Checkbox>
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item>
                <Space style={{ position: "absolute", right: 0 }}>
                  <Button onClick={handleCancel}>{intl("cancel")}</Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className={styles["modal-button"]}
                  >
                    {intl("ok")}
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

function mapStateToProps(state) {
  return {
    lang: state.lang.value,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: (language) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalAccessSettings);
